import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  FormControl,
  Divider,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  useMediaQuery,
} from '@mui/material';
import LoadingIndicatorLogo from './../../../LoadingIndicatorLogo';
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CircleIcon from '@mui/icons-material/Circle';
import ClearIcon from '@mui/icons-material/Clear';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { updateAdvisorOnboardingStep4 } from './../../../../services/advisor_services/index';
import { useAccount } from './../../../../contexts/Account';

const useStyles = () => ({
  step4: {
    fontFamily: 'Poppins',
  },
  formControl: {
    width: '100%',
    height: '44px',
    margin: '8px 0',
  },
  formControl2: {
    width: '50%',
    height: '44px',
    margin: '8px 0',
  },
  formControl3: {
    width: '100%',
    height: '44px',
  },
  label: {
    top: '-4px',
  },
  listContainer: {
    overflow: 'hidden',
    padding: '10px',
    marginBottom: '10px',
  },
  divider: {
    margin: '0px 0px 10px 0',
    backgroundColor: '#5e7da4',
  },
  listItem: {
    padding: '0',
    fontFamily: 'Poppins',
  },
  listItemLabel: {
    overflow: 'hidden',
    fontFamily: 'Poppins',
  },
  listItemIcon: {
    minWidth: '16px',
  },
  titleContainer: {
    // marginBottom: '30px',
  },
  deleteContainer: {
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  deleteIcon: {
    fontSize: '18px !important',
    cursor: 'pointer',
  },
  title: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#232B35',
    fontFamily: 'Poppins',
  },
  subtitle: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#646D7A',
    fontFamily: 'Poppins',
  },
  enterIcon: {
    fontSize: '15px !important',
    bottom: '-3px',
    position: 'relative',
    display: 'inline',
  },
  sendButton: {
    borderRadius: '30px',
    width: '226px',
    height: '44px',
    fontWeight: '700',
    fontSize: '16px',
    textTransform: 'none',
  },
  addMoreButton: {
    textTransform: 'none',
    fontFamily: 'Poppins',
  },
  sendButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingContainer: {
    alignItems: 'center',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    left: '0px',
    position: 'fixed',
    right: '0px',
    top: '0px',
  },
});

export default function FormExperience() {
  const history = useHistory();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { accountDetails } = useAccount();
  const [experiences, setExperiences] = useState(
    accountDetails && accountDetails.experiences
      ? accountDetails.experiences
      : [
          {
            company: '',
            position: '',
            startDate: '',
            endDate: '',
            notes: [],
            isCurrent: false,
          },
        ]
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event, index) => {
    let value;
    if (event.target.name === 'isCurrent') {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    const newExperiences = [...experiences];
    newExperiences[index][event.target.name] = value;
    setExperiences(newExperiences);
  };

  const handleChangeDate = (newDate, param, index) => {
    let newEndDate;
    if (newDate) {
      newEndDate = newDate.getFullYear();
    }
    const newExperiences = [...experiences];
    newExperiences[index][param] = newEndDate;
    newExperiences[index][`d${param}`] = newDate;
    setExperiences(newExperiences);
  };

  const handleAddBulletPoint = (index, value) => {
    if (value) {
      const newExperiences = [...experiences];
      newExperiences[index].notes.push(value);
      newExperiences[index].noteForm = '';
      setExperiences(newExperiences);
    }
  };

  const handleRemoveBulletPoint = (indexExp, indexNote) => {
    const newExperiences = [...experiences];
    newExperiences[indexExp].notes.splice(indexNote, 1);
    setExperiences(newExperiences);
  };

  const handleAddExperience = () => {
    setExperiences([
      ...experiences,
      {
        company: '',
        position: '',
        startDate: '',
        endDate: '',
        notes: [],
        isCurrent: false,
      },
    ]);
  };

  function currentCheckbox(experience, index) {
    return (
      <Checkbox
        value={experience.isCurrent || false}
        name="isCurrent"
        color="primary"
        defaultChecked={experience.isCurrent || false}
        onChange={(event) => handleChange(event, index)}
      />
    );
  }

  function bulletHelperText() {
    return (
      <span
        style={{
          display: 'inline',
          float: 'right',
          cursor: 'pointer',
          fontFamily: 'Poppins',
          fontSize: '0.875rem',
          fontWeight: 400,
          lineHeight: 1.5,
        }}
      >
        Press
        <b> Enter</b>
        <KeyboardReturnOutlinedIcon sx={classes.enterIcon} />
      </span>
    );
  }

  function disabledGenerateProfile() {
    return experiences.some((exp) => {
      const isEmpty = !exp.company && !exp.position;
      const isFull =
        exp.company &&
        exp.position &&
        exp.startDate &&
        (exp.isCurrent || exp.endDate);
      return !isEmpty && !isFull;
    });
  }

  function getExperiences(experience) {
    if (
      experience.company == '' &&
      experience.position == '' &&
      experience.startDate == '' &&
      experience.endDate == ''
    ) {
      return [];
    }
    return [experience];
  }

  function handleGenerateProfile() {
    try {
      const newExperiences = experiences
        .filter((e) => e.company && e.position)
        .map((e) => {
          return JSON.stringify({
            company: e.company,
            position: e.position,
            startDate: e.startDate.toString(),
            endDate: e.isCurrent ? 'Present' : e.endDate.toString(),
            notes: e.notes,
          });
        });
      setIsLoading(true);
      updateAdvisorOnboardingStep4({
        ADVISOR_ID: accountDetails.id,
        EXPERIENCES: newExperiences,
        ONBOARDING_STATUS: 'step6',
      }).then(() => {
        setIsLoading(false);
        accountDetails.experiences =
          experiences.length > 1 ? experiences : getExperiences(experiences[0]);
        accountDetails.onboardingStatus = 'step5';
        history.push('/advisor/onboarding/step5');
      });
    } catch {
      setIsLoading(false);
      throw new Error();
    }
  }

  return (
    <div style={classes.step4}>
      <Typography sx={classes.title}>
        Current position or most recent role
      </Typography>
      <Typography sx={classes.subtitle}>
        Add the details of your current or most recent position
      </Typography>
      {experiences.map((experience, index) => (
        <div key={`experience-${index}`}>
          {index !== 0 ? (
            <div style={classes.deleteContainer}>
              <ClearIcon
                onClick={() => {
                  experiences.splice(index, 1);
                  setExperiences([...experiences]);
                }}
                sx={classes.deleteIcon}
              />
            </div>
          ) : null}

          <FormControl sx={classes.formControl}>
            <TextField
              label="Position"
              name="position"
              variant="outlined"
              style={{ height: 44 }}
              error={experience.company && !experience.position}
              InputLabelProps={{
                style: {
                  height: 44,
                  top: '-4px',
                  fontFamily: 'Poppins',
                },
              }}
              inputProps={{
                style: {
                  height: 44,
                  padding: '0 14px',
                  fontFamily: 'Poppins',
                },
              }}
              value={experience.position}
              onChange={(event) => handleChange(event, index)}
            />
          </FormControl>
          <FormControl sx={classes.formControl}>
            <TextField
              label="Company"
              name="company"
              variant="outlined"
              style={{ height: 44 }}
              error={experience.position && !experience.company}
              InputLabelProps={{
                style: {
                  height: 44,
                  top: '-5px',
                  fontFamily: 'Poppins',
                },
              }}
              inputProps={{
                style: {
                  height: 44,
                  padding: '0 14px',
                  fontFamily: 'Poppins',
                },
              }}
              value={experience.company}
              onChange={(event) => handleChange(event, index)}
            />
          </FormControl>
          <FormControl sx={classes.formControl3}>
            <FormControlLabel
              classes={{
                label: classes.step4,
              }}
              control={currentCheckbox(experience, index)}
              label="It’s my current position"
            />
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl sx={classes.formControl2}>
              <DatePicker
                views={['year']}
                value={
                  experience.startDate
                    ? new Date(parseInt(experience.startDate), 1, 1)
                    : null
                }
                minDate={new Date(1950, 1, 1)}
                maxDate={
                  experience.endDate
                    ? new Date(parseInt(experience.endDate), 1, 1)
                    : new Date()
                }
                onChange={(date) => handleChangeDate(date, 'startDate', index)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label="Start Date"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'Start Date',
                    }}
                  />
                )}
              />
            </FormControl>
            {!experience.isCurrent ? (
              <FormControl sx={classes.formControl2}>
                <DatePicker
                  fullWidth
                  views={['year']}
                  value={
                    experience.endDate
                      ? new Date(parseInt(experience.endDate), 1, 1)
                      : null
                  }
                  minDate={
                    experience.startDate
                      ? new Date(parseInt(experience.startDate), 1, 1)
                      : new Date(1950, 1, 1)
                  }
                  maxDate={new Date()}
                  onChange={(date) => handleChangeDate(date, 'endDate', index)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      style={{ marginLeft: 5, fontFamily: 'Poppins' }}
                      size="small"
                      label="End Date"
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'End Date',
                      }}
                    />
                  )}
                />
              </FormControl>
            ) : null}
          </LocalizationProvider>
          <Typography sx={classes.subtitle}>
            Add highlights from this position
          </Typography>
          <FormControl
            sx={classes.formControl}
            style={{ marginTop: 15, marginBottom: isSM ? 25 : 15 }}
          >
            <TextField
              label="Add Bullet Point"
              name="noteForm"
              variant="outlined"
              style={{ height: 44 }}
              InputLabelProps={{
                style: {
                  height: 44,
                  top: '-4px',
                  fontFamily: 'Poppins',
                },
              }}
              inputProps={{
                style: {
                  height: 44,
                  padding: '0 14px',
                  fontFamily: 'Poppins',
                },
              }}
              value={experience.noteForm}
              onChange={(event) => handleChange(event, index)}
              helperText={bulletHelperText()}
              onKeyDown={(event) => {
                return event.keyCode === 13
                  ? handleAddBulletPoint(index, event.target.value)
                  : null;
              }}
            />
          </FormControl>
          <List sx={classes.listContainer}>
            {experience.notes.map((note, index2) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem key={`note-${index2}`} sx={classes.listItem}>
                <ListItemIcon sx={classes.listItemIcon}>
                  <CircleIcon sx={{ fontSize: 7 }} color="primary" />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemLabel }}
                  primary={note}
                />
                <ClearIcon
                  onClick={() => {
                    handleRemoveBulletPoint(index, index2);
                  }}
                  sx={classes.deleteIcon}
                />
              </ListItem>
            ))}
          </List>
          {index === 0 ? (
            <div style={classes.titleContainer}>
              <Typography sx={classes.title}>Other positions</Typography>
            </div>
          ) : null}

          {index !== 0 && experiences.length > index + 1 ? (
            <Divider color="primary" sx={classes.divider} />
          ) : null}
        </div>
      ))}
      <Button
        variant="text"
        color="primary"
        sx={classes.addMoreButton}
        startIcon={<AddCircleIcon />}
        onClick={handleAddExperience}
      >
        Add another position
      </Button>
      {isLoading ? (
        <div style={classes.loadingContainer}>
          <LoadingIndicatorLogo size={200} iconFontSize={93} iconRight={105} />
        </div>
      ) : null}
      <div style={classes.sendButtonContainer}>
        <Button
          variant="contained"
          color="primary"
          sx={classes.sendButton}
          onClick={handleGenerateProfile}
          disabled={disabledGenerateProfile()}
          style={{ fontFamily: 'Poppins' }}
        >
          Generate Profile
        </Button>
      </div>
    </div>
  );
}
