import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useMediaQuery, Avatar } from '@mui/material';
import {
  getAdvisorImagePath,
  verifyAdvisorImagePath,
} from './../../../../services/utils';
import Headline from './EmptyState/Headline';
import EmptyActionButton from './EmptyActionsButton';

const useStyles = (isSM) => ({
  card: {
    width: '100%',
  },
  publicProfileHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingBottom: isSM ? '0px' : '40px',
    paddingRight: isSM ? '0px' : '20px',
    paddingTop: isSM ? '0px' : '20px',
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: '36px',
    fontWeight: 600,
    textAlign: isSM ? 'center' : 'auto',
    paddingTop: isSM ? '20px' : '0px',
  },
  headline: {
    fontSize: '16px',
    color: '#232B35',
    fontFamily: 'Poppins',
    minWidth: '0px',
    marginTop: '10px',
    position: 'relative',
    wordBreak: 'keep-all',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
    textAlign: isSM ? 'center' : 'auto',
  },
  titlePosition: {
    fontSize: '16px',
    color: '#646D7A',
    maxHeight: '50px',
    minWidth: '0px',
    marginTop: '0px',
    marginRight: '25px',
    position: 'relative',
    fontWeight: 400,
    wordBreak: 'keep-all',
    fontFamily: 'Poppins',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
    textAlign: isSM ? 'center' : 'auto',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  imageAndBiographyContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  imageContainer: {
    width: '232px',
    height: '232px',
    margin: isSM ? 'auto' : '0 20px 0 0',
    marginTop: '20px',
  },
  biographyInformationContainer: {
    display: 'flex',
    fontFamily: 'Poppins',
    marginLeft: '10px',
    width: '70%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  positionAndTimeContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '40px',
    marginBottom: '20px',
  },
  mobileImageContainer: {
    width: '100%',
    marginTop: '15px',
  },
  emptyTitle: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
    color: '#646D7A',
  },
  emptyAvatar: {
    display: 'flex',
    width: '237px',
    justifyContent: 'center',
    alignItems: 'center',
    height: '237px',
    borderRadius: '120px',
    backgroundColor: '#F5F7FA',
    margin: isSM ? 'auto' : '0 20px 0 0',
    marginTop: '20px',
  },
});

function PublicProfileCard({ advisor }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const classes = useStyles(isSM);
  return (
    <div className="profileHeaderBlock" style={classes.card}>
      {!isSM ? (
        <div style={classes.publicProfileHeaderContainer}>
          <div style={classes.mainInformationContainer}>
            <div style={classes.row}>
              <div style={classes.imageAndBiographyContainer}>
                <Avatar
                  src={getAdvisorImagePath(advisor)}
                  sx={{ ...classes.imageContainer }}
                />
                <div style={classes.biographyInformationContainer}>
                  <Typography sx={classes.title}>
                    {advisor.displayName}
                  </Typography>

                  {advisor.headline ? (
                    <>
                      <div style={classes.positionAndTimeContainer}>
                        {advisor.title && (
                          <Typography
                            sx={advisor.title ? classes.titlePosition : {}}
                          >
                            {advisor.title}
                          </Typography>
                        )}
                      </div>
                      <div>
                        <Typography sx={classes.headline}>
                          {advisor.headline}
                        </Typography>
                      </div>
                    </>
                  ) : (
                    <Headline />
                  )}
                </div>
                <EmptyActionButton />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={classes.publicProfileHeaderContainer}>
          <Box
            sx={classes.mobileImageContainer}
            display="flex"
            flexDirection="column"
          >
            <Avatar
              src={getAdvisorImagePath(advisor)}
              sx={{ ...classes.imageContainer }}
            />
            <Typography sx={classes.title}>{advisor.displayName}</Typography>
            <Typography sx={classes.headline}>{advisor.headline}</Typography>
            <EmptyActionButton />
          </Box>
        </div>
      )}
    </div>
  );
}

export default PublicProfileCard;
