import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, Typography } from '@mui/material';
import EmptyContainer from './../../Step6/EmptyContainer';

const useStyles = () => ({
  container: {
    width: '100%',
    paddingTop: '15px',
  },
  emptyTitle: {
    fontSize: '16px',
    fontWeight: 400,
    marginBottom: '10px',
  },
  itemMargin: {
    marginRight: '8px',
  },
  tagsContainer: {
    '& > *': {},
    display: 'flex',
    fontFamily: 'Poppins',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%',
    maxWidth: '100%',
  },
});

function Skills() {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { t } = useTranslation();

  return (
    <div style={classes.container} id="EmptyIndustryContainer">
      <Typography sx={classes.emptyTitle}>
        {t('ADVISOR-ONBOARDING-EMPTY-INDUSTRIES')}
      </Typography>
      <div style={classes.tagsContainer}>
        {[134, 134, 134, 134].map((skill, index) => (
          <Box sx={classes.itemMargin} key={index}>
            <EmptyContainer width={skill} rounded height={34} />
          </Box>
        ))}
      </div>
    </div>
  );
}

export default Skills;
