import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import ACButton from '../ACButton';
import ACSnackbar from '../ACSnackbar';
import { isNil } from '../../services/utils';
import Password from './PasswordsContainer';

const useStyles = (isSM) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: isSM ? '100%' : '448px',
    height: '336px',
    alignItems: 'center',
    borderRadius: '10px',
    paddingTop: '40px',
    marginBottom: '20px',
  },
  buttonContainer: {
    marginTop: '25px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  continueButton: {
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: '16px',
    height: '44px',
    width: '339px',
    lineHeight: '24px',
    borderRadius: '40px',
    color: 'white',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#3171F6',
    },
  },
  validIcon: {
    fontSize: '20px',
    color: 'primary.main',
  },
  invalidIcon: {
    fontSize: '20px',
  },
  error: {
    color: 'secondary.dark',
    marginLeft: '5px',
  },
  label: {
    color: '#646D7A',
    fontSize: '13px',
    fontWeight: 500,
    letterSpacing: '-0.044px',
  },
  tooltipText: {
    marginTop: '10px',
    fontWeight: 500,
    fontSize: '15px',
    letterSpacing: '-0.4300000071525574px',
  },
  listItemText: {
    fontSize: '13px',
    letterSpacing: '-0.4300000071525574px',
  },
  tooltipItemIcon: {
    fontSize: '5px',
    marginLeft: '-15px',
    marginRight: '-25px',
  },
  listItemContainer: {
    margin: 'auto',
    alignContent: 'center',
  },
});

function PasswordForm({
  isLoading,
  isSM,
  onContinue,
  passwordLabel,
  disableButtonOutsideState,
}) {
  const { t } = useTranslation();
  const classes = useStyles(isSM);
  const [password, setPassword] = useState('');
  const [hasError, setHasError] = useState(true);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const validations = useMemo(
    () => [
      {
        criteria: (v1 = '') => v1.length >= 8,
        labelTranslationKey: 'PASSWORD-8-CHAR-MIN-ERROR-MESSAGE',
      },
      {
        criteria: (v1 = '') => v1.match(`[A-Z]`) && v1.match(`[a-z]`),
        labelTranslationKey:
          'PASSWORD-MUST-CONTAIN-UPPER-AND-LOWER-CASE-LETTER-ERROR-MESSAGE',
      },
      {
        criteria: (v1 = '') => v1.match(`[0-9]`),
        labelTranslationKey: 'PASSWORD-MUST-CONTAIN-NUMBER-ERROR-MESSAGE',
      },
      {
        criteria: (v1 = '') => v1.match(`[@,#,%,^,*,(,),!,?,~,$,&]`),
        labelTranslationKey:
          'PASSWORD-MUST-CONTAIN-SPECIAL-CHARACTER-ERROR-MESSAGE',
      },
    ],
    []
  );

  function handleKeyUpData(data) {
    if (data.id === 'password') {
      setPassword(data.content);
    }
  }


  useEffect(() => {
    const HasError =
      validations.filter((validation) => !validation.criteria(password))
        .length > 0;

    setHasError(HasError);
  }, [password]);

  function tooltipGenerator(evaluatedPassword) {
    return (
      <>
        <Typography sx={classes.tooltipText} color="inherit">
          Password must include:
        </Typography>
        <List dense>
          {validations.map((validation) => {
            const valid = validation.criteria(evaluatedPassword);

            return (
              <ListItem sx={classes.listItemContainer}>
                <ListItemIcon sx={classes.tooltipItemIcon}>
                  <CheckCircle
                    sx={valid ? classes.validIcon : classes.invalidIcon}
                  />
                </ListItemIcon>
                <Typography sx={classes.listItemText} color="inherit">
                  {t(validation.labelTranslationKey)}
                </Typography>
              </ListItem>
            );
          })}
        </List>
      </>
    );
  }

  function renderPasswordLabel() {
    return <Typography sx={classes.label}>{passwordLabel}</Typography>;
  }


  const passwordWidth = isSM ? 150 : 339;
  return (
    <>
      <Password
        onKeyUpData={handleKeyUpData}
        passwordLabel={renderPasswordLabel()}
        width={passwordWidth}
        passwordTooltip={tooltipGenerator(password)}
        validations={validations}
      />
      <div style={classes.buttonContainer}>
        <ACButton
          data-cy="password-set-continue-button"
          type="submit"
          color="primary"
          disabled={hasError || disableButtonOutsideState}
          loading={isLoading}
          onClick={() => onContinue(password)}
          style={classes.continueButton}
        >
          Continue
        </ACButton>
      </div>
      <ACSnackbar
        open={errorSnackbar}
        text={t('PASSWORD_VALIDATION_MATCH')}
        severity="error"
        onClose={() => setErrorSnackbar(false)}
        autoHideDuration={6000}
      />
    </>
  );
}

export default PasswordForm;
