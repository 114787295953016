import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import {
  Typography,
  Select,
  MenuItem,
  OutlinedInput,
  useMediaQuery,
} from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import ACButton from './../../../ACButton';
import ACSnackbar from './../../../ACSnackbar';
import { useAccount } from './../../../../contexts/Account';
import Autocomplete from './../Autocomplete';
import { getAdvisorRecurlyPlan } from './../../../../services/advisor_services';
import { Form } from 'formik';
import Input from './../Input';
import AdvisorImageUploader from './../AdvisorImageUploader';

const useStyles = ({ isXS, isSM }) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: isXS ? 'column' : 'row',
    width: '100%',
    height: '100%',
    padding: isSM ? '0 20px' : '',
  },
  companyWebsiteContainer: {
    display: 'flex',
    flex: 'auto',
    justifyContent: 'space-between',
    flexDirection: isXS ? 'column' : 'row',
  },
  interestsInput: {
    width: '100%',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
    borderRadius: '10px',
    height: '44px',
  },
  dataImageRight: {
    width: '100%',
  },
  label: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
    marginBottom: '10px',
  },
  labelContainer: {
    maxHeight: '44px',
    width: '100%',
    maxWidth: isXS ? '' : '270px',
    marginBottom: '20px',
  },
  continueButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: isSM ? '0px' : '25px 0',
    position: isSM ? 'sticky' : '',
    bottom: '0px',
    width: isSM ? '100%' : '',
  },
  dividerMargin: {
    marginBottom: '15px',
  },
  fieldContainer: {
    marginBottom: '15px',
  },
  upperFieldContainer: {
    marginBottom: '15px',
    maxWidth: isXS ? '' : '270px',
    width: isXS ? '100%' : '45%',
  },
  upperFieldRateContainer: {
    marginBottom: '15px',
    maxWidth: isXS ? '' : '270px',
    width: isXS ? '100%' : '45%',
  },
  thumbnailContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: '250px',
    width: isXS ? '100%' : '200px',
  },
  menuItem: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
  },
});

function Step2FormikForm(props) {
  const {
    handleBlur,
    values,
    isValid,
    t,
    isSnackbarVisible,
    setIsSnackbarVisible,
    setFilePondStatus,
    submitStep2Form,
    isXS,
    setAvatarImage,
    setOriginalImage,
    setFieldValue,
    isUpdate,
    setYourInterests,
    isLoading,
    onValid,
  } = props;
  const { tags } = useAccount();

  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles({ isXS, isSM });
  const [rateEnabled, setRateEnabled] = useState(true);
  const { accountDetails, isFreemium } = useAccount();
  const isContinueButtonDisabled = !isValid;
  const interestsOptions = [
    'Board Seats',
    'Consulting',
    'Mentoring',
    'Networking',
    'New Ventures',
    'Full-Time Roles',
  ];

  async function handleACChange(event) {
    const {
      target: { value },
    } = event;
    setYourInterests(value);
    values.yourInterests = value;
  }
  function setImageFiles(images) {
    setAvatarImage(images.image);
    setOriginalImage(images.originalImage);
  }

  useEffect(() => {
    getAdvisorRecurlyPlan({
      recurlyPlanCode: accountDetails.recurlyPlanCode,
    })
      .then((result) => {
        const planCode = result.data.getRecurlyPlan;
        if (!planCode.meetingsEnabled) {
          setRateEnabled(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (onValid) {
      onValid(!isContinueButtonDisabled);
    }
  }, [isContinueButtonDisabled]);

  return (
    <Form>
      <ACSnackbar
        id="onboarding-step1-error-snackbar"
        onClose={() => setIsSnackbarVisible(false)}
        open={isSnackbarVisible}
        severity="error"
        text={t('STEP1-ERROR-SNACKBAR-TEXT')}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      <div style={classes.row}>
        <div style={classes.thumbnailContainer}>
          <AdvisorImageUploader
            resultFiles={setImageFiles}
            status={setFilePondStatus}
          />
        </div>
        <div style={classes.dataImageRight}>
          <div style={classes.companyWebsiteContainer}>
            <Input
              style={classes.upperFieldContainer}
              id="yourFirstName"
              inputProps={{
                'data-cy': 'txtYourFirstName',
              }}
              label={t('ADVISOR-ONBOARDING-STEP-1-NAME')}
              onBlur={handleBlur}
              limitText={50}
              setFieldValue={setFieldValue}
              textFieldDisplayName="First Name"
              headerInput
              size="small"
              value={values.yourFirstName}
              placeholder={t('ADVISOR-ONBOARDING-STEP-1-NAME-PLACEHOLDER')}
            />

            <Input
              style={classes.upperFieldContainer}
              id="yourLastName"
              inputProps={{
                'data-cy': 'txtYourLastName',
              }}
              onBlur={handleBlur}
              headerInput
              label={t('ADVISOR-ONBOARDING-STEP-1-LASTNAME-PLACEHOLDER')}
              limitText={50}
              setFieldValue={setFieldValue}
              textFieldDisplayName="Last Name"
              size="small"
              value={values.yourLastName}
              placeholder={t('ADVISOR-ONBOARDING-STEP-1-LASTNAME-PLACEHOLDER')}
            />
          </div>
          <div style={classes.companyWebsiteContainer}>
            {rateEnabled && (
              <Input
                style={classes.upperFieldContainer}
                id="yourHourlyRate"
                inputProps={{
                  'data-cy': 'txtYourHourlyRate',
                }}
                label={t('STEP2-YOUR-HOURLY-RATE-INPUT-LABEL')}
                onBlur={handleBlur}
                headerInput
                disabled={!rateEnabled}
                limitText={8}
                setFieldValue={setFieldValue}
                textFieldDisplayName="Hourly Rate"
                size="small"
                value={values.yourHourlyRate}
                isCurrencyField
                placeholder={t(
                  'ADVISOR-ONBOARDING-STEP-1-HOURLY-RATE-PLACEHOLDER'
                )}
              />
            )}
            {!rateEnabled && (
              <div
                style={{
                  width: isXS ? '100%' : '45%',
                  display: 'flex',
                  flexDirection: isXS ? 'column' : 'row',
                  justifyContent: 'space-between',
                  marginBottom: '16px',
                }}
              >
                <Input
                  style={classes.upperFieldRateContainer}
                  id="yourHourlyRate"
                  inputProps={{
                    'data-cy': 'txtYourHourlyRate',
                  }}
                  label={t('STEP2-YOUR-HOURLY-RATE-INPUT-LABEL')}
                  onBlur={handleBlur}
                  headerInput
                  disabled={!rateEnabled}
                  limitText={8}
                  setFieldValue={setFieldValue}
                  textFieldDisplayName="Hourly Rate"
                  size="small"
                  value={values.yourHourlyRate}
                  isCurrencyField
                  placeholder={t(
                    'ADVISOR-ONBOARDING-STEP-1-HOURLY-RATE-PLACEHOLDER'
                  )}
                />
                <div
                  style={{
                    width: isXS ? '100%' : '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'end',
                    marginBottom: '16px',
                  }}
                >
                  <Typography style={{ fontSize: '70%' }}>
                    <LockOutlined
                      color="primary"
                      sx={{ fontSize: '12px', marginRight: '2px' }}
                    />
                    {t('ADVISOR-UNLOCK-RATE')}
                  </Typography>
                  <Typography
                    color="primary"
                    onClick={() => {
                      window.location.replace(
                        `https://register.advisorycloud.com/upgrade-plan-hourly-rate/?iwm_advisor_id=${accountDetails.iwmId}&sosfcontactid=${accountDetails.salesforceContactId}&email=${accountDetails.email}`
                      );
                    }}
                    style={{
                      fontSize: '70%',
                      cursor: 'pointer',
                    }}
                  >
                    {t('ADVISOR-UPGRADE-RATE')}
                  </Typography>
                </div>
              </div>
            )}
            <div style={classes.upperFieldContainer}>
              <Typography sx={classes.label}>
                {t('STEP2-YOUR-INTERESTS-INPUT-LABEL')}
              </Typography>
              <div style={classes.labelContainer}>
                <Select
                  labelId="demo-multiple-name-label"
                  id="yourInterests"
                  multiple
                  value={values.yourInterests}
                  sx={{
                    borderRadius: '10px',
                    width: '100%',
                    maxWidth: isXS ? '' : '270px',
                    fieldset: {
                      borderColor: '#EFF0F6 !important',
                    },
                  }}
                  onChange={(e, newValue) => handleACChange(e, newValue)}
                  input={
                    <OutlinedInput sx={classes.interestsInput} label="Name" />
                  }
                >
                  {interestsOptions.map((option) => (
                    <MenuItem sx={classes.menuItem} key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {isXS && (
                <div style={classes.row}>
                  <Autocomplete
                    style={classes.fieldContainer}
                    defaultValue={values.primarySkills}
                    isStep2
                    onBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    getOptionLabel={(option) => option.label}
                    helperText={
                      <Trans i18nKey="ADVISOR-ONBOARDING-STEP-3-MINIMUM" />
                    }
                    id="primarySkills"
                    input-data-cy="txtCompanyIndustry"
                    label={t('ADVISOR-ONBOARDING-STEP-3-ENTER-TOP-SKILLS')}
                    subtitle={t(
                      'ADVISOR-ONBOARDING-STEP-3-ENTER-TOP-SKILLS-SUBTITLE'
                    )}
                    limitTags={8}
                    options={tags.skills}
                    placeholder={t(
                      'ADVISOR-ONBOARDING-STEP-3-ENTER-TOP-SKILLS-PLACEHOLDER'
                    )}
                    ListboxProps={{
                      style: { fontFamily: 'Poppins' },
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <br />

      <div style={{ ...classes.dividerMargin, ...classes.row }}>
        <Input
          style={classes.fieldContainer}
          id="yourHeadline"
          inputProps={{
            'data-cy': 'txtYourHeadline',
          }}
          label={t('COMPANY-HEADLINE-LABEL')}
          onBlur={handleBlur}
          multiline
          limitText={200}
          setFieldValue={setFieldValue}
          textFieldDisplayName="Headline"
          height={95}
          size="small"
          rows={3}
          placeholder={t('ADVISOR-ONBOARDING-STEP-1-HEADLINE-PLACEHOLDER')}
          value={values.yourHeadline}
        />
      </div>

      <div style={{ ...classes.dividerMargin, ...classes.row }}>
        <Input
          style={classes.fieldContainer}
          id="yourBiography"
          inputProps={{
            'data-cy': 'txtYourBiography',
          }}
          label={t('ADVISOR-BIOGRAPHY-LABEL')}
          multiline
          onBlur={handleBlur}
          setFieldValue={setFieldValue}
          height={269}
          textFieldDisplayName="Biography"
          placeholder={t('ADVISOR-ONBOARDING-STEP-1-BIOGRAPHY-PLACEHOLDER')}
          rows={10}
          value={values.yourBiography}
        />
      </div>

      {!isUpdate && (
        <div style={classes.continueButtonContainer}>
          <ACButton
            color="primary"
            data-cy="btnStep2Continue"
            disabled={isContinueButtonDisabled}
            loading={isLoading ? 1 : 0}
            width={isSM ? '100%' : null}
            style={{
              width: isSM ? '100%' : 200,
              fontFamily: 'Poppins',
              fontSize: isSM ? 18 : 14,
              borderRadius: isSM ? 0 : 18,
              height: isSM ? 50 : '',
            }}
            onClick={() => {
              submitStep2Form(values);
            }}
          >
            {t('CONTINUE-BUTTON-TEXT')}
          </ACButton>
        </div>
      )}
    </Form>
  );
}

export default Step2FormikForm;
