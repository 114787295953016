import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Chip,
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
  AppBar,
  IconButton,
  Paper,
  Divider,
  List,
  ListItem,
  InputBase,
  ListItemText,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

const getStyles = () => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    paddingRight: '0px',
  },
  inputLabel: {
    marginBottom: '10px',
    color: 'black',
  },
  inputContainer: {
    width: '100%',
    marginBottom: '20px',
    borderRadius: '10px',
  },
  SMinputContainer: {
    width: '100%',
    marginBottom: '10px',
    borderRadius: '10px',
  },
  label: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
    marginBottom: '10px',
  },
  subtitle: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    color: '#646D7A',
  },
  helperText: {
    color: 'grey.600',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
  },
  responsivePosition: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  chipContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  chip: {
    margin: '0 10px 10px 0',
  },
  chipText: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
  },
  toolbar: {
    width: '100%',
    marginLeft: '15px',
  },
  textFieldContainer: {
    width: '70%',
    caretColor: 'fff',
    fontFamily: 'Poppins',
    fontSize: '16px',
    '& .MuiInputBase-input': {
      color: '#fff',
    },
  },
  listItemLabel: {
    overflow: 'hidden',
    fontFamily: 'Poppins',
  },
  icon: {
    color: '#fff',
  },
});

function Input({
  className,
  helperText,
  subtitle,
  id,
  label,
  defaultValue,
  setFieldValue,
  options,
  placeholder,
  limitTags,
  handleCompanyIndustryAutocompleteChange,
  ...rest
}) {
  let [selectedValue, setSelectedValue] = useState(defaultValue);
  const [textFieldError, setTextFieldError] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [queryOptions, setQueryOptions] = useState(options);
  const [inputString, setInputString] = useState('');
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const styles = getStyles();
  const onDelete = (val) => () => {
    setSelectedValue(selectedValue.filter((v) => v.label !== val));
  };

  const handleClose = () => {
    setInputString('');
    setDialogOpen(false);
  };
  useEffect(() => {
    if (inputString && inputString != '') {
      setQueryOptions(
        options.filter((d) =>
          d.label.toLowerCase().includes(inputString.toLowerCase())
        )
      );
    } else {
      setQueryOptions(options);
    }
  }, [inputString]);

  function handleChange(e, newValue) {
    if (limitTags && limitTags === selectedValue.length) {
      setSnackMessage(`Limit ${limitTags} skills`);
      setTextFieldError(true);
    } else {
      setFieldValue(id, newValue);
      setSelectedValue(newValue);
    }
  }

  function handleDialogItemClick(newValue) {
    if (selectedValue.some((skill) => skill.id == newValue.id)) {
      selectedValue.splice(
        selectedValue.findIndex((el) => el.id == newValue.id),
        1
      );
      setFieldValue(id, selectedValue);
    } else {
      if (limitTags && limitTags === selectedValue.length) {
        setSnackMessage(`Limit ${limitTags} skills`);
        setTextFieldError(true);
      } else {
        selectedValue.push(newValue);
        setFieldValue(id, selectedValue);
      }
    }
  }
  function isAlreadySelected(skill) {
    return defaultValue.some((mySkill) => mySkill.id === skill.id);
  }

  return (
    <div style={styles.inputContainer} className={className}>
      <Modal open={dialogOpen} onClose={handleClose}>
        <Paper sx={styles.container}>
          <AppBar style={{ position: 'sticky' }}>
            <div style={styles.toolbar}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <InputBase
                sx={styles.textFieldContainer}
                placeholder="Search"
                inputProps={{ 'aria-label': 'search' }}
                value={inputString}
                onChange={(e) => setInputString(e.target.value)}
              />
              <IconButton type="button" sx={{}} aria-label="search">
                <SearchIcon sx={styles.icon} />
              </IconButton>
            </div>
          </AppBar>
          <List
            style={{
              overflowX: 'scroll',
            }}
          >
            {queryOptions.map((skill) => (
              <>
                <ListItem
                  style={{
                    backgroundColor: isAlreadySelected(skill)
                      ? 'rgba(188,188,188,255)'
                      : '#fff',
                  }}
                >
                  <ListItemText
                    // classes={{ primary: classes.listItemLabel }}
                    primary={skill.label}
                    onClick={() => handleDialogItemClick(skill)}
                  />
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
        </Paper>
      </Modal>
      {isSM ? (
        <InputLabel sx={styles.inputLabel} htmlFor={id}>
          <Typography sx={styles.label}>{label}</Typography>
          <Typography sx={styles.subtitle}>{subtitle}</Typography>
        </InputLabel>
      ) : (
        <InputLabel sx={styles.inputLabel} htmlFor={id}>
          <div style={styles.responsivePosition}>
            <Typography sx={styles.label}>{label}</Typography>
            <Typography variant="caption" sx={styles.helperText}>
              {helperText}
            </Typography>
          </div>
          <Typography sx={styles.subtitle}>{subtitle}</Typography>
        </InputLabel>
      )}

      {isSM ? (
        <>
          <div style={{ marginBottom: '20px' }}>
            <TextField
              className="inputRounded"
              error={textFieldError}
              disabled
              helperText={snackMessage}
              onClick={() =>
                isSM ? setDialogOpen(true) : console.log('Not SM')
              }
              placeholder={placeholder}
              variant="outlined"
              sx={{
                ...styles.SMinputContainer,
                width: '100%',
                marginBottom: '10px',
                borderRadius: '10px',
                fieldset: {
                  borderColor: '#EFF0F6',
                },
              }}
            />

            <Typography variant="caption" sx={styles.helperText}>
              {helperText}
            </Typography>
          </div>
        </>
      ) : (
        <Autocomplete
          {...rest}
          id={id}
          multiple
          disableCloseOnSelect
          value={selectedValue}
          options={options}
          onChange={(e, newValue) => handleChange(e, newValue)}
          renderTags={() => null}
          sx={{
            width: '100%',
            marginBottom: '10px',
            borderRadius: '10px',
          }}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                className="inputRounded"
                sx={styles.inputContainer}
                error={textFieldError}
                helperText={snackMessage}
                onClick={() =>
                  isSM ? setDialogOpen(true) : console.log('Not SM')
                }
                inputProps={{
                  ...params.inputProps,
                  'data-testid': rest['input-data-testid'],
                  'data-cy': rest['input-data-cy'],
                }}
                placeholder={placeholder}
                variant="outlined"
              />
            </>
          )}
        />
      )}

      <div style={styles.chipContainer}>
        {selectedValue.map((val) => (
          <div style={styles.chip}>
            <Chip
              label={val.label}
              key={val.id}
              onDelete={onDelete(val.label)}
              sx={styles.chipText}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Input;
