import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormikForm from './Step3FormikForm';
import { useAccount } from './../../../../contexts/Account';
import { updateAdvisorOnboardingStep3 } from './../../../../services/advisor_services/index';

function Step3Form(props) {
  const { formRef, onValid, isUpdate, updateSubmit } = props;
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [filePondStatus, setFilePondStatus] = useState(true);
  const { accountDetails, tags } = useAccount();

  const Step3Schema = Yup.object().shape({
    primarySkills: Yup.array(),
    additionalSkills: Yup.array(),
    advisorIndustry: Yup.array(),
  });

  async function sendFormData(data) {
    try {
      const primarySkillsIds = data.primarySkills.map((skill) => skill.id);
      const additionalSkillsIds = data.additionalSkills.map(
        (skill) => skill.id
      );
      primarySkillsIds.forEach((primary) => {
        if (!additionalSkillsIds.includes(primary)) {
          additionalSkillsIds.push(primary);
        }
      });
      const advisorIndustryIds = data.advisorIndustry.map((skill) => skill.id);
      const updatedAdvisor = await updateAdvisorOnboardingStep3({
        ADVISOR_ID: accountDetails.id,
        PRIMARY_SKILL_TAG_IDS: primarySkillsIds,
        SKILL_TAG_IDS: additionalSkillsIds,
        INDUSTRY_TAGS: advisorIndustryIds,
        ONBOARDING_STATUS: 'step4',
      });
      accountDetails.favoriteSkillsIds = primarySkillsIds;
      accountDetails.skillTagIds = additionalSkillsIds;
      accountDetails.industryTagIds = advisorIndustryIds;
      accountDetails.onboardingStatus = 'step4';
    } catch (err) {
      // eslint-disable-next-line no-console
      throw new Error();
    }
  }

  function submitStep3Form(data) {
    let values = data;

    if (!values && isUpdate) {
      values = formRef.current.values;
    }

    setIsLoading(true);
    Promise.all([sendFormData(values)])
      .then(() => {
        setIsLoading(false);
        window.location.replace(
          `${window.location.origin}/advisor/onboarding/step4`
        );
      })
      .catch((error) => {
        setIsLoading(false);
        setIsSnackbarVisible(true);
      });
  }
  const formInitialValues = {
    primarySkills: tags.skills.filter(
      (skill) =>
        accountDetails.favoriteSkillsIds &&
        accountDetails.favoriteSkillsIds.includes(skill.id)
    ),
    additionalSkills: tags.skills.filter(
      (skill) =>
        accountDetails.skillTagIds &&
        accountDetails.skillTagIds.includes(skill.id)
    ),
    advisorIndustry: tags.industries.filter(
      (skill) =>
        accountDetails.industryTagIds &&
        accountDetails.industryTagIds.includes(skill.id)
    ),
  };

  useEffect(() => {
    if (isUpdate && updateSubmit) {
      updateSubmit(submitStep3Form);
    }
  }, [submitStep3Form]);

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={Step3Schema}
      validateOnMount
      validateOnChange
      validateOnBlur
      innerRef={formRef}
    >
      {(formik) => {
        return (
          <FormikForm
            {...formik}
            {...props}
            t={t}
            isSnackbarVisible={isSnackbarVisible}
            setIsSnackbarVisible={setIsSnackbarVisible}
            isXS={isXS}
            setFilePondStatus={setFilePondStatus}
            submitStep3Form={submitStep3Form}
            onValid={onValid}
            isLoading={isLoading}
            filePondStatus={filePondStatus}
          />
        );
      }}
    </Formik>
  );
}

export default Step3Form;
