import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useMediaQuery,
  List,
  ListItemButton,
  IconButton,
  ListItemText,
  Box,
  Collapse,
  Typography,
  Fab,
  Avatar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AdvisoryCloudLogo from './../../AdvisoryCloudLogo';
import { getAdvisorImagePath } from './../../../services/utils';
import CardBoard from './../CardPublicSearch/boards';
import { ReactComponent as Expandcircle } from './expand-circle-down.svg';
import eventBus, { eventBusValues } from './../../../eventBus';
import { useAccount } from '../../../contexts/Account';
import { getAdvisorQueryParams } from '../../../utils/queryParams';

const useStyles = () => ({
  logoContainer: {
    position: 'absolute',
    top: '-35px',
    left: '23px',
    height: '60px',
  },
  menuItemContainer: {
    height: '62px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '32px',
    letterSpacing: '0.75px',
    color: '#232B35',
  },
  menuLabelsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  menuSecondaryLabel: {
    color: 'grey.500',
  },
  closeButtonContainer: {
    position: 'absolute',
    left: '90%',
    top: '22px',
    zIndex: 2,
    color: '#97A3B7',
  },
  boards: {
    paddingLeft: '15px',
    paddingRight: '15px',
    marginTop: '-15px',
  },
  mainContainer: {
    height: 'calc(100vh - calc(100vh - 100%) - 145px)',
    maxHeight: '90vh',
    overflowY: 'auto',
    marginTop: '60px',
  },
});

function MenuItem({
  id,
  label,
  secondaryLabel,
  onClick,
  children,
  newTag,
  arrowDown,
}) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  function renderCollapse() {
    if (children) {
      return (
        <Collapse
          in={isOpen}
          timeout="auto"
          unmountOnExit
          sx={{ paddingLeft: '24px' }}
        >
          {children}
        </Collapse>
      );
    }
    return null;
  }

  function handleMenuItemClick() {
    setIsOpen(!isOpen);

    if (onClick) {
      onClick();
    }
  }

  return (
    <>
      <ListItemButton
        sx={classes.menuItemContainer}
        onClick={handleMenuItemClick}
      >
        <div style={classes.menuLabelsContainer}>
          <ListItemText id={id} primary={label} disableTypography={true} />
          {secondaryLabel && (
            <ListItemText
              classes={classes.menuSecondaryLabel}
              primary={secondaryLabel}
            />
          )}
        </div>
        {arrowDown && <ArrowDropDownIcon />}
        {newTag && (
          <Fab
            variant="extended"
            size="small"
            color="secondary"
            role="none"
            style={{
              height: '18px',
              marginLeft: '4px',
              color: '#1976d2',
              fontSize: '9px',
              fontWeight: 'bold',
              paddingTop: '2px',
              boxShadow: 'none',
              backgroundColor: '#E7EDFD',
            }}
          >
            New
          </Fab>
        )}
      </ListItemButton>

      {renderCollapse()}
    </>
  );
}

function OptionsList({
  accountDetails,
  onDismiss,
  setIsOpen,
  backToBoardView,
  onProfileClick,
}) {
  const history = useHistory();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { isFreeTrial, isFreemium } = useAccount();

  function handleMenuItem(route) {
    history.push(route);
    onDismiss();
  }

  function handleBoardroomClick(data) {
    const companySlug = data.slug;
    const companyId = data.id;
    history.push({
      pathname: `/${companySlug}/boardroom`,
      state: companyId,
    });
    onDismiss();
  }

  function seeOpportunity(data) {
    setIsOpen(false);
    backToBoardView(true);
    eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
      companyId: data.id,
    });
  }

  return (
    <>
      <div style={classes.closeButtonContainer}>
        <CloseIcon
          onClick={() => {
            setIsOpen(false);
            backToBoardView(true);
          }}
        />
      </div>
      <div style={classes.logoContainer}>
        <AdvisoryCloudLogo
          removeHyperlink
          onClick={() => history.push('/dashboard')}
          width={189}
        />
      </div>
      <Box sx={classes.mainContainer}>
        <List>
          <MenuItem
            id="advisorDashboardMobileMenuOption"
            label="Dashboard"
            onClick={() => handleMenuItem('/dashboard')}
          />
          <MenuItem
            id="advisorAdvisoryBoardsMobileMenuOption"
            label="Advisory Boards"
            arrowDown
            children={
              <>
                <MenuItem
                  label="View Advisory Boards"
                  onClick={() => handleMenuItem('/board-directory')}
                />
                <MenuItem
                  label="Build Your Own"
                  onClick={() =>
                    window.location.assign(
                      `https://advisorycloud.com/add-a-board-to-your-membership?${getAdvisorQueryParams(
                        { accountDetails, isFreeTrial, isFreemium }
                      )}`
                    )
                  }
                />
              </>
            }
          />
          <MenuItem
            id="advisorBoardMeetingsMobileMenuOption"
            label="Board Meetings"
            onClick={() => handleMenuItem('/board-meetings')}
          />
          <MenuItem
            id="advisorNetworkMobileMenuOption"
            label="Network"
            onClick={() => handleMenuItem('/networking')}
          />
          <MenuItem
            id="advisorMessagesMobileMenuOption"
            label="Messages"
            onClick={() => handleMenuItem('/messages')}
          />
        </List>
        <div style={classes.boards}>
          {accountDetails.boards.memberBoards.map((bm, index) => {
            return (
              <CardBoard
                key={index}
                onClick={() => handleBoardroomClick(bm)}
                companyLogo={bm.companyLogo}
                companyName={bm.companyName}
                status="member"
              />
            );
          })}
          {accountDetails.boards.invitedBoards.map((bm, index) => {
            return (
              <CardBoard
                key={index}
                onClick={() => seeOpportunity(bm)}
                companyLogo={bm.companyLogo}
                companyName={bm.companyName}
                status="invitation"
              />
            );
          })}
          {accountDetails.boards.appliedBoards.map((bm, index) => {
            return (
              <CardBoard
                key={index}
                onClick={() => seeOpportunity(bm)}
                companyLogo={bm.companyLogo}
                companyName={bm.companyName}
                status="pending"
              />
            );
          })}
        </div>
      </Box>

      <Box
        id="advisorMobileSubMenu"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          position: 'absolute',
          bottom: 0,
          padding: '4px 10px 10px 30px',
        }}
        onClick={onProfileClick}
      >
        <Avatar
          src={getAdvisorImagePath(accountDetails)}
          alt={accountDetails.displayName}
          sx={{ width: '60px', height: '60px' }}
        />
        <Typography
          style={{
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '32px',
            letterSpacing: 0.75,
            color: '#232B35',
            textAlign: 'left',
            width: '100%',
            paddingLeft: '20px',
          }}
        >
          {accountDetails.displayName}
        </Typography>
        <IconButton sx={{ ml: 'auto' }}>
          <Expandcircle
            style={{
              color: '#232B35',
            }}
          />
        </IconButton>
      </Box>
    </>
  );
}

export default OptionsList;
