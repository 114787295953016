import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { TextField } from 'formik-mui';
import { Formik, Form, Field } from 'formik';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ACButton from '../ACButton';
import * as Yup from 'yup';
import { logIn, forgotPassword } from '../../services/Authentication';
import { Transitions } from '../../services/Transition';
import { getConsoleEnvironmentUrl } from '../../services/utils';
import { useAccount } from '../../contexts/Account';
import {
  LoginResponses,
  LoginResponseTypes,
} from '../../services/Authentication/constants';
import ACSnackbar from '../ACSnackbar';

const useStyles = () => ({
  buttonContainer: {
    marginTop: '3vh',
  },
  registerContainer: {
    marginTop: '2vh',
  },
  button: {
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 'bold',
    fontFamily: 'Poppins',
  },
  errorMessage: {
    maxWidth: '300px',
    textAlign: 'center',
    marginTop: '8px',
    color: 'error.main',
    fontFamily: 'Poppins',
  },
  fieldError: {
    marginLeft: '15px',
    marginTop: '2px',
    color: 'error.main',
    fontSize: '85%',
    fontFamily: 'Poppins',
  },
});

const FieldWrapper = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-input': {
      color: theme.palette.common.black,
    },

    '& .MuiInputBase-input:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 100px #fafafa inset',
    },

    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      backgroundColor: '#fff',
    },

    '& .MuiInput-underline:hover:before': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.main,
    },

    '& .Mui-error:after': {
      borderBottomColor: theme.palette.error.main,
    },
  },
}))(Field);

function FormLogin(props) {
  const location = useLocation();
  const { t } = useTranslation();
  const { authenticate } = useAccount();
  const [errorMessage, setErrorMessage] = useState();
  const [fieldError, setFieldError] = useState(false);
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(
    'Something went wrong'
  );
  const { usernameProps, passwordProps, LostPasswordComponent } = props;
  const classes = useStyles();
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);
  const [initialValidForm, setInitialValidForm] = useState(true);
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const url = new URL(window.location);
  const redirectUrl = url.search.replace('?redirectUrl=', '');
  const missingLegacyId = queryParams.get('missingLegacyId');

  const SignupSchema = Yup.object().shape({
    username: Yup.string()
      .email(`${t('USERNAME_REQUIRED')}`)
      .required(`${t('USERNAME_REQUIRED')}`),
    password: Yup.string().required(`${t('PASSWORD_REQUIRED')}`),
  });

  async function submitForm(data) {
    try {
      setLoading(true);
      const loginResponse = await logIn(
        data.username,
        data.password,
        authenticate,
        false
      );

      if (loginResponse.response === LoginResponseTypes.ADMIN) {
        window.location.replace(getConsoleEnvironmentUrl());
      } else if (loginResponse.response === LoginResponseTypes.USER_MIGRATED) {
        const clientMetadata = { workflow: 'user-migrated' };
        forgotPassword(data.username, clientMetadata);
        props.history.push({
          pathname: '/password/reset',
          state: {
            isFirstTime: Transitions.Types.FIRST_TIME,
            isFirstTimeEmail: data.username,
          },
        });
      } else if (
        loginResponse.response === LoginResponseTypes.ERROR_IN_COGNITO_RESPONSE
      ) {
        setErrorMessage(loginResponse.message);
        setLoading(false);
      } else if (
        loginResponse.response === LoginResponseTypes.COGNITO_UNKNOW_ERROR
      ) {
        if (
          loginResponse.data &&
          loginResponse.data.message ===
            LoginResponses.INCORRECT_USERNAME_PASSWORD_CODE
        ) {
          setSnackbarMessage(LoginResponses.INCORRECT_USERNAME_PASSWORD_CODE);
        }
        setIsSnackbarVisible(true);
        setLoading(false);
        window.scrollTo(0, 0);
      } else if (loginResponse.response === LoginResponseTypes.LOGIN_SUCCESS) {
        if (redirectUrl) {
          props.history.push(redirectUrl);
          // } else if (
          //   props.history.location.state &&
          //   props.history.location.state.from
          // ) {
          //   props.history.push(
          //     props.history.location.state.from.pathname +
          //       props.history.location.state.from.search
          //   );
        } else {
          props.history.push('/dashboard', { missingLegacyId });
        }
      }
    } catch (err) {
      setIsSnackbarVisible(true);
      window.scrollTo(0, 0);
    }
  }

  function handleFormChange() {
    setInitialValidForm(false);
    setErrorMessage(null);
    setFieldError(false);

    if (formikRef.current) {
      formikRef.current.validateForm();
    }
  }

  function getErrorMessage() {
    if (
      errorMessage === LoginResponses.INCORRECT_USERNAME_PASSWORD_CODE ||
      errorMessage === LoginResponses.USER_NOT_FOUND_CODE ||
      errorMessage === LoginResponses.PASSWORD_RESET_REQUIRED_EXCEPTION
    ) {
      setFieldError(true);
      return (
        <Typography sx={classes.errorMessage}>
          {t('INVALID_CREDENTIALS')}
        </Typography>
      );
    }
    if (errorMessage === LoginResponses.PASSWORD_ATTEMPTS_EXCEEDED) {
      return (
        <Typography sx={classes.errorMessage}>
          {t('MAX_PASSWORD_ATTEMPTS')}
        </Typography>
      );
    }
    if (errorMessage === LoginResponses.NOT_AUTHORIZED_EXCEPTION) {
      return (
        <Typography sx={classes.errorMessage}>
          {t('ACCOUNT-DEACTIVED')}
        </Typography>
      );
    }
    return null;
  }

  return (
    <>
      <ACSnackbar
        text={snackbarMessage}
        onClose={() => setIsSnackbarVisible(false)}
        open={isSnackbarVisible}
        severity="error"
      />

      <Formik
        initialValues={{
          username: email || '',
          password: '',
        }}
        innerRef={formikRef}
        validationSchema={SignupSchema}
        onSubmit={submitForm}
        validateOnBlur
        validateOnChange
      >
        {(formik) => {
          const { isValid, touched, handleSubmit, errors } = formik;
          return (
            <Form
              role="form"
              aria-label={`${t('ARIA_LABEL_LOGIN_FORM')}`}
              data-testid="formLogin"
              style={{
                width: '100%',
                marginTop: '2vh',
                display: 'flex',
                flexDirection: 'column',
                fontFamily: 'Poppins',
              }}
              onChange={handleFormChange}
              onSubmit={handleSubmit}
            >
              <FieldWrapper
                data-testid="txtUsername"
                style={{ marginTop: '2vh' }}
                error={Boolean(fieldError || errors.username)}
                component={TextField}
                variant="outlined"
                type="email"
                label={
                  <div style={{ fontFamily: 'Poppins' }}>{t('USERNAME')}</div>
                }
                name="username"
                role="textbox"
                autoComplete="username"
                aria-label={t('ARIA_LABEL_LOGIN_USERNAME')}
                inputProps={{
                  'aria-label': `${t('ARIA_LABEL_LOGIN_USERNAME')}`,
                  'aria-required': 'true',
                }}
                {...usernameProps}
              />

              <FieldWrapper
                data-testid="txtPassword"
                style={{ marginTop: '2vh' }}
                component={TextField}
                error={Boolean(fieldError || errors.password)}
                variant="outlined"
                type="password"
                label={
                  <div style={{ fontFamily: 'Poppins' }}>{t('PASSWORD')}</div>
                }
                name="password"
                autoComplete="current-password"
                role="textbox"
                aria-label={t('ARIA_LABEL_LOGIN_PASSWORD')}
                inputProps={{
                  'aria-label': `${t('ARIA_LABEL_LOGIN_PASSWORD')}`,
                  'aria-required': 'true',
                }}
                {...passwordProps}
              />

              {LostPasswordComponent}

              {errorMessage ? getErrorMessage() : null}
              <div style={classes.buttonContainer}>
                <ACButton
                  data-testid="btnSubmit"
                  type="submit"
                  color="primary"
                  style={{
                    borderRadius: '20px',
                    width: '100%',
                    minHeight: '33px',
                  }}
                  loading={loading ? 1 : 0}
                  disabled={
                    !initialValidForm
                      ? !(isValid && Object.keys(touched).length >= 1)
                      : false
                  }
                >
                  <Typography
                    sx={classes.button}
                    style={{ fontWeight: 'bold' }}
                  >
                    {loading ? ' ' : <> {t('LOGIN_TITLE')}</>}
                  </Typography>
                </ACButton>
              </div>
              <div style={classes.registerContainer}>
                <Typography
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontFamily: 'Poppins',
                    fontWeight: '400px',
                    fontSize: '14px',
                    marginBottom: '10px',
                  }}
                >
                  {t('NOT_A_MEMBER')}
                </Typography>
                <ACButton
                  data-testid="btnAccount"
                  type="button"
                  size="large"
                  color="primary"
                  variant="outlined"
                  style={{ borderRadius: '20px', width: '100%' }}
                  onClick={() => {
                    window.open(
                      'https://advisorycloud.com/plans-and-pricing',
                      '_blank'
                    );
                  }}
                >
                  <Typography
                    sx={classes.button}
                    style={{ fontWeight: 'bold' }}
                  >
                    {t('BECOME_AN_ADVISOR')}
                  </Typography>
                </ACButton>
                <ACButton
                  data-testid="btnAccount"
                  type="button"
                  color="primary"
                  variant="outlined"
                  size="large"
                  style={{
                    borderRadius: '20px',
                    width: '100%',
                    marginTop: '20px',
                  }}
                  onClick={() => {
                    window.open(
                      'https://advisorycloud.com/get-started-free',
                      '_blank'
                    );
                  }}
                >
                  <Typography
                    sx={classes.button}
                    style={{ fontWeight: 'bold' }}
                  >
                    {t('BUILD_AN_ADVISORY_BOARD')}
                  </Typography>
                </ACButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default withRouter(FormLogin);
