import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { useAccount } from '../../contexts/Account';

const useStyles = (isSM) => ({
  upgradeBanner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '53px',
    zIndex: 5,
  },
  fixedContainer: {
    width: '100%',
    height: '57px',
    color: 'white',
    backgroundColor: '#6736FA',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: isSM ? '10px' : '50px',
    paddingRight: isSM ? '20px' : '50px',
    border: 'none',
    cursor: 'pointer',
    zIndex: 5,
    position: 'fixed',
    display: 'inline',
  },
  text: {
    fontSize: isSM ? '16px' : '18px',
    fontFamily: 'Poppins',
  },
  boldText: {
    fontSize: isSM ? '16px' : '18px',
    fontFamily: 'Poppins',
    fontWeight: 800,
  },
});

const TIER = {
  Essentials: 'ESSENTIALS',
  Professional: 'PROFESSIONAL',
  Executive: 'EXECUTIVE',
};

const UpgradeBanner = () => {
  const { t } = useTranslation();
  const { currentPlan, accountDetails, currentUserInfo } = useAccount();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { salesforceValues, specialOffersUrl } = currentPlan;

  function goToUpgradePage() {
    let url = `${specialOffersUrl}?email=${currentUserInfo.attributes.email}&sosfcontactid=${accountDetails.salesforceContactId}&plancode=${accountDetails.recurlyPlanCode}`;
    window.location.replace(url);
  }

  return (
    <div style={classes.upgradeBanner}>
      <button
        type="button"
        style={classes.fixedContainer}
        onClick={() => goToUpgradePage()}
      >
        <span style={classes.boldText}>
          {t(`UPGRADE_BANNER_${TIER[salesforceValues.tier]}`)}
        </span>
        &nbsp;
        <span style={classes.text}>{t('UPGRADE_BANNER_MIDDLE_TEXT')}</span>
        &nbsp;
        <span style={classes.boldText}>{t('UPGRADE_BANNER_LEARN_MORE')}</span>
      </button>
    </div>
  );
};

export default UpgradeBanner;
