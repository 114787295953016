import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useMediaQuery, Dialog, Typography } from '@mui/material';
import { ModeOutlined, PlayCircleOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { RWebShare } from 'react-web-share';

import { ReactComponent as LinkedInIcon } from './linkedin.svg';
import { ReactComponent as ReplyIcon } from './reply.svg';
import { useAccount } from '../../contexts/Account';
import ACSnackbar from '../../components/ACSnackbar';
import LoadingIndicatorLogo from '../../components/LoadingIndicatorLogo';
import Step6 from '../../components/Advisor/Onboarding/Step6/index';
import Step6Header from '../../components/Advisor/Onboarding/Step6/Step6Header';
import LinkedInPostModal from '../../components/LinkedInPostModal';
import {
  getPublicAdvisorRecurlyPlan,
  updateAdvisorOnboardingStep6,
} from '../../services/advisor_services';
import {
  isLinkedinAuthenticated,
  authorizeWithPopUp,
  getAccessToken,
  closeChild,
} from '../../services/LinkedIn';
import { getPublicAdvisorTags } from '../../services/Backend/account';

const useStyles = (isSM) => ({
  loadingContainer: {
    alignItems: 'center',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    left: '0px',
    position: 'fixed',
    right: '0px',
    top: '0px',
  },
  companyContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  blueContainer: {
    width: '100%',
    position: 'absolute',
    height: '270px',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'space-around',
    top: '80px',
    backgroundColor: '#1663F7',
  },
  blueContainerReplica: {
    width: '-moz-available',
    width: '-webkit-fill-available',
    position: 'absolute',
    height: '100px',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '1150px',
    marginLeft: '2%',
    marginRight: '2%',
    top: '95px',
    backgroundColor: 'rgba(0,0,0,0)',
  },
  blueContainerTitle: {
    paddingRight: '10px',
    marginTop: '19px',
    fontSize: '16px',
    fontWeight: 400,
    color: isSM ? 'black' : '#FFFFFF',
    zIndex: isSM ? 0 : 100,
  },
  editContainer: {
    display: 'flex',
    flexDirection: 'row',
    color: '#FFFFFF',
    cursor: 'pointer',
    height: '50px',
  },
  iframeContainer: {
    position: 'relative',
    paddingBottom: isSM ? '0px' : '50%',
    height: isSM ? '100%' : '550px',
    width: isSM ? '100%' : '800px',
    overflow: 'hidden',
  },
  iframe: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    overflowX: 'visible',
  },
  viewContainer: {
    display: 'flex',
    flex: 'auto',
    justifyContent: 'center',
    overflowX: 'hidden',
    maxWidth: '1200px',
    minWidth: isSM ? '' : '1000px',
    zIndex: 1,
    boxShadow: '0px 8px 30px rgba(54, 118, 244, 0.10)'
  },
  childrenContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#F5F7FA',
  },
  linkContainer: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
  },
  link: {
    border: 'solid 2px white',
    padding: '0px 8px',
    borderRadius: '10px',
    width: 'fit-content',
    maxHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'text',
  },
});

function Step6Layout() {
  const { t } = useTranslation();
  const [meetUrl, setMeetUrl] = useState('');
  const [onboardingRedirectUrl, setOnboardingRedirectUrl] = useState('');
  const { accountDetails } = useAccount();
  const [skills, setSkills] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [openMeetIFrame, setOpenMeetIFrame] = useState(false);
  const [linkedInPostModal, setLinkedInPostModal] = useState(false);
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isMd = useMediaQuery((theme) => theme.breakpoints.down([1140]));
  const classes = useStyles(isSM);

  const advisor = accountDetails;
  const publicProfile = `${window.location.origin}/profile/${advisor.publicProfileUrl}`;
  const displayProfile = publicProfile.split('https://app.').join('');

  function handleEditClick() {
    const afterOnboarding = () => {
      accountDetails.onboardingStatus = 'Complete';
      accountDetails.onboardingProcess = 'Complete';
      let editParam = '?profile=edit';
      if (onboardingRedirectUrl.includes('?')) {
        editParam = '&profile=edit';
      }
      window.location.replace(`${onboardingRedirectUrl}${editParam}`);
    };
    finishOnboarding(afterOnboarding);
  }

  function handleViewOpportunitiesClick() {
    const afterOnboarding = () => {
      accountDetails.onboardingStatus = 'Complete';
      accountDetails.onboardingProcess = 'Complete';
      window.location.replace(onboardingRedirectUrl);
    };
    finishOnboarding(afterOnboarding);
  }

  async function finishOnboarding(callBack) {
    try {
      setIsLoading(true);
      await updateAdvisorOnboardingStep6({
        ADVISOR_ID: accountDetails.id,
        ONBOARDING_STATUS: 'Complete',
      });
      callBack();
    } catch (err) {
      console.log(err);
      throw new Error();
    }
  }

  async function linkedInAction() {
    const isAuthenticatedLinkedIn = isLinkedinAuthenticated();
    if (isAuthenticatedLinkedIn) {
      setLinkedInPostModal(true);
    } else {
      await authorizeWithPopUp();
    }
  }

  async function linkedInResponse(linkedinResponse) {
    if (linkedinResponse === 201) {
      setSuccessMessage('Your profile is shared successfully.');
      setSuccessSnackbar(true);
    } else if (linkedinResponse === 422) {
      setErrorMessage(
        'You have already posted this content, either update the post or try again in 10 minutes'
      );
      setErrorSnackbar(true);
    } else {
      setErrorMessage('An error occurred while sharing your profile.');
      setErrorSnackbar(true);
    }
  }

  React.useEffect(() => {
    getPublicAdvisorRecurlyPlan({
      recurlyPlanCode: advisor.recurlyPlanCode,
    })
      .then((result) => {
        const planCode = result.data.getRecurlyPlan;
        setOnboardingRedirectUrl(planCode.onboardingRedirectUrl);
        if (planCode.meetingsEnabled) {
          setMeetUrl(
            `https://register.advisorycloud.com/connect-with-me/a.html?first_name=${advisor.givenName}&last_name=${advisor.surName}&sosfcontactid=${advisor.salesforceContactId}&id=${advisor.id}&hourly_rate=${advisor.meetingRate}`
          );
        } else {
          setMeetUrl(
            `https://register.advisorycloud.com/connect-with-me/b.html?first_name=${advisor.givenName}&last_name=${advisor.surName}&sosfcontactid=${advisor.salesforceContactId}&id=${advisor.id}`
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [advisor]);

  useEffect(() => {
    getPublicAdvisorTags({
      skillsData: true,
      industriesData: true,
      positionsData: false,
    }).then((response) => {
      if (!response.errors) {
        setSkills(response.data.getTags.skillsData);
        setIndustries(response.data.getTags.industriesData);
      }
    });
    window.linkedinWait = true;

    const interval = setInterval(() => {
      if (window.linkedinSuccess) {
        clearInterval(interval);
        closeChild();
        getAccessToken(window.linkedinSuccess);
        setLinkedInPostModal(true);
      }
    }, 600);

    if (isLinkedinAuthenticated()) {
      window.linkedinWait = undefined;
      clearInterval(interval);
    }
    return () => {
      window.linkedinWait = undefined;
      clearInterval(interval);
    };
  }, []);

  function handleCloseDialog() {
    setOpenMeetIFrame(false);
  }

  function renderBaseComponents() {
    return (
      <div style={classes.childrenContainer}>
        <div style={classes.viewContainer}>
          <Step6
            advisor={advisor}
            skills={skills}
            industries={industries}
            setOpenMeetIFrame={setOpenMeetIFrame}
          />
        </div>
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>{t('ONBOARDING-HELMET-TITLE')}</title>
      </Helmet>
      <Dialog
        open={openMeetIFrame}
        fullScreen={isSM}
        maxWidth="md"
        height="md"
        onClose={handleCloseDialog}
      >
        <div style={classes.iframeContainer}>
          <iframe
            src={meetUrl}
            title="Meet"
            frameBorder="0"
            style={classes.iframe}
          />
        </div>
      </Dialog>
      {isLoading && (
        <div style={classes.loadingContainer}>
          <LoadingIndicatorLogo size={200} iconFontSize={93} iconRight={105} />
        </div>
      )}
      {!isLoading && (
        <div style={classes.companyContainer}>
          <Step6Header
            handleViewOpportunitiesClick={handleViewOpportunitiesClick}
          />
          {!isSM ? (
            <>
              <div style={classes.blueContainer} />
              <div style={classes.blueContainerReplica}>
                <div className="tourTrigger" style={classes.editContainer}>
                  <PlayCircleOutlined
                    style={{
                      marginTop: '17px',
                      marginRight: 5,
                      width: '34px',
                      height: '27px',
                    }}
                  />
                  <Typography sx={classes.blueContainerTitle}>
                    {t('STEP6-ADVISOR-BLUE-CONTAINER-TOUR')}
                  </Typography>
                </div>
                <div style={classes.editContainer} id="actionsContainer">
                  <div style={{ display: 'flex' }} id="editButton">
                    <ModeOutlined
                      style={{
                        marginTop: '16px',
                        marginRight: 5,
                        width: '31px',
                        height: '26px',
                      }}
                    />
                    <Typography
                      onClick={() => handleEditClick()}
                      sx={classes.blueContainerTitle}
                    >
                      {t('EDIT-BUTTON-TEXT')}
                    </Typography>
                  </div>
                  <div style={{ display: 'flex' }} id="shareOnLinkedInButton">
                    <LinkedInIcon
                      style={{
                        marginTop: '16px',
                        marginRight: 5,
                        fontSize: 28,
                      }}
                    />
                    <Typography
                      onClick={linkedInAction}
                      sx={classes.blueContainerTitle}
                    >
                      {t('STEP6-ADVISOR-PROMOTE')}
                    </Typography>
                  </div>
                  <div style={{ display: 'flex' }} id="copyPublicProfileURL">
                    <RWebShare
                      data={{
                        text: `${t('ADVISOR-LINKEDIN-POST-TEXT')}`,
                        url: publicProfile,
                        title: 'Share your advisor profile',
                      }}
                      onClick={() => {
                        setSuccessMessage(
                          'Your profile is shared successfully.'
                        );
                        setSuccessSnackbar(true);
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <ReplyIcon
                          style={{
                            marginTop: '15px',
                            marginRight: 5,
                            transform: 'scaleX(-1)',
                            width: '30px',
                            height: '29px',
                          }}
                        />
                        <Typography sx={classes.blueContainerTitle}>
                          {t('SHARE-LABEL')}
                        </Typography>
                      </div>
                    </RWebShare>

                    {!isMd && (
                      <div style={classes.linkContainer}>
                        <div style={classes.link}>{displayProfile}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-evenly',
                backgroundColor: '#f1f5fa',
              }}
              id="actionsContainer"
            >
              <div style={{ display: 'flex' }} id="editButton">
                <ModeOutlined style={{ marginTop: '19px', marginRight: 5 }} />
                <Typography
                  onClick={() => handleEditClick()}
                  sx={classes.blueContainerTitle}
                >
                  {t('EDIT-BUTTON-TEXT')}
                </Typography>
              </div>
              <div style={{ display: 'flex' }} id="shareOnLinkedInButton">
                <LinkedInIcon
                  style={{ marginTop: '19px', marginRight: 5, fontSize: 24 }}
                />
                <Typography
                  onClick={linkedInAction}
                  sx={classes.blueContainerTitle}
                >
                  {t('STEP6-ADVISOR-PROMOTE')}
                </Typography>
              </div>
              <div style={{ display: 'flex' }} id="copyPublicProfileURL">
                <RWebShare
                  data={{
                    text: `${t('ADVISOR-LINKEDIN-POST-TEXT')}`,
                    url: publicProfile,
                    title: 'Share your advisor profile',
                  }}
                  onClick={() => {
                    setSuccessMessage('Your profile is shared successfully.');
                    setSuccessSnackbar(true);
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <ReplyIcon
                      style={{
                        marginTop: '19px',
                        marginRight: 5,
                        transform: 'scaleX(-1)',
                      }}
                    />
                    <Typography sx={classes.blueContainerTitle}>
                      {t('SHARE-LABEL')}
                    </Typography>
                  </div>
                </RWebShare>
              </div>
            </div>
          )}
          {renderBaseComponents()}
        </div>
      )}
      <ACSnackbar
        open={successSnackbar}
        text={successMessage}
        severity="success"
        onClose={() => setSuccessSnackbar(false)}
        autoHideDuration={6000}
      />
      <ACSnackbar
        open={errorSnackbar}
        text={errorMessage}
        severity="error"
        onClose={() => setErrorSnackbar(false)}
        autoHideDuration={6000}
      />
      {linkedInPostModal ? (
        <>
          <LinkedInPostModal
            linkedInPostModal={linkedInPostModal}
            setLinkedInPostModal={setLinkedInPostModal}
            linkedInResponse={linkedInResponse}
            fontFamily={'Poppins'}
            shareProfile
          />
        </>
      ) : null}
    </>
  );
}

export default Step6Layout;
