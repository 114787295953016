import React, { useEffect, useState, useRef } from 'react';
import { Paper, Typography, useMediaQuery, Box, Avatar } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import AdvisoryCloudLogo from './../../AdvisoryCloudLogo';
import { getAdvisorImagePath } from './../../../services/utils';
import { useAccount } from './../../../contexts/Account';
import { useHistory } from 'react-router-dom';
import eventBus, { eventBusValues } from './../../../eventBus';
import CardBoard from './boards';
import NoBoardCard from './noBoard';
import BuildBoardCard from './buildBoard';
import ActionButtons from './actions';
import StarSymbol from './../Profile/components/star-symbol.svg';
import StarSymbolGray from './../Profile/components/star-symbol-gray.svg';
import { getAdvisorQueryParams } from '../../../utils/queryParams';

const useStyles = ({ isSM, isFreeTrial, showBanner }) => ({
  outerContainer: {
    minWidth: isSM ? '0px' : '280px',
    maxWidth: isSM ? '0px' : '350px',
    width: isSM ? '0px' : '20%',
    overflow: 'auto',
  },
  container: {
    minWidth: '280px',
    maxWidth: '350px',
    boxSizing: 'border-box',
    width: '20%',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: '0px',
    textAlign: 'left',
    display: isSM ? 'none' : 'flex',
    scrollbarGutter: 'stable',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '30px',
    top: isFreeTrial ? '50px' : showBanner ? '57px' : '0px',
    position: 'fixed',
    overflowY: 'hidden',
    minHeight: isFreeTrial
      ? 'calc(100vh - 50px)'
      : showBanner
      ? 'calc(100vh - 57px)'
      : '100vh',
    maxHeight: isFreeTrial
      ? 'calc(100vh - 50px)'
      : showBanner
      ? 'calc(100vh - 57px)'
      : '100vh',
    boxShadow: 'none',
    border: '1px solid #F0F3F5',
    '&::-webkit-scrollbar': {
      width: '8px',
      backgroundColor: '#F8F8F8',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: '#C7C7C7',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#B7B7B7',
    },
    '&::-webkit-scrollbar-thumb:active': {
      backgroundColor: '#B7B7B7',
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
    },
    '&:hover': {
      overflowY: 'auto',
    },
  },
  avatarContainer: {
    marginTop: '70px',
    display: isSM ? 'none' : 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#FBFCFD',
    border: '1px solid #F5F7FA',
    borderRadius: '12px',
    padding: '20px 15px',
    width: '100%',
  },
  imageWrapper: {
    display: 'flex',
  },
  logoContainer: {
    position: 'absolute',
    top: '-5px',
  },
  displayName: {
    color: '#232B35',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '32px',
    letterSpacing: '0.75px',
  },
  headline: {
    textAlign: 'center',
    wordWrap: 'break-word',
    color: '#1C2F49',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '10px',
    lineHeight: '15px',
    letterSpacing: '0.75px',
    marginBottom: '10px',
  },
  title: {
    color: '#74879A',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '0.75px',
    marginBottom: '10px',
    marginTop: '4px',
  },
});

function AdvisorDashboardLeftMenu() {
  const ref = useRef();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { accountDetails, isFreeTrial, currentPlan, type, isFreemium } =
    useAccount();
  const isFirefox = typeof InstallTrigger !== 'undefined';
  const [haveScroll, setHaveScroll] = useState(false);
  const [advisor, setAdvisor] = useState(accountDetails);
  const [freemiumAcPlusBoards, setFreemiumAcPlusBoards] = useState({
    memberBoards: [],
    invitedBoards: [],
    appliedBoards: [],
  });
  const [nonFreemiumAcPlusBoards, setNonFreemiumAcPlusBoards] = useState({
    memberBoards: [],
    invitedBoards: [],
    appliedBoards: [],
  });
  const { salesforceValues } = currentPlan;
  const showBanner =
    !isFreeTrial &&
    salesforceValues.billingCycle === 'Monthly' &&
    ['Essentials', 'Professional', 'Executive'].includes(salesforceValues.tier);
  const classes = useStyles({ isSM, isFreeTrial, showBanner });
  const history = useHistory();

  async function classifyBoards(boards, type) {
    const freemiumAcPlus = [];
    const nonFreemiumAcPlus = [];

    for (const board of boards) {
      if (board.isFreemium || board.isAcPlus) {
        freemiumAcPlus.push(board);
      } else {
        nonFreemiumAcPlus.push(board);
      }
    }

    setFreemiumAcPlusBoards((prev) => ({ ...prev, [type]: freemiumAcPlus }));
    setNonFreemiumAcPlusBoards((prev) => ({
      ...prev,
      [type]: nonFreemiumAcPlus,
    }));
  }

  useEffect(() => {
    if (advisor.boards) {
      classifyBoards(advisor.boards.memberBoards, 'memberBoards');
      classifyBoards(
        advisor.boards.invitedBoards.filter(
          (b) =>
            b.opportunityStage !== 'position_filled' &&
            b.opportunityStage !== 'dormant' &&
            b.opportunityStage !== 'pending_cancellation' &&
            b.boardStatus !== 'inactive' &&
            b.boardStatus !== 'paused'
        ),
        'invitedBoards'
      );
      classifyBoards(advisor.boards.appliedBoards, 'appliedBoards');
    }
  }, [advisor.boards]);

  useEffect(() => {
    setHaveScroll(ref.current.scrollHeight > ref.current.clientHeight);
    window.addEventListener('resize', updateDimensions);
  }, []);

  function updateDimensions() {
    if (ref && ref.current) {
      setHaveScroll(ref.current.scrollHeight > ref.current.clientHeight);
    }
  }

  function handleBoardroomClick(data) {
    const companySlug = data.slug;
    const companyId = data.id;
    history.push({
      pathname: `/${companySlug}/boardroom`,
      state: companyId,
    });
  }

  function seeOpportunity(data) {
    eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
      companyId: data.id,
    });
  }

  useEffect(() => {
    eventBus.on(eventBusValues.advisorAnsweredInvitation, () => {
      if (advisor.boards) {
        classifyBoards(advisor.boards.memberBoards, 'memberBoards');
        classifyBoards(
          advisor.boards.invitedBoards.filter(
            (b) =>
              b.opportunityStage !== 'position_filled' &&
              b.opportunityStage !== 'dormant' &&
              b.opportunityStage !== 'pending_cancellation' &&
              b.boardStatus !== 'inactive' &&
              b.boardStatus !== 'paused'
          ),
          'invitedBoards'
        );
      }
      setAdvisor({ ...accountDetails });
    });
    eventBus.on(eventBusValues.advisorRequestedToJoinCompany, () => {
      if (advisor.boards) {
        classifyBoards(advisor.boards.appliedBoards, 'appliedBoards');
      }
      setAdvisor({ ...accountDetails });
    });

    return () => {
      eventBus.remove(eventBusValues.advisorAnsweredInvitation);
      eventBus.remove(eventBusValues.advisorRequestedToJoinCompany);
    };
  }, [advisor.boards]);

  return (
    <div style={classes.outerContainer}>
      <Paper
        sx={{
          ...classes.container,
          paddingRight: haveScroll ? (isFirefox ? '13px' : '20px') : '20px',
        }}
        ref={ref}
      >
        <div style={classes.logoContainer}>
          <AdvisoryCloudLogo
            removeHyperlink
            onClick={() => {
              eventBus.dispatch(eventBusValues.closeAdvisorDrawer);
              if (location.pathname !== '/dashboard') {
                history.push('/dashboard');
              }
            }}
            width={153}
          />
        </div>
        <Box sx={classes.avatarContainer}>
          <div role="none" style={classes.imageWrapper} onKeyDown={() => {}}>
            {advisor.featuredAdvisor ? (
              <div style={{ display: 'flex', position: 'relative' }}>
                <img
                  src={StarSymbol}
                  alt="star-symbol"
                  style={{
                    position: 'absolute',
                    left: '0px',
                    top: '15px',
                    zIndex: 3,
                    width: '31px',
                    height: '31px',
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    width: '16px',
                    height: '16px',
                    backgroundColor: '#ffffff',
                    left: '5px',
                    top: '20px',
                    zIndex: 2,
                  }}
                />

                <Box
                  sx={{
                    display: 'inline-block',
                    width: '154px',
                    height: '154px',
                    borderRadius: '50%',
                    background:
                      'linear-gradient(180deg, #4DF4FF 0%, rgba(38, 83, 242, 0.69) 43.75%, rgba(61, 19, 182, 0.63) 80.21%, #6736FA 100%)',
                    padding: '4px',
                  }}
                >
                  <Avatar
                    src={getAdvisorImagePath(advisor)}
                    sx={{
                      width: '146px',
                      height: '146px',
                      marginBottom: '10px',
                      zIndex: 1,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      eventBus.dispatch(
                        eventBusValues.triggerAdvisorDrawerAction,
                        advisor
                      );
                    }}
                  />
                </Box>
              </div>
            ) : (
              <div style={{ display: 'flex', position: 'relative' }}>
                <div
                  onClick={() => {
                    eventBus.dispatch(
                      eventBusValues.triggerUpgradeFeaturedAdvisorAction
                    );
                  }}
                >
                  {type === 'advisor' && !isFreemium && (
                    <>
                      <img
                        src={StarSymbolGray}
                        alt="star-symbol"
                        style={{
                          position: 'absolute',
                          left: '0px',
                          top: '15px',
                          zIndex: 3,
                          cursor: 'pointer',
                        }}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          width: '16px',
                          height: '16px',
                          backgroundColor: '#ffffff',
                          left: '5px',
                          top: '20px',
                          zIndex: 2,
                        }}
                      />
                    </>
                  )}
                </div>
                <Avatar
                  src={getAdvisorImagePath(advisor)}
                  sx={{
                    width: '146px',
                    height: '146px',
                    marginBottom: '10px',
                    zIndex: 1,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    eventBus.dispatch(
                      eventBusValues.triggerAdvisorDrawerAction,
                      advisor
                    );
                  }}
                />
              </div>
            )}
          </div>
          <Typography variant="h4" sx={classes.displayName}>
            {advisor.displayName}
          </Typography>
          {advisor.title && (
            <Typography variant="caption" sx={classes.title}>
              {advisor.title.length > 18
                ? advisor.title.substring(0, 18) + '...'
                : advisor.title}
            </Typography>
          )}
          <Typography variant="caption" sx={classes.headline}>
            {advisor.headline}
          </Typography>
          <ActionButtons />
        </Box>
        <div
          style={{
            width: '100%',
            paddingBottom: '50px',
          }}
        >
          {/* Non Freemium & AC+ Companies  */}
          <Box id="advisoryBoardsSection" sx={{ marginBottom: '18px' }}>
            <Typography
              sx={{
                color: '#646D7A',
                fontWeight: 500,
                fontSize: '13px',
                lineHeight: '24px',
                letterSpacing: '.75px',
                margin: '36px 0px 18px 0px',
              }}
            >
              Advisory Boards
            </Typography>
            {nonFreemiumAcPlusBoards.memberBoards.map((bm, index) => {
              return (
                <Box key={index} sx={{ marginBottom: '18px' }}>
                  <CardBoard
                    onClick={() => handleBoardroomClick(bm)}
                    companyLogo={bm.companyLogo}
                    companyName={bm.companyName}
                    slug={bm.slug}
                    status="member"
                    key={bm.id}
                  />
                </Box>
              );
            })}
            {nonFreemiumAcPlusBoards.invitedBoards.map((bm, index) => {
              return (
                <Box key={index} sx={{ marginBottom: '18px' }}>
                  <CardBoard
                    onClick={() => seeOpportunity(bm)}
                    companyLogo={bm.companyLogo}
                    companyName={bm.companyName}
                    slug={bm.slug}
                    status="invitation"
                  />
                </Box>
              );
            })}
            {nonFreemiumAcPlusBoards.appliedBoards.map((bm, index) => {
              return (
                <Box key={index} sx={{ marginBottom: '18px' }}>
                  <CardBoard
                    onClick={() => seeOpportunity(bm)}
                    companyLogo={bm.companyLogo}
                    companyName={bm.companyName}
                    slug={bm.slug}
                    status="pending"
                  />
                </Box>
              );
            })}
            <Box
              id="joinBoardsCta"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '20px',
                cursor: 'pointer',
                color: '#3171F6',
              }}
              onClick={() => history.push('/board-directory')}
            >
              <AddCircle
                sx={{
                  width: '18px',
                  height: '18px',
                  paddingRight: '4px',
                }}
              />
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                Browse advisory boards
              </Typography>
            </Box>
          </Box>
          {/* Freemium & AC+ Companies  */}
          <Box id="advisoryCloudsSection" sx={{ marginBottom: '36px' }}>
            {(freemiumAcPlusBoards.memberBoards.length > 0 ||
              freemiumAcPlusBoards.invitedBoards.length > 0 ||
              freemiumAcPlusBoards.appliedBoards.length > 0) && (
              <Typography
                sx={{
                  color: '#646D7A',
                  fontWeight: 500,
                  fontSize: '13px',
                  lineHeight: '24px',
                  letterSpacing: '.75px',
                  margin: '36px 0px 18px 0px',
                }}
              >
                AdvisoryClouds
              </Typography>
            )}

            {freemiumAcPlusBoards.memberBoards.map((bm, index) => {
              return (
                <Box key={index} sx={{ marginBottom: '18px' }}>
                  <CardBoard
                    onClick={() => handleBoardroomClick(bm)}
                    companyLogo={bm.companyLogo}
                    companyName={bm.companyName}
                    slug={bm.slug}
                    status="member"
                  />
                </Box>
              );
            })}
            {freemiumAcPlusBoards.invitedBoards.map((bm, index) => {
              return (
                <Box key={index} sx={{ marginBottom: '18px' }}>
                  <CardBoard
                    onClick={() => seeOpportunity(bm)}
                    companyLogo={bm.companyLogo}
                    companyName={bm.companyName}
                    slug={bm.slug}
                    status="invitation"
                  />
                </Box>
              );
            })}
            {freemiumAcPlusBoards.appliedBoards.map((bm, index) => {
              return (
                <Box key={index} sx={{ marginBottom: '18px' }}>
                  <CardBoard
                    onClick={() => seeOpportunity(bm)}
                    companyLogo={bm.companyLogo}
                    companyName={bm.companyName}
                    slug={bm.slug}
                    status="pending"
                  />
                </Box>
              );
            })}
          </Box>

          {(!advisor.boards.memberBoards || !advisor.boards.memberBoards[0]) &&
          (!advisor.boards.invitedBoards || !advisor.boards.invitedBoards[0]) &&
          (!advisor.boards.appliedBoards ||
            !advisor.boards.appliedBoards[0]) ? (
            <NoBoardCard
              onClick={() => {
                history.push('/board-directory');
              }}
            />
          ) : (
            <BuildBoardCard
              onClick={() =>
                window.location.replace(
                  `https://advisorycloud.com/add-a-board-to-your-membership?${getAdvisorQueryParams(
                    { accountDetails, isFreeTrial, isFreemium }
                  )}`,
                  '_blank'
                )
              }
            />
          )}
        </div>
      </Paper>
    </div>
  );
}

export default AdvisorDashboardLeftMenu;
