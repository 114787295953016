import React from 'react';
import AdvisorUpperBar from '../components/Advisor/UpperBar';
import AdvisorRightMenuSplit from '../components/Advisor/RightMenu/indexSplit';
import CompanyRightMenu from '../components/Company/RightMenu/index';
import CompanyMenu from '../components/Company/Menu';
import CompanyTopMenuMobile from '../components/Company/TopMenu/mobile';
import CompanyUpperBar from '../components/Company/UpperBar';
import { useAccount } from '../contexts/Account';
import LeftMenu from '../components/Advisor/CardPublicSearch';
import ImpersonateUpperBar from '../components/ImpersonationUpperBar';
import UpgradeBanner from '../components/UpgradeBanner';
import ExFreemiumBanner from '../components/ExFreemiumBanner';
import { CompanyExtraFeedProvider } from '../contexts/CompanyFeed';
import { AccountType } from '../services/utils/types';
import eventBus, { eventBusValues } from '../eventBus';

const useStyles = ({ isFreemium }) => ({
  companyContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
  },
  impersonationUpperBarContainer: {
    position: 'fixed',
  },
  companyContainerImpersonated: {
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
    justifyContent: 'center',
  },
  advisorContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    marginTop: isFreemium ? '50px' : '0px',
  },
  advisorContainerImpersonated: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    width: '80%',
    justifyContent: 'center',
    marginLeft: '110px',
  },
  advisorContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
  },
  viewContainer: {
    display: 'flex',
    flex: 'auto',
    overflowX: 'hidden',
  },
  advisorViewContainer: {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    overflowX: 'hidden',
  },
  advisorMainViewContainer: {
    display: 'flex',
  },
  childrenContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
});

function BaseLayout({ children }) {
  const {
    type,
    accountDetails,
    isImpersonated,
    isFreeTrial,
    currentPlan,
    isFreemium,
  } = useAccount();
  const classes = useStyles({ isFreemium });
  function handleMenuClick() {
    eventBus.dispatch(eventBusValues.mobileMenuTrigger);
  }

  function getLeftMenu() {
    return <LeftMenu />;
  }

  if (type === AccountType.ADVISOR) {
    const { salesforceValues } = currentPlan;
    const showBanner =
      !isFreeTrial &&
      salesforceValues.billingCycle === 'Monthly' &&
      ['Essentials', 'Professional', 'Executive'].includes(
        salesforceValues.tier
      );
    return (
      <>
        {isImpersonated && <ImpersonateUpperBar />}
        {showBanner && <UpgradeBanner />}
        <div
          style={
            isImpersonated
              ? classes.advisorContainerImpersonated
              : classes.advisorContainer
          }
        >
          <div style={{ paddingTop: 0 }}>
            <AdvisorUpperBar onAvatarMenuIconCLick={handleMenuClick} />
            <div style={classes.advisorMainViewContainer}>
              {getLeftMenu()}
              <div style={classes.advisorViewContainer}>{children}</div>
            </div>
          </div>
          <AdvisorRightMenuSplit
            onDismiss={() => {
              eventBus.dispatch(eventBusValues.mobileMenuTrigger);
            }}
            accountDetails={accountDetails}
          />
        </div>
      </>
    );
  }

  if (type === 'company') {
    const showExFremiumBanner =
      accountDetails.freemiumRegistration &&
      accountDetails.opportunityStage === 'discovery' &&
      !isFreemium;
    return (
      <>
        <CompanyRightMenu
          onDismiss={() => {
            eventBus.dispatch(eventBusValues.mobileCompanyMenuTrigger);
          }}
          accountDetails={accountDetails}
        />
        <CompanyExtraFeedProvider>
          <div style={classes.impersonationUpperBarContainer}>
            {isImpersonated && <ImpersonateUpperBar />}
          </div>
          <div
            style={
              isImpersonated
                ? classes.companyContainerImpersonated
                : classes.companyContainer
            }
          >
            {showExFremiumBanner && <ExFreemiumBanner show={true} />}
            <CompanyMenu />

            <div style={classes.childrenContainer}>
              <CompanyTopMenuMobile onMenuIconClick={handleMenuClick} />
              <CompanyUpperBar />
              <div style={classes.viewContainer}>{children}</div>
            </div>
          </div>
        </CompanyExtraFeedProvider>
      </>
    );
  }

  return <>{children}</>;
}

export default BaseLayout;
