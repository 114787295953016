import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Typography, useMediaQuery, Box } from '@mui/material';
import { useAccount } from './../../../../contexts/Account';
import ProfileCard from './ProfileCard';
import Experience from './../../../Experience';
import LoginModal from './../../../LoginModal';
import Tag from './../../../Tag';
import BoardPosition from './../../Profile/components/boardPosition';
import Skills from './EmptyState/Skills';
import Biography from './EmptyState/BIography';
import EmptyExperience from './EmptyState/Experience';
import Industries from './EmptyState/Industries';
import Interests from './EmptyState/Interests';
import Boards from './EmptyState/Boards';

const useStyles = ({ isSM, isSeeMoreSelected }) => ({
  card: {
    width: '100%',
    borderRadius: '25px 25px 0px 0px',
    marginTop: isSM ? '15px' : '90px',
    marginLeft: isSM ? '10px' : '',
    marginRight: isSM ? '10px' : '',
  },
  advisorPurpleDot: {
    fontWeight: 'bold',
    width: '10px',
    height: '10px',
    marginRight: '10px',
    borderColor: '#0F78FD',
    borderRadius: '10px',
    background: '#6736FA',
  },
  companyInformationSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '20px',
    width: '100%',
    paddingBottom: '40px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mainButton: {
    fontWeight: 700,
    width: isSM ? '90%' : 'auto',
    minWidth: '150px',
    height: isSM ? '50px' : '44px',
    textTransform: 'none',
    fontSize: '16px',
    marginLeft: '10px',
    padding: isSM ? '0px' : '0px 20px',
    borderColor: '#6736FA',
    borderRadius: '25px',
    background: '#6736FA',
    color: '#FFF',
    lineHeight: 'initial',
    '&:hover': {
      background: '#6736FA',
    },
    whiteSpace: 'nowrap',
  },
  mainInformationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: isSM ? '0 20px 0 20px' : '0 60px 0 60px',
  },
  leftContainer: {
    width: '65%',
    padding: isSM ? '0 0 0 20' : '0 0 0 60px',
  },
  rightContainer: {
    width: '25%',
    padding: isSM ? '0 20px 0 0' : '0 60px 0 0',
  },
  tagsTitle: {
    fontWeight: 600,
    marginTop: '30px',
    fontSize: '20px',
    marginBottom: '15px',
  },
  advisorButtonText: {
    fontSize: '15px',
    fontWeight: 400,
  },
  experienceTitle: {
    fontWeight: 600,
    fontSize: '20px',
    marginTop: '30px',
    marginBottom: '-15px',
  },
  generalMargin: {
    marginRight: '8px',
    marginBottom: '5px',
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%',
    marginTop: isSM ? '20px' : '-15px',
    marginLeft: isSM ? '20px' : '5px',
    maxWidth: '100%',
  },
  industryTagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'colun',
  },
  boardsContainer: {
    '& > *': {},
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  boards: {
    maxHeight: isSeeMoreSelected ? '143px' : '',
    width: '127%',
    overflowY: isSeeMoreSelected ? 'scroll' : 'hidden',
  },
  advisorButtonContainer: {
    height: '90px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '30px',
    backgroundColor: '#F5F7FA',
    marginBottom: '20px',
  },
  seeMoreTitle: {
    color: 'primary.main',
    cursor: 'pointer',
  },
  biographyText: {
    fontWeight: 400,
    whiteSpace: 'pre-wrap',
    fontSize: '100%',
    fontSize: '16px',
    color: '#232B35',
  },
  bodyContainer: {
    display: isSM ? '' : 'flex',
    justifyContent: 'space-between',
    margin: isSM ? '0 20px' : '',
    maxWidth: isSM ? '90%' : '',
  },
  rightSideTitle: {
    marginBottom: '20px',
    fontWeight: 600,
    marginTop: '30px',
    fontSize: '20px',
  },
});

function AdvisorPublicProfile({ advisor, skills, industries }) {
  const { tags } = useAccount();
  const { t } = useTranslation();
  const [loginModal, setLoginModal] = useState(false);
  const [isSeeMoreSelected, setIsSeeMoreSelected] = useState(false);
  const [totalBoardsCount, setTotalBoardsCount] = useState(3);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles({ isSM, isSeeMoreSelected });
  const favoriteTagsSkills = tags.skills.filter((skill) =>
    (advisor.favoriteSkillsIds || []).includes(skill.id)
  );

  function boardsArray(boards) {
    let filteredArray = boards.slice(0, totalBoardsCount);
    return filteredArray.map((company) => (
      <BoardPosition
        publicProfile
        key={company.id}
        companyId={company.id}
        advisorId={advisor.id}
      />
    ));
  }

  function seeMoreActions() {
    setIsSeeMoreSelected(true);
    setTotalBoardsCount(totalBoardsCount + 5);
  }

  function addFavoriteSkills(skills) {
    const finalDisplaySkills = [];
    favoriteTagsSkills.forEach((favSkill) => {
      if (finalDisplaySkills.length < 5) {
        finalDisplaySkills.push(favSkill);
      }
    });
    if (finalDisplaySkills.length < 5) {
      skills.forEach((singleSkill) => {
        if (
          finalDisplaySkills.length < 5 &&
          !advisor.favoriteSkillsIds.includes(singleSkill.id)
        ) {
          finalDisplaySkills.push(singleSkill);
        }
      });
    }
    return finalDisplaySkills;
  }

  let advisorSkills = advisor.skillTagIds ? advisor.skillTagIds : [];
  const advisorIndustries = advisor.industryTagIds
    ? advisor.industryTagIds
    : [];
  skills = skills
    ? skills.filter((skill) => advisorSkills.includes(skill.id))
    : [];

  skills =
    advisor.favoriteSkillsIds && advisor.favoriteSkillsIds.length > 0
      ? addFavoriteSkills(skills)
      : skills;
  if (skills.length > 5) {
    skills.length = 5;
  }
  industries = industries
    ? industries.filter((industry) => advisorIndustries.includes(industry.id))
    : [];

  function sortExperience(a, b) {
    if (a.endDate === 'Present' && b.endDate !== 'Present') return -1;
    if (a.endDate !== 'Present' && b.endDate === 'Present') return 1;
    if (Number(a.startDate) < Number(b.startDate)) return 1;
    if (Number(a.startDate) > Number(b.startDate)) return -1;
    return 0;
  }

  const experienceSorted = advisor.experiences
    ? advisor.experiences.sort(sortExperience)
    : [];

  return (
    <>
      {!isSM ? (
        <Paper sx={classes.card}>
          <div style={classes.companyInformationSectionContainer}>
            <div style={classes.mainInformationContainer}>
              <ProfileCard advisor={advisor} setLoginModal={setLoginModal} />
              <div style={classes.tagsContainer}>
                {skills.length > 0 ? (
                  skills.map((skill, index) => (
                    <Box sx={classes.generalMargin} key={index}>
                      <Tag label={skill.label} publicProfile />
                    </Box>
                  ))
                ) : (
                  <Skills />
                )}
              </div>
            </div>
            <div style={classes.bodyContainer}>
              <div style={classes.leftContainer}>
                <Typography variant="body1" sx={classes.tagsTitle}>
                  {t('PUBLIC-ADVISOR-BIOGRAPHY-LABEL')}
                </Typography>
                {advisor.biography ? (
                  <Typography variant="body1" sx={classes.biographyText}>
                    {advisor.biography}
                  </Typography>
                ) : (
                  <Biography />
                )}

                <Typography variant="body1" sx={classes.experienceTitle}>
                  {t('PUBLIC-ADVISOR-EXPERIENCE-LABEL')}
                </Typography>
                {experienceSorted && experienceSorted.length !== 0 ? (
                  experienceSorted.map((experience, index) => (
                    <Experience
                      content={experience}
                      publicProfile
                      key={index}
                    />
                  ))
                ) : (
                  <EmptyExperience />
                )}
              </div>
              <div style={classes.rightContainer}>
                <div
                  className="boardPositionBlock"
                  style={classes.boardsContainer}
                >
                  <Typography variant="body1" sx={classes.rightSideTitle}>
                    {t('PUBLIC-ADVISOR-BOARD-POSITIONS-LABEL')}
                  </Typography>
                  <div style={classes.boards}>
                    {advisor.boards.memberBoards &&
                    advisor.boards.memberBoards.length ? (
                      <>{boardsArray(advisor.boards.memberBoards)}</>
                    ) : (
                      <Boards />
                    )}
                  </div>
                </div>
                {advisor.boards.memberBoards.length > totalBoardsCount && (
                  <Typography
                    onClick={() => seeMoreActions()}
                    sx={classes.seeMoreTitle}
                  >
                    {t('PUBLIC-SEE-MORE-BOARDS-LABEL')}
                  </Typography>
                )}
                <div style={classes.boardsContainer}>
                  <Typography variant="body1" sx={classes.rightSideTitle}>
                    {t('PUBLIC-ADVISOR-INTERESTS-LABEL')}
                  </Typography>

                  {advisor.interests && advisor.interests.length ? (
                    <div style={classes.industryTagsContainer}>
                      {advisor.interests.map((interest, index) => (
                        <Box sx={classes.generalMargin} key={index}>
                          <Tag label={interest} publicProfile />
                        </Box>
                      ))}
                    </div>
                  ) : (
                    <Interests />
                  )}
                </div>
                <div style={classes.boardsContainer}>
                  <Typography variant="body1" sx={classes.rightSideTitle}>
                    {t('PUBLIC-ADVISOR-INDUSTRY-LABEL')}
                  </Typography>

                  {industries && industries.length > 0 ? (
                    <div style={classes.industryTagsContainer}>
                      {industries.map((industry, index) => (
                        <Box sx={classes.generalMargin} key={index}>
                          <Tag label={industry.label} publicProfile />
                        </Box>
                      ))}
                    </div>
                  ) : (
                    <Industries />
                  )}
                </div>
              </div>
            </div>
            <>
              <LoginModal
                loginModal={loginModal}
                setLoginModal={setLoginModal}
              />
            </>
          </div>
        </Paper>
      ) : (
        <Paper sx={classes.card} elevation={3}>
          <div style={classes.companyInformationSectionContainer}>
            <div style={classes.mainInformationContainer}>
              <ProfileCard advisor={advisor} setLoginModal={setLoginModal} />
            </div>
            <div style={classes.tagsContainer}>
              {skills.map((skill, index) => (
                <Box sx={classes.generalMargin} key={index}>
                  <Tag label={skill.label} publicProfile />
                </Box>
              ))}
            </div>
            <div style={classes.bodyContainer}>
              <Typography variant="body1" sx={classes.tagsTitle}>
                {t('PUBLIC-ADVISOR-BIOGRAPHY-LABEL')}
              </Typography>

              {advisor.biography ? (
                <Typography variant="body1" sx={classes.biographyText}>
                  {advisor.biography}
                </Typography>
              ) : (
                <Biography />
              )}

              <div style={classes.boardsContainer}>
                <Typography variant="body1" sx={classes.rightSideTitle}>
                  {t('PUBLIC-ADVISOR-BOARD-POSITIONS-LABEL')}
                </Typography>
                <div style={classes.boards}>
                  {advisor.boards.memberBoards &&
                  advisor.boards.memberBoards.length ? (
                    <>{boardsArray(advisor.boards.memberBoards)}</>
                  ) : (
                    <Boards />
                  )}
                </div>
              </div>
              {advisor.boards.memberBoards.length > totalBoardsCount && (
                <Typography
                  onClick={() => setTotalBoardsCount(totalBoardsCount + 5)}
                  sx={classes.seeMoreTitle}
                >
                  {t('PUBLIC-SEE-MORE-BOARDS-LABEL')}
                </Typography>
              )}

              <div style={classes.boardsContainer}>
                <Typography variant="body1" sx={classes.rightSideTitle}>
                  {t('PUBLIC-ADVISOR-INTERESTS-LABEL')}
                </Typography>

                {advisor.interests && advisor.interests.length > 0 ? (
                  <div style={classes.industryTagsContainer}>
                    {advisor.interests.map((interest, index) => (
                      <Box sx={classes.generalMargin} key={index}>
                        <Tag label={interest} publicProfile />
                      </Box>
                    ))}
                  </div>
                ) : (
                  <Interests />
                )}
              </div>

              <Typography variant="body1" sx={classes.experienceTitle}>
                {t('PUBLIC-ADVISOR-EXPERIENCE-LABEL')}
              </Typography>

              {experienceSorted && experienceSorted.length !== 0 ? (
                experienceSorted.map((experience, index) => (
                  <Experience content={experience} publicProfile key={index} />
                ))
              ) : (
                <EmptyExperience />
              )}
            </div>
            <>
              <LoginModal
                loginModal={loginModal}
                setLoginModal={setLoginModal}
              />
            </>
          </div>
        </Paper>
      )}
    </>
  );
}

export default AdvisorPublicProfile;
