import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Typography } from '@mui/material';
import eventBus, { eventBusValues } from './../../../eventBus';
import CustomIcon from './../../../icons/customIcon';

const useStyles = ({ isWrapped }) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: 'none',
    cursor: 'pointer',
    marginRight: isWrapped ? '8px' : '18px',
    backgroundColor: 'transparent',
  },
  meetingTitleText: {
    fontWeight: 'bold',
    fontSize: '12px',
  },
  meetingDateText: {
    fontWeight: '10',
    fontSize: '12px',
  },
  textContainer: {
    maxHeight: '100%',
    marginLeft: '8px',
    textAlign: 'left',
  },
});

function UpcomingMeetingBanner({ company }) {
  const { t } = useTranslation();
  const isWrapped = useMediaQuery((theme) => theme.breakpoints.down([1500]));
  const isWrapped2 = useMediaQuery((theme) => theme.breakpoints.down([1530]));

  const classes = useStyles({ isWrapped });
  const displayNameSmall =
    company && company.displayName
      ? company.displayName.length > 20
        ? truncateString(company.displayName, 20)
        : company.displayName
      : '';
  const displayName =
    company && company.displayName
      ? company.displayName.length > 40
        ? truncateString(company.displayName, 40)
        : company.displayName
      : '';

  function truncateString(str, max) {
    if (str.length <= max) {
      return str;
    } else {
      let subStr = str.substr(0, max);
      let lastIndex = subStr.lastIndexOf(' ');

      if (lastIndex !== -1) {
        subStr = subStr.substr(0, lastIndex);
      }

      return subStr;
    }
  }

  function handleButtonClick() {
    eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
      company,
      hidePostSection: false,
    });
  }

  function getDateFormat(date) {
    const myDate = new Date(date);
    return `${myDate.getMonth() + 1}/${myDate.getDate()}`;
  }
  return (
    <>
      <button
        className="upcomingBoardMeeting"
        style={classes.container}
        type="button"
        onClick={() => handleButtonClick()}
      >
        <CustomIcon
          iconname="calendar-meeting"
          style={{
            color: '#7F00FF',
            fontSize: '28px',
            marginBottom: '5px',
            marginRight: '2px',
            width: '30px',
            height: 'auto',
          }}
        />
        <div style={classes.textContainer}>
          <Typography variant="body1" sx={classes.meetingTitleText}>
            {t('UPCOMING-BOARD-MEETING-TITLE')}
          </Typography>
          <Typography variant="body1" sx={classes.meetingDateText}>
            {t('UPCOMING-BOARD-MEETING-TEXT', {
              companyName: !isWrapped2 ? displayName : displayNameSmall,
              date: getDateFormat(company.upcomingBoardMeeting),
            })}
          </Typography>
        </div>
      </button>
    </>
  );
}

export default UpcomingMeetingBanner;
