import React from 'react';
import { Box, useMediaQuery, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EmptyContainer from './../../Step6/EmptyContainer';

const useStyles = () => ({
  container: {
    width: '100%',
    paddingTop: '15px',
  },
  emptyTitle: {
    fontSize: '16px',
    fontWeight: 400,
    marginBottom: '10px',
  },
  tagsContainer: {
    '& > *': {},
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
  },
  margin: {
    marginRight: '8px',
    fontFamily: 'Poppins',
  },
});

function Headline() {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { t } = useTranslation();

  return (
    <div style={classes.container}>
      <Typography sx={classes.emptyTitle}>
        {t('ADVISOR-ONBOARDING-EMPTY-EXPERIENCE')}
      </Typography>
      {!isSM ? (
        <div style={classes.tagsContainer}>
          {[451, 107, '100%', '100%', '100%', '100%'].map((skill, index) => (
            <Box sx={classes.margin} key={index}>
              <EmptyContainer width={skill} height={24} />
            </Box>
          ))}
        </div>
      ) : (
        <div style={classes.tagsContainer}>
          {[251, 27, '100%', '100%', '100%', '100%'].map((skill, index) => (
            <Box sx={classes.margin} key={index}>
              <EmptyContainer width={skill} height={24} />
            </Box>
          ))}
        </div>
      )}
    </div>
  );
}

export default Headline;
