import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CustomIcon from './../../../../icons/customIcon';

const useStyles = ({ isSM }) => ({
  mainActions: {
    color: '#ffffff',
    backgroundColor: '#3171F6',
    '&:hover': {
      backgroundColor: '#3171F6',
    },
    borderRadius: '40px',
    width: isSM ? '100%' : '148px',
    height: '48.38px',
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '15px',
    fontStyle: 'normal',
    letterSpacing: -0.43,
    padding: '6px 15px 6px 15px',
  },
  actionsMenu: {
    width: isSM ? '100%' : undefined,
    borderRadius: '12px',
    border: '1px solid var(--Gray-5, #F5F7FA)',
    boxShadow: '0px 1px 10px 0px rgba(230, 237, 254, 0.25)',
  },
});

export default function EmptyActionButton() {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles({ isSM });

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClosePopper = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false && anchorRef.current) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box
      sx={{
        display: 'flex',
        position: isSM ? undefined : 'relative',
        left: '40px',
      }}
    >
      <Button
        onClick={handleToggle}
        sx={classes.mainActions}
        id="actionsButton"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        ref={anchorRef}
      >
        Connect
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper sx={classes.actionsMenu}>
              <ClickAwayListener onClickAway={handleClosePopper}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={() => {}} id="bookVideoCallMenuItem">
                    <CustomIcon
                      iconname="book-call"
                      sx={{
                        fontSize: '12px',
                        marginBottom: '5px',
                      }}
                    />
                    <Typography style={{ marginLeft: '10px' }}>
                      Book 1:1 video call
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => {}} id="hireAdvisorMenuItem">
                    <CustomIcon
                      iconname="hire-advisor"
                      sx={{
                        fontSize: '12px',
                        marginBottom: '5px',
                      }}
                    />
                    <Typography style={{ marginLeft: '10px' }}>
                      Hire advisor
                    </Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}
