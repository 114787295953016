import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { uploadToS3 } from './../../../../services/Amplify/amplify-files-upload';
import FormikForm from './Step2FormikForm';
import { useAccount } from './../../../../contexts/Account';
import { Enviroments } from './../../../../services/utils/types';
import { updateAdvisorOnboardingStep2 } from './../../../../services/advisor_services/index';

function Step2Form(props) {
  const { formRef, onValid, isUpdate, updateSubmit } = props;
  const history = useHistory();
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [avatarImage, setAvatarImage] = useState();
  const [originalImage, setOriginalImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [filePondStatus, setFilePondStatus] = useState(true);
  const { accountDetails, tags, currentPlan } = useAccount();
  const [yourInterests, setYourInterests] = useState(
    accountDetails.interests || []
  );

  const Step2Schema = Yup.object().shape({
    yourFirstName: Yup.string().max(50, '50-character limit').required(),
    yourLastName: Yup.string().max(50, '50-character limit').required(),
    primarySkills: Yup.array(),
  });

  async function sendFormData(data) {
    const primarySkillsIds = data.primarySkills.map((skill) => skill.id);
    try {
      await updateAdvisorOnboardingStep2({
        ADVISOR_ID: accountDetails.id,
        BIOGRAPHY: data.yourBiography || '',
        GIVENNAME: data.yourFirstName,
        SURNAME: data.yourLastName,
        HEADLINE: data.yourHeadline || '',
        AVATARIMAGEID: data.uploadedAvatarId || '',
        ORIGINALIMAGEID: data.uploadedOriginalId || '',
        MEETINGRATE: data.yourHourlyRate || 500,
        INTERESTS: yourInterests || [],
        PRIMARY_SKILL_TAG_IDS: primarySkillsIds || [],
        ONBOARDING_STATUS: isXS ? 'step5' : 'step3',
      });
      accountDetails.givenName = data.yourFirstName;
      accountDetails.biography = data.yourBiography || '';
      accountDetails.surName = data.yourLastName;
      accountDetails.meetingRate = data.yourHourlyRate;
      accountDetails.headline = data.yourHeadline || '';
      accountDetails.interests = yourInterests || [];
      accountDetails.onboardingStatus = isXS ? 'step5' : 'step3';
      accountDetails.favoriteSkillsIds = primarySkillsIds || [];
    } catch (err) {
      throw new Error();
    }
  }

  async function sendProfileImg() {
    try {
      let uploadedAvatarId = null;
      let uploadedOriginalId = null;
      let bucketImages = process.env.REACT_APP_S3_BUCKET_IMAGES;
      if (process.env.NODE_ENV === Enviroments.PRODUCTION) {
        bucketImages = process.env.REACT_APP_S3_BUCKET_IMAGES.replace(
          'us-west-2',
          'us-east-1'
        );
      }
      if (avatarImage) {
        const imageId = `${uuidv4()}.${avatarImage.type.split('/')[1]}`;
        await uploadToS3({
          BLOB_LOAD: avatarImage,
          CONTENT_TYPE: avatarImage.type,
          FILE_ID: imageId,
          S3_BUCKET: bucketImages,
        });
        uploadedAvatarId = `public/${imageId}`;
      }
      if (originalImage) {
        const imageId = `${uuidv4()}.${originalImage.type.split('/')[1]}`;
        await uploadToS3({
          BLOB_LOAD: originalImage,
          CONTENT_TYPE: originalImage.type,
          FILE_ID: imageId,
          S3_BUCKET: process.env.REACT_APP_S3_BUCKET_ORIGINAL_IMAGES,
        });
        uploadedOriginalId = `public/${imageId}`;
      }
      return {
        uploadedAvatarId,
        uploadedOriginalId,
      };
    } catch (err) {
      console.log(err);
      throw new Error();
    }
  }

  async function submitStep2Form(data) {
    let values = data;

    if (!values && isUpdate) {
      values = formRef.current.values;
    }

    setIsLoading(true);
    const imageUpload = await sendProfileImg(values);
    if (imageUpload.uploadedOriginalId && imageUpload.uploadedAvatarId) {
      values.uploadedOriginalId = imageUpload.uploadedOriginalId;
      values.uploadedAvatarId = imageUpload.uploadedAvatarId;
    }
    try {
      await sendFormData(values);
      if (isXS) {
        window.location.replace(
          `${window.location.origin}/advisor/onboarding/step5`
        );
      } else {
        window.location.replace(
          `${window.location.origin}/advisor/onboarding/step3`
        );
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setIsSnackbarVisible(true);
    }
  }
  const formInitialValues = {
    yourFirstName: accountDetails.givenName,
    yourLastName: accountDetails.surName,
    yourBiography: accountDetails.biography,
    yourHeadline: accountDetails.headline,
    yourHourlyRate: accountDetails.meetingRate,
    yourInterests: accountDetails.interests || [],
    primarySkills:
      tags && tags.skills
        ? tags.skills.filter(
            (skill) =>
              accountDetails.favoriteSkillsIds &&
              accountDetails.favoriteSkillsIds.includes(skill.id)
          )
        : null,
  };

  useEffect(() => {
    if (
      currentPlan &&
      currentPlan.salesforceValues &&
      currentPlan.salesforceValues.purchasingChannel === 'Company Invitee' &&
      accountDetails &&
      accountDetails.boards &&
      accountDetails.boards.memberBoards &&
      accountDetails.boards.memberBoards[0]
    ) {
      history.push(`/${accountDetails.boards.memberBoards[0].slug}/boardroom`);
    }
  }, []);

  useEffect(() => {
    if (isUpdate && updateSubmit) {
      updateSubmit(submitStep2Form);
    }
  }, [submitStep2Form]);

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={Step2Schema}
      validateOnMount
      validateOnChange
      validateOnBlur
      innerRef={formRef}
    >
      {(formik) => {
        return (
          <FormikForm
            {...formik}
            {...props}
            t={t}
            setAvatarImage={setAvatarImage}
            setOriginalImage={setOriginalImage}
            setYourInterests={setYourInterests}
            isSnackbarVisible={isSnackbarVisible}
            setIsSnackbarVisible={setIsSnackbarVisible}
            isXS={isXS}
            setFilePondStatus={setFilePondStatus}
            submitStep2Form={submitStep2Form}
            onValid={onValid}
            isLoading={isLoading}
            filePondStatus={filePondStatus}
          />
        );
      }}
    </Formik>
  );
}

export default Step2Form;
