import React from "react";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useMediaQuery } from "@mui/material";

const useStyles = ({ isSM }) => ({
  formControl: {
    width: isSM ? '80px' : '100px',
    height: '30px',
    margin: 0,
    backgroundColor: 'theme.palette.primary.main',
    borderRadius: "8%",
  },
  label: {
    color: "white",
    textAlign: "center",
    width: "100%",
    position: "absolute",
    fontWeight: "bold",
    top: "15%",
    paddingRight: "11px",
  },
  icon: {
    color: "white",
    width: isSM ? '20px' : '25px',
    height: isSM ? '20px' : '25px',
    margin: isSM ? "2% 8% auto auto" : "0% 8% auto auto",
    paddingBottom: isSM ? 0 : "1px",
    bottom: "20%",
  },
  center: {
    margin: "auto",
  },
});

function ActionButton(props) {
  const { itemList, label } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles({isSM});
  const handleChange = (event) => {
    event.target.value.callback();
  };

  return (
    <FormControl
      className={classes.formControl}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Typography
        className={classes.label}
        variant={isSM ? "caption" : "body2"}
      >
        {label}
      </Typography>
      <Select
        className={classes.select}
        disableUnderline
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}
        id="sort-select"
        value={label}
        onChange={handleChange}
      >
        {itemList.map((item) => {
          return (
            <MenuItem key={item.title} value={item}>
              {item.title}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default ActionButton;
