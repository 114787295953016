import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Typography } from '@mui/material';
import EmptyContainer from './../../Step6/EmptyContainer';

const useStyles = () => ({
  container: {
    width: '100%',
    paddingTop: '15px',
  },
  emptyTitle: {
    fontSize: '16px',
    fontWeight: 400,
    marginBottom: '10px',
  },
  tagsContainer: {
    '& > *': {
      marginRight: '8px',
    },
    display: 'flex',
    fontFamily: 'Poppins',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%',
    maxWidth: '100%',
  },
});

function Headline({ barWith, hideTitle }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { t } = useTranslation();

  return (
    <div style={classes.container}>
      {!hideTitle && (
        <Typography sx={classes.emptyTitle}>
          {t('ADVISOR-ONBOARDING-EMPTY-BIOGRAPHY')}
        </Typography>
      )}
      {!isSM ? (
        <div style={classes.tagsContainer}>
          {[724, 724, 724, 724].map((skill, index) => (
            <EmptyContainer key={index} width={barWith || skill} height={24} />
          ))}
        </div>
      ) : (
        <div style={classes.tagsContainer}>
          {[300, 300, 300, 300].map((skill, index) => (
            <EmptyContainer key={index} width={barWith || skill} height={24} />
          ))}
        </div>
      )}
    </div>
  );
}

export default Headline;
