export const advisorAllowedRoutes = [
  '/dashboard',
  '/account',
  '/advisor/onboarding/terms-of-service',
  '/advisor/onboarding/step2',
  '/advisor/onboarding/step3',
  '/advisor/onboarding/step4',
  '/advisor/onboarding/step5',
  '/advisor/onboarding/step6',
  '/board-directory',
  '/networking',
  '/board-meetings',
  '/messages',
  '/post/:postId?',
  '/:companySlug/post/:postId?',
  '/update/:postId?',
  '/:companySlug/boardroom',
  '/:companySlug/board-meetings',
  '/:companySlug/board-meetings/:postId?',
  '/:companySlug/boardroom/:postId?',
  '/:companySlug/updates',
  '/:companySlug/updates/:postId?',
  '/:companySlug/update',
  '/:companySlug/update/:postId?',
  '/:companySlug/questions',
  '/:companySlug/questions/:postId?',
  '/:companySlug/question',
  '/:companySlug/question/:postId?',
  '/:companySlug/my-answers',
  '/:companySlug/benefits',
  '/advisor/opportunities',
  '/advisor/boardroom/:id',
  '/insights',
];
