import React, { useState } from 'react';
import {
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';

const useStyles = () => ({
  inputLabel: {
    marginBottom: '10px',
    color: 'black',
  },
  inputContainer: {
    width: '100%',
  },
  emptyLabel: {
    marginTop: '24px',
  },
  inputLabelText: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: 1.5,
  },
  currencyInputLabelText: {
    fontWeight: 400,
    textAlign: 'left',
    fontSize: '16px',
    lineHeight: 1.5,
    borderRadius: '10px',
  },
  helperText: {
    color: 'grey.600',
  },
  helperErrorText: {
    color: 'error.main',
  },
  responsivePosition: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

function Input({
  id,
  label,
  className,
  errorTextInRed,
  limitText,
  setFieldValue,
  helperText,
  textFieldDisplayName,
  FormHelperTextProps,
  headerInput,
  value,
  isCurrencyField,
  height,
  style,
  ...rest
}) {
  const [selectedValue, setSelectedValue] = useState(value);
  const [textFieldError, setTextFieldError] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();

  function classSelector() {
    if (FormHelperTextProps) {
      return FormHelperTextProps;
    }
    if (errorTextInRed) {
      return classes.helperErrorText;
    }
    return classes.helperText;
  }

  function handleInputChange(event) {
    if (limitText && event.target.value.length >= limitText) {
      setTextFieldError(true);
      setSnackMessage(`Limit ${limitText} characters`);
    } else {
      setSnackMessage('');
      setTextFieldError(false);
      setFieldValue(id, event.target.value);
      setSelectedValue(event.target.value);
    }
  }

  return (
    <div style={{ ...classes.inputContainer, ...style }}>
      {isSM ? (
        <InputLabel sx={classes.inputLabel} htmlFor={id}>
          <Typography sx={!label ? classes.emptyLabel : classes.inputLabelText}>
            {label}
          </Typography>
          <Typography variant="caption" sx={classSelector()}>
            {helperText}
          </Typography>
        </InputLabel>
      ) : (
        <InputLabel sx={classes.inputLabel} htmlFor={id}>
          <div style={classes.responsivePosition}>
            <Typography
              sx={!label ? classes.emptyLabel : classes.inputLabelText}
            >
              {label}
            </Typography>
            <Typography variant="caption" sx={classSelector()}>
              {helperText}
            </Typography>
          </div>
        </InputLabel>
      )}

      {isCurrencyField ? (
        <TextField
          label="Amount"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          variant="outlined"
          type="number"
          {...rest}
          InputProps={{
            style: classes.currencyInputLabelText,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          value={selectedValue}
          error={textFieldError}
          helperText={snackMessage}
          sx={{
            borderRadius: '10px',
            fieldset: {
              borderColor: '#EFF0F6 !important',
            },
          }}
          onChange={(e) => handleInputChange(e)}
        />
      ) : (
        <TextField
          {...rest}
          onChange={(e) => handleInputChange(e)}
          className={`inputRounded ${headerInput ? 'inputHeight' : ''}`}
          sx={{
            ...classes.inputContainer,
            fieldset: {
              borderColor: '#EFF0F6',
            },
          }}
          id={id}
          value={selectedValue}
          InputLabelProps={{
            style: {
              height: height || '44px',
              top: '-4px',
              fontFamily: 'Poppins',
            },
          }}
          error={textFieldError}
          helperText={snackMessage}
          variant="outlined"
          InputProps={{
            style: {
              ...classes.inputLabelText,
              height: height || '44px',
              fontFamily: 'Poppins',
            },
          }}
        />
      )}
    </div>
  );
}

export default Input;
