import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Form } from 'formik';
import ACButton from './../../../ACButton';
import ACSnackbar from './../../../ACSnackbar';
import { useAccount } from './../../../../contexts/Account';
import Autocomplete from './../Autocomplete';

const useStyles = (isXS) => ({
  continueButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '25px',
    marginBottom: '25px',
  },
  fieldContainer: {
    marginBottom: '15px',
    width: '90%',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: isXS ? 'column' : 'row',
    height: '100%',
  },
});

function Step3FormikForm(props) {
  const {
    setFieldValue,
    handleBlur,
    values,
    isValid,
    t,
    isSnackbarVisible,
    setIsSnackbarVisible,
    submitStep3Form,
    isXS,
    isLoading,
    onValid,
  } = props;
  const classes = useStyles(isXS);
  const { tags } = useAccount();
  const isContinueButtonDisabled = !isValid;

  useEffect(() => {
    if (onValid) {
      onValid(!isContinueButtonDisabled);
    }
  }, [isContinueButtonDisabled]);

  return (
    <>
      <Form>
        <ACSnackbar
          id="onboarding-step1-error-snackbar"
          onClose={() => setIsSnackbarVisible(false)}
          open={isSnackbarVisible}
          severity="error"
          text={t('STEP1-ERROR-SNACKBAR-TEXT')}
        />

        <div style={classes.row}>
          <Autocomplete
            sx={classes.fieldContainer}
            defaultValue={values.primarySkills}
            onBlur={handleBlur}
            setFieldValue={setFieldValue}
            getOptionLabel={(option) => option.label}
            helperText={<Trans i18nKey="ADVISOR-ONBOARDING-STEP-3-MINIMUM" />}
            id="primarySkills"
            input-data-cy="txtCompanyIndustry"
            label={t('ADVISOR-ONBOARDING-STEP-3-ENTER-TOP-SKILLS')}
            subtitle={t('ADVISOR-ONBOARDING-STEP-3-ENTER-TOP-SKILLS-SUBTITLE')}
            limitTags={8}
            options={tags.skills}
            placeholder={t(
              'ADVISOR-ONBOARDING-STEP-3-ENTER-TOP-SKILLS-PLACEHOLDER'
            )}
            ListboxProps={{
              style: { fontFamily: 'Poppins' },
            }}
          />
        </div>
        <div style={classes.row}>
          <Autocomplete
            sx={classes.fieldContainer}
            defaultValue={values.additionalSkills}
            onBlur={handleBlur}
            setFieldValue={setFieldValue}
            getOptionLabel={(option) => option.label}
            id="additionalSkills"
            input-data-cy="txtCompanyIndustry"
            label={t('ADVISOR-ONBOARDING-STEP-3-ENTER-ADDITIONAL-SKILLS')}
            subtitle={t(
              'ADVISOR-ONBOARDING-STEP-3-ENTER-ADDITIONAL-SKILLS-SUBTITLE'
            )}
            options={tags.skills}
            placeholder={t(
              'ADVISOR-ONBOARDING-STEP-3-ENTER-TOP-SKILLS-PLACEHOLDER'
            )}
            ListboxProps={{
              style: { fontFamily: 'Poppins' },
            }}
          />
        </div>
        <div style={classes.row}>
          <Autocomplete
            sx={classes.fieldContainer}
            defaultValue={values.advisorIndustry}
            onBlur={handleBlur}
            setFieldValue={setFieldValue}
            getOptionLabel={(option) => option.label}
            helperText={<Trans i18nKey="ADVISOR-ONBOARDING-STEP-3-MAXIMUM" />}
            id="advisorIndustry"
            input-data-cy="txtCompanyIndustry"
            label={t('ADVISOR-ONBOARDING-STEP-3-ENTER-TOP-INDUSTRY')}
            subtitle={t('ADVISOR-ONBOARDING-STEP-3-ADDING-ADDITIONAL-SKILLS')}
            limitTags={3}
            options={tags.industries}
            placeholder={t(
              'ADVISOR-ONBOARDING-STEP-3-ENTER-TOP-SKILLS-PLACEHOLDER'
            )}
            ListboxProps={{
              style: { fontFamily: 'Poppins' },
            }}
          />
        </div>

        <div style={classes.continueButtonContainer}>
          <ACButton
            color="primary"
            data-cy="btnStep3Continue"
            disabled={isContinueButtonDisabled}
            loading={isLoading ? 1 : 0}
            style={{ width: 200, fontFamily: 'Poppins' }}
            onClick={() => {
              submitStep3Form(values);
            }}
          >
            {t('CONTINUE-BUTTON-TEXT')}
          </ACButton>
        </div>
      </Form>
    </>
  );
}

export default Step3FormikForm;
