import React, { useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  Avatar,
  AvatarGroup,
} from '@mui/material';
import { useAccount } from './../../../contexts/Account';
import DefaultAdvisorAvatar from './../../../icons/user-avatar.svg';
import eventBus, { eventBusValues } from './../../../eventBus';

const useStyles = ({ isSM }) => ({
  root: {
    display: 'flex',
    flexDirection: !isSM ? 'row' : 'column',
    alignItems: 'center',
    paddingTop: '25px',
  },
  rectangle: {
    width: isSM ? '100%' : '220px',
    height: isSM ? '140px' : '220px',
    borderRadius: '16px',
    margin: '10px',
    padding: isSM ? '15px' : '23px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
  },
  rectangle50: {
    width: isSM ? '50%' : '220px',
    height: isSM ? '150px' : '220px',
    borderRadius: '16px',
    margin: '10px',
    padding: isSM ? '15px' : '23px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
  },
  data: {
    textAlign: 'left',
    paddingTop: '20px',
  },
  data50: {
    textAlign: isSM ? 'center' : 'left',
    paddingTop: '20px',
    flexBasis: isSM ? '25%' : '50%',
  },
  tileTitle: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#232B35',
    letterSpacing: '0.5px',
    fontFamily: 'Poppins',
  },
  description: {
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#232B35',
    marginTop: '2px',
    fontFamily: 'Poppins',
  },
  dataDisplay: {
    fontWeight: 600,
    fontSize: '36px',
    lineHeight: '32px',
    color: '#232B35',
    letterSpacing: '-1.5px',
    fontFamily: 'Poppins',
  },
});

const CompanyOwnProfileTilesSlide = ({ company }) => {
  const [teamSeatsAvailable, setTeamSeatsAvailable] = useState(0);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles({ isSM });
  const { isFreemium, accountDetails } = useAccount();
  const avatarsToDisplay =
    company && company.dashboardStats
      ? company.dashboardStats.advisorImages.slice(0, 3)
      : [];

  function getAdvisorImagePath(image) {
    if (image) {
      return image;
    }
    return null;
  }

  if (company && company.dashboardStats) {
    console.log('slide', company.dashboardStats);
    console.log('stats', accountDetails.dashboardStats);
  }

  useEffect(() => {
    if (company && company.boardSeatsAvailable && company.contacts) {
      const teamSeatsAvailable =
        parseInt(company.boardSeatsAvailable) -
        company.contacts.filter(
          (contact) =>
            contact.accountStatus === 'active' ||
            contact.accountStatus === 'new'
        ).length;

      setTeamSeatsAvailable(teamSeatsAvailable);
    }
  }, [company]);

  function goToUpgradePage() {
    eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction, {
      gatedAction: true,
      companyName: company ? company.companyName : '',
    });
  }

  function onboardingTitle() {
    return (
      <>
        <Typography
          style={{
            ...classes.dataDisplay,
            fontSize: isSM ? '18px' : '20px',
            lineHeight: '24px',
            letterSpacing: '-1px',
          }}
        >
          {isFreemium ? 'AdvisoryCloud' : 'Advisory Board'}
        </Typography>
        {isFreemium ? (
          <Typography
            onClick={() => goToUpgradePage()}
            style={{
              ...classes.description,
              cursor: 'pointer',
              color: '#3171F6',
              fontWeight: 700,
            }}
          >
            See upgrade options
          </Typography>
        ) : (
          <Typography style={classes.description}>
            Onboarding {company.onboardingStatus}
          </Typography>
        )}
      </>
    );
  }

  function teamSeatsAvailableTile() {
    return (
      <>
        {company.boardSeatsAllowed === -1 ? (
          <Typography
            style={{
              ...classes.dataDisplay,
              fontSize: '20px',
              lineHeight: '24px',
              letterSpacing: '-1px',
            }}
          >
            Unlimited
          </Typography>
        ) : (
          <Typography style={classes.dataDisplay}>
            {teamSeatsAvailable}
          </Typography>
        )}

        <Typography style={classes.description}>
          Team seats available
        </Typography>
      </>
    );
  }

  return (
    <div>
      <div style={classes.root}>
        {!isSM ? (
          <Paper
            style={{
              ...classes.rectangle,
              marginLeft: 0,
              backgroundColor: '#FFEED6',
            }}
          >
            <Typography variant="h6" style={classes.tileTitle}>
              Account
            </Typography>
            <Grid container>
              <Grid item xs={12} style={classes.data}>
                {onboardingTitle()}
              </Grid>
              <Grid item xs={12} style={classes.data}>
                {teamSeatsAvailableTile()}
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={6} style={{ width: '50%' }}>
              <Paper
                style={{
                  ...classes.rectangle,
                  marginLeft: 0,
                  backgroundColor: '#FFEED6',
                }}
              >
                <Typography variant="h6" style={classes.tileTitle}>
                  Account
                </Typography>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: '10px', textAlign: 'left' }}
                  >
                    {onboardingTitle()}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6} style={{ width: '50%' }}>
              <Paper
                style={{
                  ...classes.rectangle,
                  marginLeft: 0,
                  backgroundColor: '#FFEED6',
                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: '30px', textAlign: 'left' }}
                  >
                    {teamSeatsAvailableTile()}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )}

        <Paper style={{ ...classes.rectangle, backgroundColor: '#D7F1FD' }}>
          <Typography variant="h6" style={classes.tileTitle}>
            Feedback
          </Typography>
          <Grid container>
            <Grid item xs={6} style={classes.data50}>
              <Typography style={classes.dataDisplay}>
                {company.dashboardStats ? company.dashboardStats.postsCount : 0}
              </Typography>
              <Typography style={classes.description}>Posts</Typography>
            </Grid>
            <Grid item xs={6} style={classes.data50}>
              <Typography style={classes.dataDisplay}>
                {company.dashboardStats.answersCount}
              </Typography>
              <Typography style={classes.description}>Answers</Typography>
            </Grid>
            <Grid item xs={6} style={classes.data50}>
              <Typography style={classes.dataDisplay}>
                {company.dashboardStats.commentsCount}
              </Typography>
              <Typography style={classes.description}>Comments</Typography>
            </Grid>
            <Grid item xs={6} style={classes.data50}>
              <Typography style={classes.dataDisplay}>
                {company.dashboardStats.insightsCount}
              </Typography>
              <Typography style={classes.description}>Insights</Typography>
            </Grid>
          </Grid>
        </Paper>

        <Paper style={{ ...classes.rectangle, backgroundColor: '#DBF7EB' }}>
          <Grid
            container
            style={{
              margin: 0,
              padding: 0,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'start',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: '0px',
            }}
          >
            <Grid
              item
              xs={6}
              style={{
                textAlign: 'left',
                flexBasis: '50%',
              }}
            >
              <Typography variant="h6" style={classes.tileTitle}>
                Advisors
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                textAlign: 'left',
                flexBasis: '50%',
                marginTop: '-25px',
              }}
            >
              <AvatarGroup
                style={{ paddingTop: '20px' }}
                renderSurplus={(surplus) => (
                  <span>+{surplus.toString()[0]}k</span>
                )}
              >
                {avatarsToDisplay.map((avatar, index) => {
                  const imagePath = getAdvisorImagePath(avatar);
                  if (!imagePath) {
                    return null;
                  }
                  return (
                    <Avatar
                      key={index}
                      src={imagePath}
                      size={isSM ? 'md' : 'sm'}
                      sx={{
                        border: 'none !important',
                        width: '35.5px',
                        height: '35.5px',
                        marginLeft: '-16px !important',
                      }}
                    />
                  );
                })}
              </AvatarGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} style={classes.data50}>
              <Typography style={classes.dataDisplay}>
                {company.dashboardStats.invitationsCount}
              </Typography>
              <Typography style={classes.description}>Invites sent</Typography>
            </Grid>
            <Grid item xs={6} style={classes.data50}>
              <Typography style={classes.dataDisplay}>
                {company.dashboardStats.lastMonthAdvisors}
              </Typography>
              <Typography style={classes.description}>New advisors</Typography>
            </Grid>
            <Grid item xs={6} style={classes.data50}>
              <Typography style={classes.dataDisplay}>
                {company.dashboardStats.totalAdvisors}
              </Typography>
              <Typography style={classes.description}>Advisors</Typography>
            </Grid>
            <Grid item xs={6} style={classes.data50}>
              <Typography style={classes.dataDisplay}>
                {company.dashboardStats.savedAdvisorCount}
              </Typography>
              <Typography style={classes.description}>Saved</Typography>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
};

export default CompanyOwnProfileTilesSlide;
