import React from 'react';
import { useMediaQuery, Card, Typography } from '@mui/material';
import { useAccount } from '../../../contexts/Account';
import { getAdvisorQueryParams } from '../../../utils/queryParams';

function setCardPadding(sizes) {
  if (sizes.isXS) {
    return '5px';
  }
  if (sizes.isSM) {
    return '10px';
  }
  return '15px';
}

const useStyles = (sizes) => ({
  card: {
    padding: setCardPadding(sizes),
    borderRadius: '15px',
    position: 'relative',
    height: '360px',
    border: '1px solid #3171F6',
    width: { xxs: '90vw', xs: '100%' },
    ':hover': {
      boxShadow: '0 1px 10px #D0D7E6',
    },
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: '20px',
  },
  headline: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 500,
    color: '#232B35',
    lineHeight: '24px',
    padding: '0 10px',
  },
  learnMore: {
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 700,
    color: '#3171F6',
    lineHeight: '24px',
    marginTop: '16px',
  },
  title: {
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 600,
    color: '#232B35',
    lineHeight: '24px',
  },
  titleContainer: {
    height: 'auto',
    margin: '20px 0',
  },
});

const BuildYourOwnBoardTile = () => {
  const { accountDetails, isFreeTrial, isFreemium } = useAccount();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const standardXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down([350]));
  const classes = useStyles({ isSM, isXS });

  return (
    <Card
      hover={10}
      onClick={() => {
        window.location.assign(
          `https://advisorycloud.com/add-a-board-to-your-membership?${getAdvisorQueryParams(
            { accountDetails, isFreeTrial, isFreemium }
          )}`,
          '_blank'
        );
      }}
      elevation={0}
      id="buildYourOwnBoardTile"
      sx={classes.card}
      style={{
        cursor: 'pointer',
      }}
    >
      <div style={classes.imageContainer}>
        <img
          src="https://cdn.advisorycloud.com/images/buildaboard_boarddirectory_tile.png"
          alt="buildaboard_boarddirectory_tile"
          style={{ width: standardXS ? '50%' : '60%' }}
        />
      </div>
      <div style={classes.titleContainer}>
        <Typography sx={classes.title}>Did you know?</Typography>
      </div>
      <Typography sx={classes.headline}>
        You can build your own advisory board for your company, team, or special
        project at no additional cost!
      </Typography>
      <Typography sx={classes.learnMore}>LEARN MORE</Typography>
    </Card>
  );
};

export default BuildYourOwnBoardTile;
