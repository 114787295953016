import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { useAccount } from '../../contexts/Account';
import CardScene from '../../components/CardScene';

const useStyles = () => ({
  formContainer: {
    paddingTop: '30px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '20px',
    height: '100%',
  },
  iframe: {
    width: '100%',
    height: '400px',
    overflowX: 'visible',
  },
});

function AdvisorOnboardingStep5() {
  const classes = useStyles();
  const history = useHistory();
  const { accountDetails } = useAccount();

  useEffect(() => {
    if (accountDetails.onboardingStatus !== 'step5') {
      if (
        accountDetails.onboardingStatus === 'Complete' ||
        !accountDetails.onboardingStatus
      ) {
        history.replace('/dashboard');
      } else {
        history.replace(
          `/advisor/onboarding/${accountDetails.onboardingStatus}`
        );
      }
    }
    const interval = setInterval(() => {
      accountDetails.onboardingStatus = 'step6';
      history.replace('/advisor/onboarding/step6');
    }, 4800);
    return () => clearInterval(interval);
  }, []);

  return (
    <CardScene isAdvisorOnboarding clearPaper sceneHeight="100vh">
      <div style={classes.formContainer}>
        <iframe
          src="https://cdn.advisorycloud.com/static-content/loading-white-background.html"
          title="Meet"
          frameBorder="0"
          style={classes.iframe}
        />
      </div>
    </CardScene>
  );
}

export default AdvisorOnboardingStep5;
