// Board meeting post pop up (only admins)
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Autocomplete,
  Box,
  Typography,
  TextField,
  Chip,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ACButton from './../../ACButton';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { zonedTimeToUtc } from 'date-fns-tz';
import { Clear, PictureAsPdfSharp, OndemandVideo } from '@mui/icons-material';
import { google } from 'calendar-link';
import ACSnackbar from './../../ACSnackbar';
import CompanyPostButton from './../../Company/PostButton';
import FileUploader from '../PopUpFileUploader';
import {
  companyUploadPostCall,
  companyUploadAttachmentsCall,
  companyUpdatePostCall,
  companyAddPostVideoCall,
  companyAddBoardMeetingVideoCall,
} from './../../../services/company_services';
import { useAccount } from './../../../contexts/Account';
import Carousel from './../Carousel';
import CarouselCard from './../Carousel/CarosuelCard';
import AutocompleteSkills from './questionSkill';
import BackButton from './backButton/index';
import { uploadToS3 } from './../../../services/Amplify/amplify-files-upload';
import ModeratorImageUploader from './moderatorImageUploader';
import { Enviroments } from './../../../services/utils/types';
import defaultTimezones from './timeZones.json';
import PermissionRadio from './../../CompanyPostPopUp/Permissions';
import TextEditor from '../../CompanyPostPopUp/textEditor';
import eventBus, { eventBusValues } from '../../../eventBus';
import { sendFSEventPostPopUp } from '../../../services/FullStory';

const useStyles = (isSM) => ({
  container: {
    marginTop: '5px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: isSM ? '15px' : '0px',
    marginRight: isSM ? '15px' : '0px',
  },
  buttonRowMobile: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '5px',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    position: 'relative',
    marginBottom: '5px',
  },
  titleInput: {
    marginTop: '1px',
    border: '1.5px',
    width: '100%',
  },
  postInput: {
    minHeight: '70px',
    border: '1px',
    width: '100%',
  },
  postInputExpandable: {
    minHeight: '100px',
    border: '1px',
    width: '100%',
  },
  preview: {
    width: '100%',
    height: '310px',
    textAlign: 'center',
    borderColor: 'secondary.main',
    borderRadius: '5px',
    marginBottom: '10px',
  },
  previewImage: {
    height: '100%',
    borderRadius: '5px',
    overflowX: 'hidden',
  },
  input: {
    '&::placeholder': {
      fontSize: '.8rem',
    },
    fontSize: '16px',
  },
  carouselContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '95px',
  },
  featureImageText: {
    marginTop: '7px',
    marginBottom: '7px',
  },
  warningText: {
    color: 'error.main',
  },
  warningTextMobile: {
    marginTop: '-15px',
    marginBottom: '15px',
  },

  skillsRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '15px',
    marginTop: '15px',
    widht: '100%',
    flexDirection: 'column',
  },

  chip: {
    color: 'primary.main',
    marginRight: '5px',
    marginTop: '10px',
  },
  chipColor: {
    color: 'primary.main',
  },
  iconSmall: {
    color: 'primary.main',
  },
  skillListContainer: {
    marginTop: '10px',
    display: 'flex',
    widht: '100%',
  },
  skillList: {
    listStyleType: 'none',
    padding: '0px',
    margin: '0px',
  },
  remove: {
    display: 'none',
  },
  editButtonsContainer: {
    display: 'flex',
  },
  addMargin: {
    marginRight: '15px',
  },
  inputVideo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
  },
  button: {
    borderRadius: '2px',
  },
  buttonVideoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
  },
  iconVideo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    marginRight: '7px',
  },
  closeIcon: {
    fontSize: '25px',
    marginRight: '10px',
    bottom: '-5px',
    position: 'relative',
    display: 'inline',
    cursor: 'pointer',
  },
  videoUrlField: {
    minWidth: '300px',
  },
  warningRow: {
    display: 'flex',
    justifyContent: 'end',
  },
  videoPreview: {
    position: 'relative',
    paddingBottom: '56.25%',
    height: '0px',
    width: '100%',
    overflow: 'hidden',
    marginTop: '5px',
  },
  videoFrame: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
  },
  linksFields: {
    minWidth: '400px',
    marginBottom: '15px',
  },
  moderatorFields: {
    minWidth: '250px',
    marginBottom: '15px',
  },
  moderatorContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '5px',
  },
  avatarContainer: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  advisorInfoContainer: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  meetingTimeContainer: {
    marginBottom: '15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
  },
});

function PostPopUp(props) {
  const {
    onChange,
    type,
    callbackAfterPost,
    editMode,
    editingPost,
    callbackAfterEdit,
    callbackAfterCancel,
    postData,
    automaticCalendarLink,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [postText, setPostText] = useState(editingPost ? editingPost.body : '');
  const [postTitle, setPostTitle] = useState('');
  const classes = useStyles(isSM);
  const { t } = useTranslation();
  const [uploaderOutput, setUploaderOutput] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const { accountDetails, asLoggedUserDetails, tags } = useAccount();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSrc, setSelectedSrc] = useState();
  const [selectedId, setSelectedId] = useState();
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [textIsShorter, setTextIsShorter] = useState(false);
  const [skillsArray, setSkillsArray] = useState();

  function getPermissionValues() {
    if (
      editingPost &&
      editingPost.hideToAdvisors &&
      editingPost.hideToContacts
    ) {
      return 'Board Owner';
    } else if (editingPost && editingPost.hideToAdvisors) {
      return 'Company Users';
    }
    return 'Company and Advisor Users';
  }
  const [permission, setPermission] = useState(getPermissionValues());
  const [hideToAdvisors, setHideToAdvisors] = useState(
    editingPost ? editingPost.hideToAdvisors : false
  );
  const [hideToContacts, setHideToContacts] = useState(
    editingPost ? editingPost.hideToContacts : false
  );

  const [openVideo, setOpenVideo] = useState(
    editingPost && editingPost.video && editingPost.video.url
  );
  const [videoUrl, setVideoUrl] = useState(
    editingPost && editingPost.video ? editingPost.video.url : ''
  );
  const [embededUrl, setEmbededUrl] = useState(
    editingPost && editingPost.video ? editingPost.video.url : ''
  );
  const [videoType, setVideoType] = useState(
    editingPost && editingPost.video ? editingPost.video.type : 'youtube'
  );
  const [videoUrlEror, setVideoUrlError] = useState(false);

  const [streamLink, setStreamLink] = useState('');
  const [meetingLink, setMeetingLink] = useState('');
  const [calendarLink, setCalendarLink] = useState('');
  const [moderatorName, setModeratorName] = useState('');
  const [moderatorTitle, setModeratorTitle] = useState('');
  const [moderatorAvatar, setModeratorAvatar] = useState('');
  const [moderatorAvatarEdited, setModeratorAvatarEdited] = useState(false);
  const [meetingTime, setMeetingTime] = useState();
  const [meetingTimeZone, setMeetingTimeZone] = useState('');
  const [timeZoneList] = useState(
    Object.keys(defaultTimezones).map((key) => ({
      id: defaultTimezones[key].toString(),
      label: key.toString(),
    }))
  );
  const [timeZoneListLabels] = useState(
    Object.keys(defaultTimezones).map((key) => key)
  );
  const [meetingTimeZoneInput, setMeetingTimeZoneInput] = useState('');
  const [addPostDisabled, setAddPostDisabled] = useState(false);

  const mobileDisplay = isSM && !editMode;

  useEffect(() => {
    if (!moderatorAvatar && postData) {
      setModeratorAvatarEdited(true);
    }
  }, [moderatorAvatar]);

  useEffect(() => {
    if (type === 'boardMeeting' && postData) {
      const localeHour = new Date(postData.meetingTime);
      let strTime = localeHour.toLocaleString('en-US', {
        timeZone: `${postData.meetingTimeZone}`,
      });
      const streamingDate = new Date(strTime);

      setPostText(postData.body);
      setPostTitle(postData.postTitle);
      setStreamLink(postData.streamLink);
      setMeetingLink(postData.meetingLink);
      setCalendarLink(postData.calendarLink);
      setModeratorName(postData.moderatorName);
      setModeratorTitle(postData.moderatorTitle);
      setMeetingTime(streamingDate);
      const timezone = timeZoneList.find(
        (x) => x.id == postData.meetingTimeZone
      );
      setMeetingTimeZone(postData.meetingTimeZone);
      setMeetingTimeZoneInput(timezone.label);
    } else if (type === 'boardMeeting') {
      setMeetingTime(new Date());
    }
  }, []);

  useEffect(() => {
    if (type === 'boardMeeting') {
      setAddPostDisabled(
        !moderatorAvatar ||
          !streamLink ||
          !(calendarLink || automaticCalendarLink) ||
          !meetingLink ||
          !moderatorName ||
          !moderatorTitle ||
          !postTitle ||
          !meetingTime ||
          !meetingTimeZone ||
          zonedTimeToUtc(meetingTime, meetingTimeZone) == 'Invalid Date'
      );
    }
  }, [
    moderatorAvatar,
    streamLink,
    calendarLink,
    meetingLink,
    moderatorName,
    moderatorTitle,
    postTitle,
    meetingTime,
    meetingTimeZone,
  ]);

  function setAttachmentsArray(value) {
    uploaderOutput.push(value);
    setUploaderOutput([...uploaderOutput]);
  }

  function deleteArrayElement(data) {
    const filteredArray = uploaderOutput.filter((item) => item.id !== data);
    setUploaderOutput([...filteredArray]);
    if (uploaderOutput.length === 0) {
      setSelectedSrc('');
    }
  }
  function deleteSkillsElement(data) {
    const filteredArray = skillsArray.filter((item) => item.label !== data);
    setSkillsArray([...filteredArray]);
  }

  function selectArrayElement(data) {
    if (data === -1) {
      setSelectedSrc('');
      setSelectedId('');
    } else {
      setSelectedSrc(uploaderOutput[data].url);
      setSelectedId(uploaderOutput[data].id);
    }
  }

  async function makePost(postType) {
    try {
      const makePostCall = await companyUploadPostCall({
        COMPANY_ID: accountDetails.id,
        TYPE: postType,
        BODY: postText,
        COMPANY_CONTACT_ID: asLoggedUserDetails.userId,
      });
      const post = makePostCall.data.addPost;
      if (openVideo && videoType && videoUrl) {
        const postVideoResponse = await companyAddPostVideoCall({
          COMPANY_CONTACT_ID: asLoggedUserDetails.userId,
          COMPANY_ID: accountDetails.id,
          POST_ID: post.id,
          VIDEO_TYPE: videoType,
          VIDEO_URL: embededUrl,
        });
        post.video = postVideoResponse.data.addPostVideo.video;
      }
      if (uploaderOutput.length > 0) {
        const attachments = await companyUploadAttachmentsCall({
          POST_ID: post.id,
          ATTACHMENTS_ARRAY: uploaderOutput.map((data) => {
            return JSON.stringify({
              id: data.id,
              filename: data.name,
              url: data.url,
            });
          }),
          PRIMARY_ATTACHMENT: selectedId,
          USER_ID: asLoggedUserDetails.userId,
          COMPANY_ID: accountDetails.id,
        });
        post.attachments = [...attachments.data.uploadAttachments.attachments];
      }
      if (
        type === 'boardMeeting' &&
        moderatorAvatar &&
        streamLink &&
        (calendarLink || automaticCalendarLink) &&
        meetingLink &&
        moderatorName &&
        moderatorTitle &&
        postTitle
      ) {
        let bucketImages = process.env.REACT_APP_S3_BUCKET_IMAGES;
        if (process.env.NODE_ENV === Enviroments.PRODUCTION) {
          bucketImages = process.env.REACT_APP_S3_BUCKET_IMAGES.replace(
            'us-west-2',
            'us-east-1'
          );
        }
        const imageId = `${uuidv4()}.${moderatorAvatar.type.split('/')[1]}`;
        await uploadToS3({
          BLOB_LOAD: moderatorAvatar,
          CONTENT_TYPE: moderatorAvatar.type,
          FILE_ID: imageId,
          S3_BUCKET: bucketImages,
        });
        const uploadedAvatarId = `public/${imageId}`;
        let dateUTC = zonedTimeToUtc(meetingTime, meetingTimeZone);
        if (dateUTC != 'Invalid Date') {
          dateUTC = dateUTC.toISOString();
          const googleCalendarLink = google({
            title: postTitle,
            description: postText,
            start: dateUTC,
            duration: [1, 'hour'],
          });
          await companyAddBoardMeetingVideoCall({
            COMPANY_CONTACT_ID: asLoggedUserDetails.userId,
            COMPANY_ID: accountDetails.id,
            POST_ID: post.id,
            STREAM_LINK: streamLink,
            MEETING_LINK: meetingLink,
            CALENDAR_LINK: calendarLink ? calendarLink : googleCalendarLink,
            MODERATOR_NAME: moderatorName,
            MODERATOR_TITLE: moderatorTitle,
            MODERATOR_AVATAR: uploadedAvatarId,
            POST_TITLE: postTitle,
            MEETING_TIME: dateUTC,
            MEETING_TIMEZONE: meetingTimeZone,
            BODY: postText,
            IS_EDIT: false,
          });
          post.streamLink = streamLink;
          post.meetingLink = meetingLink;
          post.meetingTime = dateUTC;
          post.meetingTimeZone = meetingTimeZone;
          post.moderatorAvatar = uploadedAvatarId;
          post.moderatorName = moderatorName;
          post.moderatorTitle = moderatorTitle;
          post.calendarLink = calendarLink ? calendarLink : googleCalendarLink;
          post.postTitle = postTitle;
        }
      }
      sendFSEventPostPopUp({
        accountDetails,
        asLoggedUserDetails,
        type: 'boardMeeting',
        body: postText,
        attachments: [],
        posted: true,
        video: false,
      });
      if (callbackAfterPost) {
        callbackAfterPost(post);
      }
    } catch (err) {
      console.log(err);
      throw new Error('ERROR at make Post', err);
    }
  }

  async function makeEdit() {
    try {
      const makeEditCall = await companyUpdatePostCall({
        BODY: postText,
        COMPANY_CONTACT_ID: asLoggedUserDetails.userId,
        COMPANY_ID: accountDetails.id,
        POST_ID: editingPost.id,
        VIDEOTYPE: openVideo ? videoType || '' : '',
        VIDEOURL: openVideo ? embededUrl || '' : '',
        HIDE_TO_ADVISORS: hideToAdvisors,
        HIDE_TO_CONTACTS: hideToContacts,
      });

      const post = makeEditCall.data.updatePost;

      const attachments = await companyUploadAttachmentsCall({
        POST_ID: post.id,
        ATTACHMENTS_ARRAY: uploaderOutput.map((data) => {
          return JSON.stringify({
            id: data.id,
            filename: data.name,
            url: data.url,
          });
        }),
        PRIMARY_ATTACHMENT: selectedId || '',
        USER_ID: asLoggedUserDetails.userId,
        COMPANY_ID: accountDetails.id,
      });
      post.attachments = [...attachments.data.uploadAttachments.attachments];

      if (callbackAfterEdit) {
        callbackAfterEdit(post);
      }
    } catch (err) {
      console.log(err);
      throw new Error('ERROR at edit Post', err);
    }
  }

  async function makeBoardMeetingEdit(postType) {
    try {
      if (
        postType === 'boardMeeting' &&
        moderatorAvatar &&
        streamLink &&
        meetingLink &&
        calendarLink &&
        moderatorName &&
        moderatorTitle &&
        postTitle
      ) {
        let uploadedAvatarId;
        if (moderatorAvatarEdited) {
          let bucketImages = process.env.REACT_APP_S3_BUCKET_IMAGES;
          if (process.env.NODE_ENV === Enviroments.PRODUCTION) {
            bucketImages = process.env.REACT_APP_S3_BUCKET_IMAGES.replace(
              'us-west-2',
              'us-east-1'
            );
          }
          const imageId = `${uuidv4()}.${moderatorAvatar.type.split('/')[1]}`;
          await uploadToS3({
            BLOB_LOAD: moderatorAvatar,
            CONTENT_TYPE: moderatorAvatar.type,
            FILE_ID: imageId,
            S3_BUCKET: bucketImages,
          });
          uploadedAvatarId = `public/${imageId}`;
        } else {
          uploadedAvatarId = postData.moderatorAvatar;
        }

        let dateUTC = zonedTimeToUtc(meetingTime, meetingTimeZone);
        if (dateUTC != 'Invalid Date') {
          dateUTC = dateUTC.toISOString();
          await companyAddBoardMeetingVideoCall({
            COMPANY_CONTACT_ID: asLoggedUserDetails.userId,
            COMPANY_ID: accountDetails.id,
            POST_ID: postData.id,
            STREAM_LINK: streamLink,
            MEETING_LINK: meetingLink,
            CALENDAR_LINK: calendarLink,
            MODERATOR_NAME: moderatorName,
            MODERATOR_TITLE: moderatorTitle,
            MODERATOR_AVATAR: uploadedAvatarId,
            POST_TITLE: postTitle,
            MEETING_TIME: dateUTC,
            MEETING_TIMEZONE: meetingTimeZone,
            BODY: postText,
            IS_EDIT: true,
          });
        }
      }
      if (callbackAfterPost) {
        callbackAfterPost(postData);
      }
    } catch (err) {
      throw new Error('ERROR at edit Post', err);
    }
  }

  async function handlePost(postType) {
    setIsLoading(true);

    try {
      if (!editMode) {
        await makePost(postType);
      } else if (postType === 'boardMeeting') {
        await makeBoardMeetingEdit(postType);
      } else {
        await makeEdit();
      }
      if (onChange) {
        onChange(false);
      }
    } catch (err) {
      setIsLoading(false);
      setShowSnackbar(true);
    }
  }

  function makePostSelector() {
    handlePost(type);
  }

  function handleAdvisorSkillsAutocompleteChange(event, vals) {
    setSkillsArray(vals);
  }

  // Ref solo para postText
  const postTextRef = useRef(postText);

  // Ref para evitar múltiples envíos (flag)
  const isEventSentRef = useRef(false);

  // Ref para almacenar el último valor enviado y compararlo con el nuevo
  const lastSentValueRef = useRef('');

  // Sincronizar referencia con el estado de postText
  useEffect(() => {
    postTextRef.current = postText;
  }, [postText]);

  // Estado para indicar si se debe enviar el evento
  const [shouldSendEvent, setShouldSendEvent] = useState(false);

    // Definir `sendPostFSEventRef` usando `useCallback` y `isEventSentRef`
    const sendPostFSEventRef = useCallback(
      async (posted) => {
        if (isEventSentRef.current) {
          return; // Evitar envíos múltiples
        }
  
        const currentPostText = postTextRef.current;
  
        // Si el valor actual es el mismo que el último enviado, evitamos el envío
        if (lastSentValueRef.current === currentPostText) {
          return;
        }
  
        // Actualizamos el valor del último evento enviado
        lastSentValueRef.current = currentPostText;
  
        // Marcamos que se está enviando el evento para evitar duplicados
        isEventSentRef.current = true;
  
        try {
          // Envía el evento con la información actual y espera a que finalice
          sendFSEventPostPopUp({
            accountDetails,
            type,
            asLoggedUserDetails,
            body: currentPostText, // Usar la referencia actualizada de postText
            attachments: uploaderOutput, // Usar el estado directamente
            posted: posted ? true : false,
            video: openVideo && videoType && videoUrl ? true : false,
          });
        } catch (error) {
          console.error('Error al enviar FullStory event', error);
        } finally {
          isEventSentRef.current = false; // Reiniciamos el flag para permitir futuros envíos
        }
      },
      [
        type,
        uploaderOutput,
        openVideo,
        videoType,
        videoUrl,
      ]
    );
  
    // useEffect para manejar la lógica de envío basado en cambios en `shouldSendEvent`
    useEffect(() => {
      if (shouldSendEvent) {
        sendPostFSEventRef(false);
        setShouldSendEvent(false); // Reiniciar el estado después de enviar
      }
    }, [shouldSendEvent, sendPostFSEventRef]);
  
    // Evento de cierre del post usando el eventBus
    useEffect(() => {
      const handleCloseCreatePost = () => {
        setShouldSendEvent(true); // Activar el envío de eventos cuando se cierre el popup
      };
  
      eventBus.on(eventBusValues.closeCreatePost, handleCloseCreatePost);
  
      return () => {
        eventBus.remove(eventBusValues.closeCreatePost, handleCloseCreatePost);
      };
    }, []);

  function editButtons() {
    return (
      <div style={classes.editButtonsContainer}>
        <div style={classes.addMargin}>
          <ACButton color="secondary" onClick={() => callbackAfterCancel()}>
            Cancel
          </ACButton>
        </div>
        <Box
          onClick={() => {
            if (textIsShorter) {
              setShowWarningMessage(true);
              setTimeout(() => {
                setShowWarningMessage(false);
              }, 3000);
            }
          }}
        >
          <ACButton
            color="primary"
            loading={isLoading ? 1 : 0}
            disabled={addPostDisabled || textIsShorter}
            onClick={() => makePostSelector()}
          >
            Save
          </ACButton>
        </Box>
      </div>
    );
  }

  function getPreview() {
    let previewToReturn;
    if (selectedId) {
      if (selectedId.includes('.pdf')) {
        previewToReturn = (
          <PictureAsPdfSharp sx={{ ...classes.previewImage, fontSize: 300 }} />
        );
      } else {
        previewToReturn = (
          <img src={selectedSrc} alt="" style={classes.previewImage} />
        );
      }
    } else {
      previewToReturn = null;
    }
    return previewToReturn;
  }

  function validateVideoUrl(url) {
    if (url === undefined || url === '' || url.length === 0) {
      setVideoUrlError(false);
      setEmbededUrl(false);
      return;
    }

    let regExp =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    let match = url.match(regExp);
    if (match && match[1].length === 11) {
      setVideoUrlError(false);
      setVideoType('youtube');
      setEmbededUrl(`https://www.youtube.com/embed/${match[1]}?autoplay=0`);
      return;
    }

    regExp =
      /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
    match = url.match(regExp);
    if (match && match[3] && match[3].length > 0) {
      setVideoUrlError(false);
      setVideoType('vimeo');
      setEmbededUrl(`https://player.vimeo.com/video/${match[3]}?autoplay=0`);
      return;
    }

    regExp =
      /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:event\/(?:\w+\/)?|event\/([^\/]*)\/event\/|event\/(\d+)\/event\/|event\/|)(\d+)(?:$|\/|\?)/;
    match = url.match(regExp);
    if (match && match[3] && match[3].length > 0) {
      setVideoUrlError(false);
      setVideoType('vimeo');
      setEmbededUrl(`https://vimeo.com/event/${match[3]}/embed?autoplay=0`);
      return;
    }

    setVideoUrlError('Video link does not match a hosting service');
    setEmbededUrl(false);
    return;
  }

  function disablePostButton() {
    if (textIsShorter) return true;
    if (postText.length === 0) return true;
    if (openVideo && videoUrlEror) return true;
    if (openVideo && videoUrl.length === 0) return true;
    if (type === 'boardMeeting') {
      if (!calendarLink && !automaticCalendarLink) return true;
      if (!streamLink) return true;
      if (!meetingLink) return true;
      if (!moderatorTitle) return true;
      if (!moderatorName) return true;
      if (!moderatorAvatar) return true;
      if (!postTitle) return true;
      if (!meetingTime) return true;
      if (!meetingTimeZone) return true;
      if (zonedTimeToUtc(meetingTime, meetingTimeZone) == 'Invalid Date')
        return true;
    }
    return false;
  }

  useEffect(() => {
    if (
      editingPost &&
      editingPost.attachments &&
      editingPost.attachments.length > 0
    ) {
      const rearrangedAttachments = editingPost.attachments.map(
        (attachment) => {
          function getPostUrl() {
            if (
              attachment.thumbnailUrl &&
              attachment.thumbnailUrl.includes('.pdf')
            ) {
              return attachment.thumbnailUrl;
            }
            if (attachment.url && attachment.url.includes('.pdf')) {
              return attachment.url;
            }
            return `https://${attachment.thumbnailUrl}`;
          }

          return {
            id: attachment.id,
            name: attachment.filename,
            url: getPostUrl(),
          };
        }
      );
      setUploaderOutput(rearrangedAttachments);
    }
  }, [editingPost]);

  return (
    <div style={classes.container}>
      <ACSnackbar
        open={showSnackbar}
        text={
          type === 'question'
            ? t('POST-QUESTION-ERROR')
            : t('POST-UPDATE-ERROR')
        }
        severity="error"
        onClose={() => setShowSnackbar(false)}
        autoHideDuration={6000}
      />
      {mobileDisplay && (
        <div style={classes.buttonRowMobile}>
          <BackButton
            buttonColor="primary"
            buttonSize="small"
            onClick={() => onChange(false)}
          />
          <div style={classes.buttonGroup}>
            {editMode ? (
              editButtons()
            ) : (
              <CompanyPostButton
                buttonColor="primary"
                buttonSize="small"
                buttonVariant={type === 'update' ? 'outlined' : ''}
                type={type === 'question' ? 'question' : 'update'}
                onClick={() => makePostSelector()}
                loading={isLoading}
                isDisabled={disablePostButton()}
              />
            )}
          </div>
        </div>
      )}
      {type === 'boardMeeting' ? (
        <div>
          <TextField
            placeholder={'Title'}
            sx={classes.titleInput}
            onChange={(e) => {
              setPostTitle(e.target.value);
            }}
            value={postTitle}
            autoComplete="off"
            id="postPopUpTitle"
            name="postPopUpTitle"
            variant="outlined"
            size="small"
            InputProps={{
              classes: { input: classes.input },
            }}
          />
        </div>
      ) : null}
      <div
        style={{
          padding: isSM ? '0px 15px 0px 25px' : null,
        }}
      >
        <div style={classes.postInput}>
          <TextEditor
            type={type}
            initialText={editingPost ? editingPost.body : ''}
            setText={setPostText}
            setWarning={setTextIsShorter}
          />
        </div>
      </div>

      {type === 'insight' || type === 'meeting' ? (
        <PermissionRadio
          permission={permission}
          setPermission={setPermission}
          setHideToAdvisors={setHideToAdvisors}
          setHideToContacts={setHideToContacts}
        />
      ) : null}
      {type == 'boardMeeting' ? (
        <div>
          <div style={classes.meetingTimeContainer}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                value={meetingTime}
                label="Meeting Time"
                onChange={(newValue) => {
                  setMeetingTime(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    style={{ width: '50%', marginRight: 10 }}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            {/* {meetingTimeZone} */}
            <Autocomplete
              options={timeZoneListLabels}
              value={meetingTimeZoneInput}
              onChange={(event, vals) => {
                const timezone = timeZoneList.find((x) => x.label == vals);
                if (timezone) {
                  setMeetingTimeZone(timezone.id);
                } else {
                  setMeetingTimeZone('');
                }
                setMeetingTimeZoneInput(vals);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  label="Time Zone"
                  style={{ width: '300px' }}
                />
              )}
            />
          </div>
          <TextField
            size="small"
            onChange={(e) => {
              setStreamLink(e.target.value);
            }}
            variant="outlined"
            label="Live Stream Link"
            value={streamLink}
            sx={classes.linksFields}
          />
          <TextField
            size="small"
            onChange={(e) => {
              setMeetingLink(e.target.value);
            }}
            variant="outlined"
            label="Meeting Link"
            value={meetingLink}
            sx={classes.linksFields}
          />
          {!automaticCalendarLink ? (
            <TextField
              size="small"
              onChange={(e) => {
                setCalendarLink(e.target.value);
              }}
              variant="outlined"
              label="Calendar Link"
              value={calendarLink}
              helperText={videoUrlEror}
              sx={classes.linksFields}
            />
          ) : null}

          <div>
            <Typography>Moderator</Typography>
          </div>
          <div style={classes.moderatorContainer}>
            <div style={classes.avatarContainer}>
              <ModeratorImageUploader
                resultFiles={(images) => setModeratorAvatar(images.image)}
                preloadUrl={postData ? postData.moderatorAvatar : null}
              />
            </div>
            <div style={classes.advisorInfoContainer}>
              <TextField
                onChange={(e) => {
                  setModeratorName(e.target.value);
                }}
                label="Name"
                variant="outlined"
                size="small"
                value={moderatorName}
                sx={classes.moderatorFields}
              />
              <TextField
                onChange={(e) => {
                  setModeratorTitle(e.target.value);
                }}
                label="Title"
                variant="outlined"
                size="small"
                value={moderatorTitle}
                sx={classes.moderatorFields}
              />
            </div>
          </div>
        </div>
      ) : null}
      {/* TO DO: Allow attachment uploading during edit mode, remove editMode check */}
      {uploaderOutput.length !== 0 ? (
        <>
          <div style={classes.preview}>{getPreview()}</div>
          <section style={classes.carouselContainer}>
            <Carousel
              deleteElement={deleteArrayElement}
              slideSelected={selectArrayElement}
              isEdit
            >
              {uploaderOutput.map((slide) => {
                return (
                  <CarouselCard
                    key={slide.id}
                    img={slide.url}
                    name={slide.name}
                  />
                );
              })}
            </Carousel>
            <Typography
              sx={classes.featureImageText}
              variant="caption_old_font"
              color="primary"
            >
              {t('POST-POP-UP-FEATURED_IMAGE')}
            </Typography>
          </section>
        </>
      ) : null}
      {/* TO DO: Allow attachment uploading and skill tag editing during edit mode, remove div below */}
      <div
        style={
          type == 'boardMeeting' ? classes.remove : { marginBottom: '15px' }
        }
      >
        <FileUploader resultFiles={setAttachmentsArray} />
        <div style={editMode ? classes.remove : classes.skillsRow}>
          <AutocompleteSkills
            onChange={handleAdvisorSkillsAutocompleteChange}
            getOptionLabel={(option) => option.label}
            id="questionSkills"
            input-data-cy="questionSkills"
            labelBox={t('POP-UP-ADD-SKILLS-TAGS')}
            limitTags={3}
            options={tags.skills}
            placeholder={t('STEP1-ADVISOR-SKILLS-SELECT-PLACEHOLDER')}
          />
          <div style={classes.skillListContainer}>
            {skillsArray ? (
              <ul style={classes.skillList}>
                {skillsArray.map((data) => {
                  return (
                    <li style={{ float: 'left' }} key={data.label}>
                      <Chip
                        label={data.label}
                        variant="outlined"
                        color="primary"
                        onDelete={() => deleteSkillsElement(data.label)}
                        sx={classes.chip}
                        deleteIcon={<Clear fontSize="small" />}
                        size="small"
                        classes={{
                          outlinedPrimary: classes.chipColor,
                          iconSmall: classes.icon,
                        }}
                      />
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </div>
        </div>
      </div>
      {!mobileDisplay && (
        <>
          <div style={classes.buttonGroup}>
            <div style={classes.editButtonsContainer}>
              <div
                style={
                  openVideo || type === 'boardMeeting'
                    ? classes.remove
                    : classes.addMargin
                }
              >
                <ACButton
                  color="primary"
                  size="medium"
                  variant="outlined"
                  onClick={() => {
                    setOpenVideo(true);
                  }}
                >
                  <OndemandVideo sx={classes.iconVideo} />
                  <div style={classes.buttonVideoContainer}>
                    <Typography style={{ fontWeight: 'bold' }}>
                      add Video
                    </Typography>
                  </div>
                </ACButton>
              </div>
              <div style={openVideo ? classes.inputVideo : classes.remove}>
                <Clear
                  onClick={() => {
                    setOpenVideo(false);
                    setEmbededUrl(false);
                    setVideoUrl('');
                    setVideoUrlError(false);
                  }}
                  sx={classes.closeIcon}
                />
                <div>
                  <TextField
                    id="urlInput"
                    size="small"
                    onChange={(e) => {
                      setVideoUrl(e.target.value);
                      validateVideoUrl(e.target.value);
                    }}
                    variant="outlined"
                    placeholder="Youtube or Vimeo URL"
                    value={videoUrl}
                    error={videoUrlEror}
                    helperText={videoUrlEror}
                    sx={classes.videoUrlField}
                  />
                </div>
              </div>
            </div>
            {editMode || type === 'boardMeeting' ? (
              editButtons()
            ) : (
              <CompanyPostButton
                buttonColor="primary"
                buttonSize="medium"
                buttonVariant={type === 'update' ? 'outlined' : ''}
                type={type === 'question' ? 'question' : 'update'}
                onClick={() => makePostSelector()}
                loading={isLoading}
                isDisabled={disablePostButton()}
              />
            )}
          </div>
          <div style={classes.warningRow}>
            {showWarningMessage ? (
              <Typography sx={classes.warningText} variant="caption_old_font">
                {t('POST-POP-UP-WARNING')}
              </Typography>
            ) : null}
          </div>
        </>
      )}
      {embededUrl ? (
        <>
          <div style={classes.videoPreview}>
            <iframe
              src={embededUrl}
              frameBorder="0"
              allowFullScreen
              title="previewVideo"
              style={classes.videoFrame}
            />
          </div>
        </>
      ) : null}
    </div>
  );
}

export default PostPopUp;
