import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import FeaturedBoardTile from './FeaturedBoardTile';
import HelperTile from './HelperTile';

const useStyles = (isSM) => ({
  tilesContainer: {
    display: 'flex',
    displayDirection: 'row',
    flexDirection: isSM ? 'column' : '',
    width: '100%',
    marginBottom: '20px',
    justifyContent: 'space-between',
  },
});

const TYPES = {
  dashboard: 'DASHBOARD',
  meetings: 'MEETINGS',
  search: 'SEARCH',
  boardroom: 'BOARDROOM',
  networking: 'NETWORKING',
};

function AdvisorTopTiles(props) {
  const { type, cardId, tileTitle, tileText } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isLessThan1040px = useMediaQuery((theme) =>
    theme.breakpoints.down([1040])
  );
  const classes = useStyles(isSM);
  const { t } = useTranslation();

  return (
    <div style={classes.tilesContainer}>
      <FeaturedBoardTile
        topText={t('FEATURED-ADVISORY-BOARD')}
        bottomText={type == TYPES.search ? undefined : t('VIEW-ADVISORY-BOARD')}
      />
      <HelperTile
        title={tileTitle}
        text={tileText}
        cardId={cardId}
        isLessThan1040px={isLessThan1040px}
        cursor={type == TYPES.search ? undefined : 'default'}
      />
    </div>
  );
}
export default AdvisorTopTiles;
