import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Typography, useMediaQuery, Grid } from '@mui/material';
import AdvisorStepper from '../../components/Advisor/Onboarding/AdvisorStepper';
import Step2Form from '../../components/Advisor/Onboarding/Step2/Step2Form';
import CardScene from '../../components/CardScene';

const useStyles = (isSM) => ({
  formContainer: {
    padding: isSM ? '30px 0px 0px' : '30px 20px 20px',
    backgroundColor: isSM && 'white',
  },
  formCard: {
    width: '800px',
  },
  stepSubtitle: {
    marginBottom: '5px',
    marginTop: '15px',
    fontWeight: 600,
    fontSize: '24px',
    padding: isSM ? '0 15px' : 'inherit',
  },
  stepDescription: {
    color: '#646D7A',
    marginBottom: '5px',
    marginTop: '15px',
    fontWeight: 400,
    fontSize: '14px',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
});

function step2(props) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { t } = useTranslation();

  const stepperHeader = () => {
    return (
      <div style={classes.headerContainer}>
        <Grid container direction="column">
          <Grid item>
            <Typography
              display="block"
              component="div"
              sx={classes.stepSubtitle}
              variant="h1"
              align="center"
            >
              {t('STEP2-ADVISOR-SUBTITLE')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              display="block"
              component="div"
              sx={classes.stepDescription}
              align="center"
            >
              {t('STEP2-ADVISOR-DESCRIPTION')}
            </Typography>
          </Grid>
          {!isSM && <AdvisorStepper activeStep={0} />}
        </Grid>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>{t('ONBOARDING-HELMET-TITLE')}</title>
      </Helmet>

      <CardScene header={stepperHeader()} isAdvisorOnboarding step={0}>
        <div style={classes.formContainer}>
          <Step2Form {...props} isSM={isSM} />
        </div>
      </CardScene>
    </>
  );
}

export default step2;
