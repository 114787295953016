import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Card, Grid, useMediaQuery, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import ACSnackbar from '../../components/ACSnackbar';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Form from './Form';
import LoginImage from './Group_37__1_.png';
import { Transitions } from '../../services/Transition';
import { useAccount } from '../../contexts/Account';

const useStyles = ({ isSM }) => ({
  rightContainer: {
    backgroundColor: 'white',
    backgroundSize: 'cover',
    height: '100%',
    color: 'black',
    padding: `30px 50px 85px ${isSM ? 45 : 50}px`,
  },
  upperContainer: {
    display: 'flex',
    height: '100%',
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-center',
  },
  futureTitleContainer: {
    textAlign: 'center',
  },
  futureTitleText: {
    fontWeight: 'bold',
    fontSize: '225%',
  },
  EngageTitleContainer: {
    marginTop: '15px',
    textAlign: 'center',
  },
  BoardRoomSubtitleContainer: {
    marginTop: '10px',
    textAlign: 'center',
  },
  textAroundButton: {
    fontWeight: 'bold',
    fontStyle: 'italic',
    display: 'inline',
    color: 'black',
  },
  textButton: {
    display: 'inline',
    textDecorationLine: 'underline',
    cursor: 'pointer',
    '&:hover': {
      borderColor: 'black',
      borderWidth: '2px',
    },
  },
});

const StyledCard = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '3vh',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
})(Card);

function Login(props) {
  const location = useLocation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isLG = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const classes = useStyles({ isSM, isLG });
  const { t } = useTranslation();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const queryParamsState = new URLSearchParams(location.state);
  const { isAuthenticated } = useAccount();

  function handleClose() {
    setShowSnackbar(false);
  }

  function handleButtonClick() {
    window.location.replace('https://advisorycloud.com/get-started-free');
  }

  const [updateSuccess] = useState(() => {
    const isPasswordUpdated =
      queryParamsState.get('passwordUpdated') ===
      Transitions.Types.PASSWORD_UPDATED;

    if (isPasswordUpdated) {
      return Transitions.Types.PASSWORD_UPDATED;
    }
    return null;
  });

  useEffect(() => {
    if (updateSuccess === Transitions.Types.PASSWORD_UPDATED) {
      setShowSnackbar(true);
      window.scrollTo(0, 0);
      props.history.replace(location.pathname, null);
    }
    if (isAuthenticated) {
      props.history.push('/dashboard');
    }
  }, []);

  const getMaxWidth = () => {
    if (isSM) {
      return '290px';
    }
    if (isLG) {
      return '65vh';
    }
    return '400px';
  };

  return (
    <>
      <ACSnackbar
        open={showSnackbar}
        duration={10000}
        text={t('UPDATE_SUCCESSFUL')}
        severity="success"
        onClose={handleClose}
      />
      <Helmet>
        <title>{t('LOGIN_TITLE')}</title>
        <meta name="description" content={t('LOGIN_HELMET_META')} />
      </Helmet>

      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={6} lg={6} width="100%">
          <Form />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div
            data-testid="login-right-container"
            style={classes.rightContainer}
          >
            <Grid container direction="column" alignItems="center">
              <Grid item xs={12} md={12} lg={12}>
                <Trans
                  t={t}
                  i18nKey="BECOME-ADVISOR"
                  components={{
                    typo: (
                      <Typography
                        color="textSecondary"
                        sx={classes.textAroundButton}
                        variant="body1"
                      />
                    ),
                    btn: (
                      <Typography
                        sx={classes.textButton}
                        onClick={handleButtonClick}
                        variant="body1"
                      />
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <div style={classes.upperContainer}>
              <Grid container justify="center" sx={classes.gridContainer}>
                <StyledCard elevation={0}>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    sx={classes.futureTitleContainer}
                  >
                    <Grid item lg={8}>
                      <Typography variant="h1" sx={classes.futureTitleText}>
                        {t('FUTURE-BOARDROOM')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    sx={classes.EngageTitleContainer}
                  >
                    <img
                      style={{
                        maxWidth: getMaxWidth(),
                      }}
                      src={LoginImage}
                      alt="Future of Boardroom"
                    />
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    sx={classes.EngageTitleContainer}
                  >
                    <Grid item lg={9}>
                      <Typography variant="h4" style={{ fontSize: '130%' }}>
                        {t('ENGAGE-BOARDROOM')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    sx={classes.BoardRoomSubtitleContainer}
                  >
                    <Grid item lg={8}>
                      <Typography variant="h5" style={{ fontSize: '105%' }}>
                        {t('LOGIN-BOARDROOM')}
                      </Typography>
                    </Grid>
                  </Grid>
                </StyledCard>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default Login;
