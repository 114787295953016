import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import ACAvatar from './../../ACAvatar';
import { ReactComponent as ExpandCircleIcon } from './expand-circle-down.svg';
import Menu from './Menu';
import eventBus, { eventBusValues } from './../../../eventBus';
import CustomIcon from './../../../icons/customIcon';

const useStyles = ({ isSM2 }) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '40px',
    height: '50px',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  backDrop: {
    zIndex: 0,
    backgroundColor: 'transparent',
  },
  image: {
    width: '35px',
    height: '35px',
    borderRadius: '35px',
  },
  icon: {
    color: isSM2 ? 'transparent' : '#232B35',
    minWidth: '20px',
    minHeight: '20px',
    marginRight: '3px',
  },
});

function AdvisorAvatarMenuButton({ onAvatarMenuIconCLick, image }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isSM2 = useMediaQuery((theme) => theme.breakpoints.down([1090]));
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [drawerIsOnEditingMode, setDrawerIsOnEditingMode] = useState(false);
  const classes = useStyles({ isSM2 });

  function handleButtonClick(event) {
    if (!isSM) {
      if (!drawerIsOnEditingMode) {
        eventBus.dispatch(eventBusValues.closeAdvisorDrawer);
        setAnchorEl(event.currentTarget);
        setIsMenuVisible((prevValue) => {
          return !prevValue;
        });
      } else {
        eventBus.dispatch(eventBusValues.drawerTryToCloseOnEdit);
      }
    }
  }

  function renderIcon() {
    if (isSM) {
      return null;
    }
    return <ExpandCircleIcon style={classes.icon} />;
  }

  useEffect(() => {
    eventBus.on(eventBusValues.triggerCloseBlocker, (data) => {
      setDrawerIsOnEditingMode(data);
    });

    return () => {
      eventBus.remove(eventBusValues.triggerCloseBlocker);
    };
  }, []);

  return (
    <>
      <button
        style={classes.container}
        type="button"
        onClick={() => {
          if (isSM) {
            onAvatarMenuIconCLick();
          }
        }}
        onMouseOver={handleButtonClick}
      >
        {renderIcon()}
        {isSM ? (
          <CustomIcon
            id="advisorHamburgerMobileMenu"
            iconname="hamburger"
            style={{
              fontSize: 15,
              width: '3em',
              height: '21px',
            }}
          />
        ) : (
          <ACAvatar image={image} size="md" avatarClick={() => {}} />
        )}
      </button>
      <Menu
        anchorEl={anchorEl}
        open={isMenuVisible}
        hideMenu={() => {
          setIsMenuVisible(false);
        }}
      />
    </>
  );
}

export default AdvisorAvatarMenuButton;
