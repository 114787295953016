import React from 'react';
import { Paper, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as Building1 } from '../../icons/building_1.svg';
import { useLocation } from 'react-router-dom';

const getClasses = ({ isSM, cursor = 'pointer', location }) => ({
  tile: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: isSM ? '100%' : '49%',
    height: '238px',
    borderRadius: '16px',
    padding: '8px',
    marginBottom: isSM ? '10px' : '',
    backgroundColor: '#F5F7FA',
    cursor,
    placeContent:
      location.pathname !== '/board-directory' ? 'center' : 'initial',
  },
  tileTitleMarginLeft: {
    fontWeight: 600,
    fontSize: '24px',
    marginLeft: '20px',
    marginBottom: '20px',
    marginTop: location.pathname == '/board-directory' ? '40px' : '0px',
  },
  tileTitleMarginLeftStyle: {
    fontWeight: 600,
    fontSize: '24px',
    fontFamily: 'Poppins',
  },
  tileText: {
    fontWeight: 400,
    fontSize: '18px',
    marginLeft: '20px',
  },
  tileTextStyle: {
    fontWeight: 400,
    fontSize: '18px',
    fontFamily: 'Poppins',
  },
  buildingIconContainer: {
    position: 'absolute',
    right: '0px',
    bottom: '0px',
    lineHeight: '0px',
  },
  secondTileText: {
    fontWeight: 400,
    fontSize: isSM ? '13px' : '18px',
    marginTop: isSM ? '15px' : '30px',
    fontFamily: 'Poppins',
  },
});

function HelperTile(props) {
  const { title, text, cardId, cursor, takeTour } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const location = useLocation();
  const classes = getClasses({ isSM, cursor, location });

  return (
    <Paper elevation={0} sx={classes.tile} id={cardId}>
      <Typography
        sx={classes.tileTitleMarginLeft}
        style={classes.tileTitleMarginLeftStyle}
      >
        {title}
      </Typography>
      <Typography sx={classes.tileText} style={classes.tileTextStyle}>
        {text}
        {takeTour && (
          <Typography style={classes.secondTileText}>
            {'Take the tour >'}
          </Typography>
        )}
      </Typography>

      <div style={classes.buildingIconContainer}>
        <Building1 />
      </div>
    </Paper>
  );
}
export default HelperTile;
