import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { Box, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import Alarm from '@mui/icons-material/Alarm';
import LoadingIndicatorLogo from './../../LoadingIndicatorLogo';
import { useLocation } from 'react-router-dom';
import DefaultAdvisorAvatar from './../../../icons/user-avatar.svg';
import CompanyDetailGrid from './../DetailGrid';
import { isNil } from './../../../services/utils';
import { useAccount } from './../../../contexts/Account';
import { isDateExpired } from './../../../services/date';
import eventBus, { eventBusValues } from './../../../eventBus';
import {
  getCompanyBySlug,
  getCompanyInfoBoardroomMenu,
} from './../../../services/company_services';
import BoardSkeleton from '../../SkeletonLoading/boardSkeleton';
import BuildYourOwnBoardTile from './BuildYourOwnBoardTile';

const useStyles = () => ({
  removed: {
    display: 'none',
  },
  advisorDetailsCardContainer: {
    marginBottom: '10px',
    marginRight: '0px',
    maxWidth: '380px',
  },
  infinteScrollContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerLoading: {
    height: '150px',
  },
});

function CompanyListGrid(props) {
  const {
    onNoAdvisorsFound,
    companiesList,
    isLoading,
    incrementOffset,
    isMainCallLoading,
    doNotInfiniteScroll,
    isCompanyCard,
    isBoardSekeleton,
    emptySearchParams,
    lastFetch,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [boardAdvisorsMap, setBoardAdvisorsMap] = useState();
  const [invitedAdvisorsMap, setInvitedAdvisorsMap] = useState();
  const location = useLocation();
  const { accountDetails } = useAccount();
  const companiesListFiltered = useMemo(() => {
    if (
      location.pathname.includes('/advisors') &&
      !location.pathname.includes('/advisors/')
    ) {
      return companiesList.filter(
        (company) =>
          !company.freeTrialEndsOn &&
          ['active', 'new'].includes(company.accountStatus)
      );
    }
    if (location.pathname.includes('/board-directory')) {
      const companiesToDisplay = [...companiesList];
      const dummyTile = {
        id: 'empty',
        buildYourBoard: true,
      };
      if (emptySearchParams && companiesToDisplay.length > 4) {
        const notFavoriteFirstIndex = companiesToDisplay.findIndex(
          (company) => !company.isFavorite
        );
        companiesToDisplay.splice(notFavoriteFirstIndex + 4, 0, dummyTile);
      } else if (
        (lastFetch || companiesList.length % 12 > 0) &&
        !emptySearchParams
      ) {
        companiesToDisplay.push(dummyTile);
      }

      return companiesToDisplay;
    }
    return companiesList;
  }, [companiesList, lastFetch, emptySearchParams]);
  const loader = useRef();
  let firstCompanyWithMeeting = -1;
  const clickedDetailCardMetric =
    location.pathname !== '/advisors' &&
    location.pathname !== '/advisors/search' &&
    location.pathname !== '/advisors/search'
      ? location.hash.substring(1) || 'answer'
      : false;
  const individualAdvisorPublicUrl = clickedDetailCardMetric
    ? location.pathname.substring(10)
    : false;
  const selectedAdvisor = clickedDetailCardMetric
    ? companiesList.find(
        ({ publicProfileUrl }) =>
          publicProfileUrl === individualAdvisorPublicUrl
      )
    : false;

  function handleButtonClick(company) {
    eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
      company,
      hidePostSection: false,
      companiesListFiltered,
    });
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('invite')) {
      const companySample = accountDetails.boards.invitedBoards.find(
        (sample) => sample.slug === queryParams.get('invite')
      );
      if (companySample) {
        const company = companiesList.find(
          (item) => item.id === companySample.id
        );
        if (company) {
          handleButtonClick(company);
        } else {
          getCompanyInfoBoardroomMenu({ companyId: companySample.id })
            .then(({ data }) => {
              handleButtonClick(data.getCompany);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    }

    if (queryParams.get('view')) {
      const company = companiesList.find(
        (item) => item.slug === queryParams.get('view')
      );
      if (company) {
        console.log('Trigger Drawer', company);
        handleButtonClick(company);
      } else {
        getCompanyBySlug({ slug: queryParams.get('view') }).then(({ data }) => {
          handleButtonClick(data.getCompany);
        });
      }
    }
  }, []);

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting && !isNil(incrementOffset)) {
        if (
          !isMainCallLoading &&
          (isNil(doNotInfiniteScroll) || !doNotInfiniteScroll)
        ) {
          incrementOffset();
        }
      }
    },
    [isMainCallLoading]
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver, isMainCallLoading]);

  function getUpcomingBoardMeetingValue(
    upcomingBoardMeeting,
    returnString = false
  ) {
    if (!isNil(upcomingBoardMeeting)) {
      if (!isDateExpired(upcomingBoardMeeting)) {
        if (returnString) {
          const meetingText = `Upcoming Meeting`;
          return meetingText;
        }
        return true;
      }
    }
    return false;
  }

  function getMarginRight(index) {
    if (isSM) {
      if (index % 2 === 1) {
        return '0px';
      }
      return '16px';
    }
    if (index % 3 === 2) {
      return '0px';
    }
    return '16px';
  }

  if (onNoAdvisorsFound && !isLoading && companiesList.length === 0) {
    onNoAdvisorsFound();
  }

  if (!invitedAdvisorsMap) {
    const newInvitedAdvisorsMap = {};
    const { invitedAdvisors } = accountDetails;

    if (!invitedAdvisors) {
      setInvitedAdvisorsMap({});
      return null;
    }

    for (let i = 0; i < invitedAdvisors.length; i += 1) {
      newInvitedAdvisorsMap[invitedAdvisors[i].id] = {
        inviteDate: invitedAdvisors[i].inviteDate,
      };
    }

    setInvitedAdvisorsMap(newInvitedAdvisorsMap);
    return null;
  }

  if (!boardAdvisorsMap) {
    const newBoardAdvisorsMap = {};
    const { boardAdvisors } = accountDetails;

    if (!boardAdvisors) {
      setBoardAdvisorsMap({});
      return null;
    }

    for (let i = 0; i < boardAdvisors.length; i += 1) {
      newBoardAdvisorsMap[boardAdvisors[i].id] = {
        joinedOnDate: boardAdvisors[i].joinedOnDate,
      };
    }

    setBoardAdvisorsMap(newBoardAdvisorsMap);
    return null;
  }

  if (isCompanyCard) {
    firstCompanyWithMeeting = companiesList.findIndex((company) =>
      getUpcomingBoardMeetingValue(company.upcomingBoardMeeting)
    );
  }

  return (
    <>
      <div
        style={
          clickedDetailCardMetric && selectedAdvisor ? classes.removed : {}
        }
      >
        <Grid container>
          {companiesListFiltered.length > 0 &&
            companiesListFiltered.map((company, index) => {
              return (
                <Grid key={company.id} item xxs={12} xs={6} sm={6} md={4}>
                  <Box
                    key={company.id}
                    sx={{
                      marginLeft: { xxs: '10px', xs: '0px' },
                      marginBottom: '10px',
                      marginRight: getMarginRight(index),
                      maxWidth: '380px',
                    }}
                  >
                    {!company.buildYourBoard ? (
                      <CompanyDetailGrid
                        key={company.id}
                        company={company}
                        advisorAvatar={
                          company.image.id === null
                            ? DefaultAdvisorAvatar
                            : `https://${company.image.location}`
                        }
                        cardClick={() => handleButtonClick(company)}
                        meetingHighlighter={getUpcomingBoardMeetingValue(
                          company.upcomingBoardMeeting
                        )}
                        meetingHighlighterType={getUpcomingBoardMeetingValue(
                          company.upcomingBoardMeeting,
                          true
                        )}
                        upcomingBoardMeeting={company.upcomingBoardMeeting}
                        MeetingHighlighterCustomIcon={<Alarm />}
                        addtopclasses={index === 0 ? 'true' : null}
                        boardMeetingStaticClasses={
                          index === firstCompanyWithMeeting
                        }
                      />
                    ) : (
                      <BuildYourOwnBoardTile />
                    )}
                  </Box>
                </Grid>
              );
            })}
        </Grid>
        <div style={classes.footerLoading}>
          {isMainCallLoading &&
            (isNil(doNotInfiniteScroll) || !doNotInfiniteScroll) && (
              <>
                {isBoardSekeleton ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: {
                        xxs: 'center',
                        sm: 'space-around',
                      },
                      flexWrap: 'wrap',
                      gap: 2,
                    }}
                  >
                    <BoardSkeleton />
                    <BoardSkeleton />
                    <BoardSkeleton />
                  </Box>
                ) : (
                  <div style={classes.infinteScrollContainer}>
                    <LoadingIndicatorLogo
                      size={100}
                      iconFontSize={47}
                      iconRight={49}
                    />
                  </div>
                )}
              </>
            )}
        </div>

        <div ref={loader} />
      </div>
    </>
  );
}
export default CompanyListGrid;
