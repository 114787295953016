import React from 'react';
import { useMediaQuery } from '@mui/material';

const useStyles = (isSM) => ({
  container: {
    paddingLeft: isSM ? '10px' : '20px',
    paddingRight: isSM ? '10px' : '20px',
    paddingTop: '5px',
    paddingBottom: '5px',
    marginTop: '10px',
    border: 'solid',
    borderWidth: '2px',
    backgroundColor: '#F5F7FA',
    borderColor: '#F5F7FA',
    color: 'black',
  },
});

function EmptyContainer({ rounded, width, height }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);

  return (
    <div
      style={{
        ...classes.container,
        width,
        borderRadius: rounded ? '25px' : '5px',
        height,
      }}
    />
  );
}

export default EmptyContainer;
