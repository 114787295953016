import React, { useEffect, useState } from 'react';
import { Avatar, Paper, Typography, useMediaQuery } from '@mui/material';
import { getFeaturedCompany } from '../../services/advisor_services';
import { useAccount } from '../../contexts/Account';
import { ReactComponent as Building1 } from '../../icons/building_1.svg';
import eventBus, { eventBusValues } from '../../eventBus';

const getClasses = ({ isSM, backgroundColor = '#D7F1FD' }) => ({
  tile: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: isSM ? '100%' : '49%',
    height: '238px',
    borderRadius: '16px',
    padding: '8px',
    marginBottom: isSM ? '10px' : '',
    cursor: 'pointer',
    backgroundColor,
  },
  topText: {
    fontWeight: 500,
    fontSize: '12px',
    position: 'absolute',
    top: '21px',
    right: '18px',
    color: '#74879A',
  },
  topTextStyle: {
    fontWeight: 500,
    fontSize: '12px',
    top: '21px',
    color: '#74879A',
  },
  titleContainer: {
    display: 'flex',
    marginTop: '40px',
    marginLeft: '20px',
    alignItems: 'center',
  },
  tileTitle: {
    fontWeight: 600,
    fontSize: '24px',
    fontFamily: 'Poppins',
  },
  tileText: {
    fontWeight: 400,
    fontSize: '18px',
    marginLeft: '20px',
    fontFamily: 'Poppins',
  },
  bottomText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '18px',
    textAlign: 'end',
    position: 'absolute',
    bottom: '20px',
    right: '20px',
  },
  windowIconContainer: {
    position: 'absolute',
    right: '0px',
    bottom: '0px',
    lineHeight: 0,
  },
  txtStyle: {
    fontWeight: 400,
    fontSize: '18px',
    fontFamily: 'Poppins',
  },
});

function FeaturedBoardTile(props) {
  const { topText, bottomText, backgroundColor } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = getClasses({ isSM, backgroundColor });
  const [featuredBoard, setFaturedBoard] = useState({
    id: null,
    title: 'Loading...',
    headline: '',
    logo: null,
  });
  const { accountDetails } = useAccount();

  function getHeadline(headline) {
    if (!headline || headline.length <= 80) {
      return headline;
    }
    return `${headline.slice(0, 80)}...`;
  }

  useEffect(() => {
    let isMounted = true;
    getFeaturedCompany({ ADVISOR_ID: accountDetails.id }).then(({ data }) => {
      if (isMounted && data.getFeaturedCompany) {
        setFaturedBoard({
          id: data.getFeaturedCompany.id,
          title: data.getFeaturedCompany.displayName,
          headline: data.getFeaturedCompany.headline,
          logo: data.getFeaturedCompany.image
            ? 'https://' + data.getFeaturedCompany.image.location
            : null,
        });
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Paper
      elevation={0}
      sx={classes.tile}
      id="featuredBoardTile"
      onClick={() => {
        eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
          companyId: featuredBoard.id,
          hidePostSection: false,
        });
      }}
    >
      <Typography sx={classes.topText} style={classes.topTextStyle}>
        {topText}
      </Typography>
      <div style={classes.titleContainer}>
        {featuredBoard.logo && (
          <Avatar
            alt="Company Logo"
            src={featuredBoard.logo}
            style={{ width: 50, height: 50, marginRight: 10 }}
          />
        )}
        <Typography style={classes.tileTitle}>{featuredBoard.title}</Typography>
      </div>
      <Typography
        sx={classes.tileText}
        style={{ ...classes.tileText, marginTop: 10 }}
      >
        {!isSM ? featuredBoard.headline : getHeadline(featuredBoard.headline)}
      </Typography>
      <Typography
        sx={classes.bottomText}
        style={{ ...classes.txtStyle, marginTop: 6 }}
      >
        {bottomText}
      </Typography>
      <div style={classes.windowIconContainer}>
        <Building1 />
      </div>
    </Paper>
  );
}
export default FeaturedBoardTile;
