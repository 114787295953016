import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import WebFont from 'webfontloader';
import { Amplify, Auth } from 'aws-amplify';
import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles';
import awsconfig from './services/Amplify/aws-exports';
import { AccountProvider } from './contexts/Account';
import Router from './Router';
import storage from './storage';
import { ACTheme as ACThemeV5 } from './themes';
import CssBaselineV5 from '@mui/material/CssBaseline';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';

WebFont.load({
  google: {
    families: ['Poppins:300,400,500,600,700,800,900'],
  },
});
Amplify.configure(awsconfig);
Auth.configure({
  storage,
});

function App() {
  return (
    <ThemeProviderV5 theme={ACThemeV5}>
      <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY}>
        <Elements>
          <CssBaselineV5 />
          <AccountProvider>
            <Helmet
              defaultTitle={process.env.REACT_APP_APPLICATION_NAME}
              titleTemplate={process.env.REACT_APP_APPLICATION_TITLE_TEMPLATE}
            />
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </AccountProvider>
        </Elements>
      </RecurlyProvider>
    </ThemeProviderV5>
  );
}

export default App;
