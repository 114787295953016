import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Paper,
  Typography,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import AdvisoryCloudLogo from './../../AdvisoryCloudLogo';
import DefaultCompany from './../../../icons/building_round.svg';
import DefaultAdvisorAvatar from './../../../icons/user-avatar.svg';
import {
  getCompanyInfoBoardroomMenu,
  getSinglePostCall,
} from './../../../services/company_services';
import { AccountType } from './../../../services/utils/types';
import {
  getRecentlyMessagedBoardMembers,
  addBoardView,
  getAdvisorRecurlyPlan,
} from './../../../services/advisor_services';
import { getAdvisor } from './../../../services/Backend/account';
import { useAccount } from './../../../contexts/Account';
import eventBus, { eventBusValues } from './../../../eventBus';
import WelcomePopUp from './../../Post/JoinPopUp/welcomePopUp';
import ActionButtons from './actions';
import CardBoard from './boards';
import { featureFlags } from './../../../splitSetup';
import Treatment from './../../Treatment';

const useStyles = ({ isSM, isFreeTrial, showBanner }) => ({
  outerContainer: {
    minWidth: isSM ? '0px' : '280px',
    maxWidth: isSM ? '0px' : '350px',
    width: isSM ? '0px' : 'calc(20% + 3px)',
    overflow: 'auto',
  },
  container: {
    minWidth: '280px',
    maxWidth: '350px',
    paddingLeft: '20px',
    paddingRight: '28px',
    borderRadius: '0px',
    textAlign: 'left',
    display: isSM ? 'none' : 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20%',
    paddingTop: '30px',
    top: isFreeTrial ? '50px' : showBanner ? '30px' : '0px',
    position: 'fixed',
    overflowY: 'hidden',
    // '&:hover': {
    //   paddingRight: haveScroll ? (isFirefox ? '13px' : '20px') : '28px',
    //   overflowY: 'auto',
    // },
    backgroundColor: '#FBFCFD',
    minHeight: isFreeTrial
      ? 'calc(100vh - 50px)'
      : showBanner
      ? 'calc(100vh - 30px)'
      : '100vh',
    maxHeight: isFreeTrial
      ? 'calc(100vh - 50px)'
      : showBanner
      ? 'calc(100vh - 30px)'
      : '100vh',
    '&::-webkit-scrollbar': {
      width: '8px',
      backgroundColor: '#F8F8F8',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: '#C7C7C7',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#B7B7B7',
    },
    '&::-webkit-scrollbar-thumb:active': {
      backgroundColor: '#B7B7B7',
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
    },
  },
  avatarContainer: {
    marginTop: '70px',
    display: isSM ? 'none' : 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#FBFCFD',
    width: '100%',
    borderRadius: '12px',
    padding: '20px 15px',
    cursor: 'pointer',
  },
  imageWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  ownerImageWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: isSM ? '20px' : '35px',
  },
  logoContainer: {
    position: 'absolute',
    top: '-5px',
  },
  userImage: {
    width: '146px',
    height: '146px',
    maxHeight: '165px',
    maxWidth: '165px',
    borderRadius: '146px',
    marginBottom: '10px',
    cursor: 'pointer',
  },
  dialogCompanyImage: {
    width: '73px',
    height: '73px',
    maxHeight: '73px',
    maxWidth: '73px',
    borderRadius: '146px',
    marginBottom: '10px',
    cursor: 'pointer',
  },
  dialogContent: {
    maxWidth: '450px',
    overflow: 'hidden',
    maxHeight: isSM ? null : '400px',
    marginTop: isSM ? '0px' : '-30px',
  },
  dialogOwnerImage: {
    width: '60px',
    height: '60px',
    maxHeight: '60px',
    maxWidth: '60px',
    borderRadius: '146px',
    marginRight: '20px',
    cursor: 'pointer',
  },
  displayName: {
    color: '#232B35',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.75px',
    cursor: 'pointer',
  },
  dialogContainer: {
    padding: '10px',
  },
  dialogContainerPaper: {
    borderRadius: '15px',
  },
  dialogActions: {
    justifyContent: 'center',
    marginTop: isSM ? '5px' : '',
    marginBottom: isSM ? '-10px' : '',
    paddingBottom: isSM ? '20px' : '',
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    justifyContent: 'center',
    width: isSM ? '' : '485px',
    height: '524px',
    alignItems: 'center',
    background: '#FFFFFF',
    borderRadius: '15px',
  },
  dialogText: {
    textAlign: 'center',
  },
  dialogTextCompanyName: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    maxWidth: '250px',
  },
  dialogTextCompanyNameContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  boardMembers: {
    fontFamily: 'Poppins',
    fontSize: '13px',
    marginTop: '20px',
    fontWeight: 500,
    letterSpacing: '0.75px',
    paddingLeft: '21px',
    alignSelf: 'flex-start',
  },
  headline: {
    textAlign: 'center',
    wordWrap: 'break-word',
    color: '#1C2F49',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '10px',
    lineHeight: '15px',
    letterSpacing: '0.75px',
    marginBottom: '10px',
    paddingTop: '10px',
    cursor: 'pointer',
  },
  title: {
    color: '#74879A',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '0.75px',
    marginBottom: '10px',
    marginTop: '4px',
  },
  dialogBoardOwnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

function MemberItem({ advisorId, currentId, companyName }) {
  const [advisorData, setAdvisorData] = useState(false);

  useEffect(() => {
    let isMounted = true;
    function getAdvisorData() {
      getAdvisor(advisorId)
        .then((response) => {
          if (isMounted) {
            if (
              response.data.getAdvisor.accountStatus === 'active' &&
              response.data.getAdvisor.image
            ) {
              setAdvisorData(response.data.getAdvisor);
            }
          }
        })
        .catch(() => {});
    }
    if (advisorId !== currentId) {
      getAdvisorData();
    }
    return () => {
      isMounted = false;
    };
  }, [advisorId]);

  if (advisorData) {
    return (
      <CardBoard
        onClick={() => visitProfile(advisorData)}
        logo={
          advisorData.image && advisorData.image.imageKey
            ? `${process.env.REACT_APP_IMAGES_URL}${advisorData.image.imageKey}`
            : ''
        }
        memberName={advisorData.displayName}
        companyName={companyName}
        boardMember={advisorData}
        memberType={AccountType.ADVISOR}
        status="member"
        key={advisorData.id}
      />
    );
  }
  return null;
}
function visitProfile(advisor) {
  eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, {
    ...advisor,
    boardRoomView: true,
  });
}

function AdvisorBoardroomsLeftMenu() {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const {
    asLoggedUserDetails,
    accountDetails,
    currentPlan,
    isFreeTrial,
    type,
    currentUserInfo,
  } = useAccount();
  const { salesforceValues } = currentPlan;
  const showBanner =
    !isFreeTrial &&
    salesforceValues.billingCycle === 'Monthly' &&
    ['Essentials', 'Professional', 'Executive'].includes(salesforceValues.tier);
  const ref = useRef();
  const [haveScroll, setHaveScroll] = useState(false);
  const isFirefox = typeof InstallTrigger !== 'undefined';
  const classes = useStyles({ isSM, isFreeTrial, showBanner });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const history = useHistory();
  const { t } = useTranslation();
  const params = useParams('/:companySlug/boardrooms');
  const { companySlug } = params;
  const postParams = useParams('/post/:postId');
  const { postId } = postParams;
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [recentMessagedAdvisors, setRecentMessagedAdvisors] = useState([]);
  const [companyBoardData, setCompanyBoardData] = useState({});
  const [companyFreemium, setCompanyFreemium] = useState(false);
  const [isACPlusCompany, setIsACPlusCompany] = useState(false);
  const [isFirstViewModalVisible, setIsFirstViewModalVisible] = useState(false);
  const [boardAdvisorsData, setBoardAdvisorsData] = useState([]);
  const [myAdvisorJoinedDate, setMyAdvisorJoinedDate] = useState({});
  const [companyBoardOwner, setCompanyBoardOwner] = useState();
  const [openWelcomePopup, setOpenWelcomePopup] = useState(false);

  const wednesday = new Date('May 16, 2023 01:15:00');

  useEffect(() => {
    let isMounted = true;
    function getCompanyData(companyId) {
      getCompanyInfoBoardroomMenu({ companyId: companyId })
        .then((response) => {
          if (isMounted) {
            setCompanyBoardData(response.data.getCompany);
            if (
              response.data.getCompany &&
              response.data.getCompany.recurlyPlanCode
            ) {
              try {
                async function fetchData() {
                  const planResponses = await getAdvisorRecurlyPlan({
                    recurlyPlanCode: response.data.getCompany.recurlyPlanCode,
                  });
                  if (
                    planResponses &&
                    planResponses.data &&
                    planResponses.data.getRecurlyPlan &&
                    planResponses.data.getRecurlyPlan.isFreemium &&
                    planResponses.data.getRecurlyPlan.isFreemium === true
                  ) {
                    setCompanyFreemium(true);
                  }
                  setIsACPlusCompany(
                    planResponses.data?.getRecurlyPlan?.isAcPlus || false
                  );
                }
                fetchData();
              } catch (error) {
                console.log('error:', error);
              }
            }
            const companyContacts = response.data.getCompany.contacts;
            const companyOwner = companyContacts.find((c) => c.isBoardOwner);
            setCompanyBoardOwner(companyOwner || companyContacts[0]);
          }
        })
        .catch((error) => {
          if (isMounted) {
            setShowSnackbar(true);
          }
        });
    }
    let selectedCompany = {};
    if (companySlug) {
      selectedCompany =
        asLoggedUserDetails.advisorRelatedCompanies.memberBoards.find(
          (element) => element.slug === companySlug
        );
      if (queryParams.get('first_view')) {
        setOpenWelcomePopup(true);
      }

      getCompanyData(selectedCompany.id);
      return () => {
        isMounted = false;
      };
    } else if (postId) {
      getSinglePostCall({
        ACCOUNT_TYPE:
          type === AccountType.COMPANY
            ? AccountType.CONTACT
            : AccountType.ADVISOR,
        USER_ID: accountDetails.id,
        POST_ID: postId,
      }).then(function (resp) {
        const post = resp.data.getPost;
        selectedCompany = { id: post.companyId };

        getCompanyData(post.companyId);
        return () => {
          isMounted = false;
        };
      });
    }
  }, [companySlug]);

  function updateDimensions() {
    if (ref && ref.current) {
      setHaveScroll(ref.current.scrollHeight > ref.current.clientHeight);
    }
  }

  function getTextTrimmed(text, maxSize) {
    if (!text) return '';
    if (text.length <= maxSize) {
      return text;
    }
    let trimmedText = text.substring(0, maxSize) + '...';
    return trimmedText;
  }

  useEffect(() => {
    if (Object.keys(companyBoardData).length != 0) {
      if (
        companyBoardData.boardAdvisors &&
        companyBoardData.boardAdvisors.length > 0
      ) {
        setBoardAdvisorsData(companyBoardData.boardAdvisors);
      }
    }
  }, [companyBoardData]);

  useEffect(() => {
    (async () => {
      if (boardAdvisorsData.length > 0) {
        setMyAdvisorJoinedDate(
          companyBoardData.boardAdvisors.find(
            (advisor) => advisor.id == accountDetails.id
          )
        );
        const advisors = await getRecentlyMessagedBoardMembers({
          ID: accountDetails.id,
          BOARD_MEMBERS: boardAdvisorsData.map((advisor) => advisor.id),
        });
        setRecentMessagedAdvisors(
          advisors.data.getRecentlyMessagedBoardMembers
        );
      }
      setHaveScroll(ref.current.scrollHeight > ref.current.clientHeight);
      window.addEventListener('resize', updateDimensions);
    })();
  }, [boardAdvisorsData]);

  useEffect(() => {
    (async () => {
      if (boardAdvisorsData.length > 0) {
        if (
          !accountDetails.viewedBoards ||
          !accountDetails.viewedBoards.includes(companyBoardData.id)
        ) {
          (async () => {
            if (myAdvisorJoinedDate.joinedOnDate > wednesday.toISOString()) {
              setIsFirstViewModalVisible(true);
              try {
                const advisors = await addBoardView({
                  ADVISOR_ID: accountDetails.id,
                  COMPANY_ID: companyBoardData.id,
                });
                if (
                  !accountDetails.viewedBoards ||
                  accountDetails.viewedBoards.length < 1
                ) {
                  accountDetails.viewedBoards = [companyBoardData.id];
                } else {
                  accountDetails.viewedBoards.push(companyBoardData.id);
                }
              } catch (error) {
                console.log('error', error);
              }
            }
          })();
        }
      }
    })();
  }, [myAdvisorJoinedDate]);

  return (
    <>
      <Dialog
        open={isFirstViewModalVisible}
        sx={classes.dialogContainer}
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: 15,
          },
        }}
        onClose={() => setIsFirstViewModalVisible(false)}
      >
        <div style={classes.dialog}>
          <DialogContent sx={classes.dialogContent}>
            <div role="none" style={classes.imageWrapper} onKeyDown={() => {}}>
              <img
                alt=""
                className="profileAvatar"
                style={classes.dialogCompanyImage}
                src={
                  companyBoardData &&
                  companyBoardData.image &&
                  companyBoardData.image.id &&
                  !companyBoardData.image.id.includes('undefined')
                    ? `${process.env.REACT_APP_IMAGES_URL}${companyBoardData.image.id}`
                    : DefaultCompany
                }
              />
            </div>
            <div style={classes.dialogTextCompanyNameContainer}>
              <DialogContentText sx={classes.dialogTextCompanyName}>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: '16px',
                    textTransform: 'none',
                    lineHeight: '24px',
                    fontWeight: 700,
                    color: '#232B35',
                  }}
                >
                  {t('WELCOME-BOARD-FIRST-VIEW-TEXT')}
                </Typography>
                <Typography
                  variant="caption"
                  style={{
                    fontSize: 16,
                    textTransform: 'none',
                    lineHeight: '24px',
                    fontWeight: 700,
                    color: '#232B35',
                  }}
                >
                  {accountDetails.givenName}!
                </Typography>
              </DialogContentText>
            </div>
            <DialogContentText style={classes.dialogText}>
              <Typography
                variant="caption"
                style={{
                  fontSize: '13px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  color: '#232B35',
                }}
              >
                {t('BOARD-FIRST-VIEW-TEXT')}
              </Typography>
            </DialogContentText>
            {companyBoardOwner && (
              <div role="none" style={classes.ownerImageWrapper}>
                <img
                  alt=""
                  style={classes.dialogOwnerImage}
                  src={
                    companyBoardOwner &&
                    companyBoardOwner.image &&
                    companyBoardOwner.image.id &&
                    !companyBoardOwner.image.id.includes('undefined')
                      ? `${process.env.REACT_APP_IMAGES_URL}${companyBoardOwner.image.id}`
                      : DefaultAdvisorAvatar
                  }
                />
                <div style={classes.dialogBoardOwnerContainer}>
                  <div>
                    <Typography
                      variant="caption"
                      style={{
                        fontFamily: 'Poppins',
                        fontSize: 13,
                        fontWeight: 500,
                        color: '#232B35',
                      }}
                    >
                      {companyBoardOwner.displayName}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="caption"
                      style={{
                        fontFamily: 'Poppins',
                        fontSize: 13,
                        fontWeight: 400,
                        color: '#232B35',
                      }}
                    >
                      {`${getTextTrimmed(companyBoardOwner.title, 20)} - `}
                    </Typography>
                    <Typography
                      variant="caption"
                      style={{
                        fontFamily: 'Poppins',
                        fontSize: 13,
                        fontWeight: 400,
                        color: '#232B35',
                      }}
                    >
                      {getTextTrimmed(companyBoardData.displayName, 40)}
                    </Typography>
                  </div>
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions sx={classes.dialogActions}>
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsFirstViewModalVisible(false);
                }}
                className="getStartedButton"
                style={{
                  backgroundColor: '#6736FA',
                  paddingTop: 15,
                  paddingBottom: 15,
                  textTransform: 'none',
                  fontFamily: 'Poppins',
                  fontWeight: 700,
                  fontSize: 15,
                  width: 152,
                  height: 44,
                  borderRadius: 30,
                }}
              >
                Get started
              </Button>
            </>
          </DialogActions>
        </div>
      </Dialog>
      <div style={classes.outerContainer}>
        <Paper
          sx={{
            ...classes.container,
            '&:hover': {
              paddingRight: haveScroll ? (isFirefox ? '13px' : '20px') : '28px',
              overflowY: 'auto',
            },
          }}
          ref={ref}
        >
          {' '}
          <div style={classes.logoContainer}>
            <AdvisoryCloudLogo
              removeHyperlink
              onClick={() => {
                eventBus.dispatch(eventBusValues.closeAdvisorDrawer);
                if (location.pathname !== '/dashboard') {
                  history.push('/dashboard');
                }
              }}
              width={153}
            />
          </div>
          <Box
            sx={classes.avatarContainer}
            onClick={() => {
              eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
                company: companyBoardData,
              });
            }}
          >
            <div role="none" style={classes.imageWrapper} onKeyDown={() => {}}>
              <img
                alt=""
                className="profileAvatar"
                style={classes.userImage}
                src={
                  companyBoardData &&
                  companyBoardData.image &&
                  companyBoardData.image.id &&
                  !companyBoardData.image.id.includes('undefined')
                    ? `${process.env.REACT_APP_IMAGES_URL}${companyBoardData.image.id}`
                    : DefaultCompany
                }
              />
            </div>
            <Typography variant="h4" sx={classes.displayName}>
              {companyBoardData.displayName}
            </Typography>
            <Typography variant="caption" sx={classes.headline}>
              {companyBoardData.headline}
            </Typography>
            <ActionButtons companyBoardData={companyBoardData} />
          </Box>
          {companyBoardOwner && (
            <div
              style={{
                width: '100%',
                paddingBottom: '20px',
              }}
            >
              {companyFreemium && (
                <Typography sx={classes.boardMembers}>Leadership</Typography>
              )}

              <CardBoard
                onClick={() =>
                  eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
                    company: companyBoardData,
                  })
                }
                logo={`${process.env.REACT_APP_IMAGES_URL}${companyBoardOwner.image.id}`}
                isBoardOwner
                memberName={companyBoardOwner.displayName}
                companyName={companyBoardData.displayName}
                boardMember={companyBoardOwner}
                memberType={AccountType.COMPANY}
                status="member"
                key={companyBoardOwner.id}
              />
            </div>
          )}
          {recentMessagedAdvisors &&
            recentMessagedAdvisors.length > 0 &&
            (companyFreemium ? (
              <Typography sx={classes.boardMembers}>Conversations</Typography>
            ) : (
              <Typography sx={classes.boardMembers}>
                Recently Messaged
              </Typography>
            ))}
          <div
            style={{
              width: '100%',
              paddingBottom: '20px',
              backgroundColor: '#FBFCFD',
            }}
          >
            {recentMessagedAdvisors &&
              recentMessagedAdvisors.length > 0 &&
              recentMessagedAdvisors.map((bm) => {
                return bm != null ? (
                  <CardBoard
                    onClick={() => visitProfile(bm)}
                    logo={
                      bm && bm.image && bm.image.imageKey
                        ? `${process.env.REACT_APP_IMAGES_URL}${bm.image.imageKey}`
                        : ''
                    }
                    memberType={AccountType.ADVISOR}
                    memberName={bm.displayName}
                    companyName={companyBoardData.displayName}
                    boardMember={bm}
                    status="member"
                    isRecentlyMessaged
                    key={bm.id}
                  />
                ) : null;
              })}
          </div>
          <div
            style={{
              width: '100%',
            }}
            id="boardMemberList"
          >
            {boardAdvisorsData &&
              boardAdvisorsData.length > 0 &&
              (companyFreemium ? (
                <Typography sx={classes.boardMembers}>
                  AdvisoryCloud Members
                </Typography>
              ) : (
                <Typography sx={classes.boardMembers}>
                  Advisory Board Members
                </Typography>
              ))}
            <div
              style={{
                width: '100%',
                paddingBottom: '50px',
                backgroundColor: '#FBFCFD',
              }}
            >
              {boardAdvisorsData &&
                boardAdvisorsData.length > 0 &&
                boardAdvisorsData.map((bm) => {
                  return (
                    <MemberItem
                      key={bm.id}
                      advisorId={bm.id}
                      currentId={accountDetails.id}
                      companyName={companyBoardData.displayName}
                    />
                  );
                })}
            </div>
          </div>
        </Paper>
      </div>
      <WelcomePopUp
        postJoinModal={openWelcomePopup}
        setPostJoinModal={setOpenWelcomePopup}
        company={companyBoardData}
        advisor={accountDetails}
        companyContact={companyBoardOwner}
        isAcPlus={isACPlusCompany}
      ></WelcomePopUp>
    </>
  );
}

export default AdvisorBoardroomsLeftMenu;
