import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { InputLabel, OutlinedInput, useMediaQuery } from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';

const useStyles = (props) => {
  return {
    passwordComponentContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '15px',
      alignItems: props.isSM ? 'center' : null,
    },
    passwordField: {
      marginTop: '10px',
      width: props.isSM ? '80%' : props.width,
      marginInline: props.isSM ? 'auto' : null,
      minWidth: '200px',
      borderRadius: '10px',
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 400,
      height: '44px',
    },
    passwordFieldContainer: {
      alignItems: 'center',
      display: 'flex',
      width: props.isSM ? '100%' : null,
    },
    visibilityIcon: {
      color: 'grey.500',
      cursor: 'pointer',
    },
    label: {
      color: '#646D7A',
      fontSize: '13px',
      fontWeight: 500,
      letterSpacing: '-0.044px',
    },
  };
};

export default function PasswordField(props) {
  const { id, onMount, onVisibilityToggle, label } = props;
  const filteredProps = { ...props };
  delete filteredProps.onMount;
  delete filteredProps.onVisibilityToggle;
  delete filteredProps.label;
  const [isVisible, setIsVisible] = useState(true);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles({ ...props, isSM });
  const UUID = useMemo(() => new Date().getTime(), []);

  const handleReference = useCallback((ref) => {
    if (onMount) {
      const data = { ref };

      if (id) {
        data.id = id;
      }

      onMount(data);
    }
  }, []);

  function handleVisibility() {
    setIsVisible(!isVisible);
  }

  useEffect(() => {
    if (onVisibilityToggle) {
      const data = { isVisible };

      if (id) {
        data.id = id;
      }

      onVisibilityToggle(data);
    }
  }, [isVisible]);

  function renderVisibleIcon() {
    if (isVisible) {
      return (
        <VisibilityOffOutlined
          sx={classes.visibilityIcon}
          onClick={handleVisibility}
        />
      );
    }

    return (
      <VisibilityOutlined
        sx={classes.visibilityIcon}
        onClick={handleVisibility}
      />
    );
  }

  return (
    <div style={classes.passwordComponentContainer}>
      <div style={classes.label}>{label}</div>

      <div aria-labelledby={UUID} style={classes.passwordFieldContainer}>
        <OutlinedInput
          ref={handleReference}
          autoFocus
          {...filteredProps}
          sx={classes.passwordField}
          type={isVisible ? 'text' : 'password'}
          endAdornment={renderVisibleIcon()}
        />
      </div>
    </div>
  );
}
